import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { IUserDataModel, IRoleDataModel } from "../../../variables/user";
import { NgbTypeahead, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, Observable, merge } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../../../services/user.service";
import { first, debounceTime, distinctUntilChanged, filter, map } from "rxjs/operators";
import { AuthService } from "../../../services/auth.service";
import { CompanyService } from "../../../services/company.service";
import { FormsBasicDataModel } from "src/app/variables/form";
import { FormService } from "src/app/services/form.service";

const updateIsCampaignForm = async (
  formService: FormService,
  formId: number,
  data: FormsBasicDataModel
): Promise<FormsBasicDataModel> => {
  let response = await formService
    .updateIsCampaignFormQueryParams(formId, data.isCampaignForm)
    .toPromise()
    .catch((error) => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
    console.log('response: ', response);
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = <FormsBasicDataModel>response["result"];

    return result;
  }
};

@Component({
  selector: "app-campaign-form",
  templateUrl: "./campaign-form.component.html",
  styleUrls: ["./campaign-form.component.scss"]
})
export class CampaignFormModalComponent implements OnInit {
  @Input() user: IUserDataModel;
  @Input() row: any;
  model: any;
  public infoIcon: string = "./assets/img/ExclamationCircle.png";
  submitted: boolean = false;

  constructor(
    private router: Router,
    route: ActivatedRoute,
    private formService: FormService,
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
  }
  modalClose() {
    this.activeModal.close();
  }
  onSubmit() {
    this.submitted = true;
    console.log("on submit", this.row);
    this.row.isCampaignForm = !this.row.isCampaignForm;
    updateIsCampaignForm(this.formService, this.row.formId, this.row).then((data) => {
      console.log("data: 7262727272 ", data);
      this.activeModal.close(data);
    });
  }
}
