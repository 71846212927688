import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CustomerService } from "src/app/services/customer.service";
import { FormService } from "src/app/services/form.service";
import { PdfService } from "src/app/services/pdf.services";
import { SortColumn, SortDirection } from "src/app/services/sortable.directive";
import { CertificateStatus, compare } from "src/app/variables/constant";
import { CustomerFormModel, ReasonDeniedModel } from "src/app/variables/form";

interface CertificatesListModel {
  customerId: number
  payerId: string
  fileName: string
  form: string
  dateSubmitted: string
}

@Component({
  selector: 'app-campaign-submitted-certificate',
  templateUrl: './campaign-submitted-certificate.component.html',
  styleUrls: ['./campaign-submitted-certificate.component.scss']
})
export class CampaignSubmittedCertificateComponent implements OnInit, OnChanges {

  @Input() campaign: any;

  certificatesListAll: CertificatesListModel[] = [];
  certificatesList: CertificatesListModel[] = [];
  collectionSizeCertificate: number = 0;
  pageCertificate: number = 1;
  pageSizeCertificate: number = 10;

  //sorting
  currentColumn: SortColumn = "";
  currentSortBy: SortDirection = "desc";

  companyCode = localStorage.getItem('company_code');

  constructor(
    public activeModal: NgbActiveModal,
    private customerService: CustomerService,
    private pdfService: PdfService,
    public sanitizer: DomSanitizer,
    private activeModal2: NgbModal,
    private formService: FormService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.getFormsSubmittedList();
  }

  ngOnInit(): void {
    this.getFormsSubmittedList();
  }

  public get certificateStatus(): typeof CertificateStatus {
    return CertificateStatus;
  }

  getFormsSubmittedList() {
    this.customerService
      .getCampaignSubmittedFormsByCampaignId(this.campaign.campaignId)
      .subscribe((res: any) => {
        if (res) {
          this.certificatesListAll = res || [];
          this.certificatesList = res || [];
          this.collectionSizeCertificate = res?.length || 0;
        }
      }, (error) => {
        this.certificatesListAll = [];
        this.certificatesList = [];
        this.collectionSizeCertificate = 0;
      });
  }

  closeModal() {
    this.activeModal.close();
  }

  resetPage() {
    this.pageCertificate = 1;
  }

  onSort(event) {
    var column = event.target.getAttribute("sortable");
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort      
    } else {
      if (this.currentColumn == "") { //first sort
        this.currentColumn = column;
        if (this.currentColumn == "firstName") { //by default, firstName is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.certificatesList = this.sortData(this.certificatesList, column, this.currentSortBy);
  }


  sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
    if (direction === '' || column === '') {
      return datalist;
    } else {
      return [...datalist].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }
}