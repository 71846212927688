import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import * as CryptoJS from "crypto-js";

export interface CompanyDataModel {
  companyId: number;
  companyCode: string;
  companyName: string;
  emailAddress?: string;
}

export function alphaNumericValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = /^[a-zA-Z0-9]*$/.test(control.value);
    return valid ? null : { alphaNumeric: true };
  };
}


const encryptSecretKey = 'd1e5f9a2b4c8d3e6f7a1b2c3d4e5f6a7';
export function encryptData(plaintext: string) {
  const key = CryptoJS.enc.Utf8.parse(encryptSecretKey); // Convert key into WordArray (using Utf8)
  const iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]); // Use zero vector as IV
  const encrypted = CryptoJS.AES.encrypt(plaintext, key, { iv: iv }); // Encrypt using AES with CBC, PKCS7
  return encrypted.toString(); // Convert ciphertext to string
}

export function decryptData(ciphertextB64: string) {
  // Base64 encoded ciphertext, 32 bytes string as key
  const key = CryptoJS.enc.Utf8.parse(encryptSecretKey); // Convert into WordArray (using Utf8)
  const iv = CryptoJS.lib.WordArray.create([0x00, 0x00, 0x00, 0x00]); // Use zero vector as IV
  const decrypted = CryptoJS.AES.decrypt(ciphertextB64, key, { iv: iv }); // By default: CBC, PKCS7
  return decrypted.toString(CryptoJS.enc.Utf8); // Convert into string (using Utf8)
}