import { Component, OnInit, NgZone, ViewChild, TemplateRef } from '@angular/core';
import { CampaignModel, CampaignCustomerModel, CampaignParamModel, ExposureModel, DeliveryMethodModel, EmailErrorModel } from '../../../variables/campaign';
import { CampaignService } from '../../../services/campaign.service';
import { compare, DeliveryMethod, CertificateStatus } from '../../../variables/constant';
import { SortColumn, SortEvent, SortDirection } from '../../../services/sortable.directive';
import { StatesService } from '../../../services/states.service';
import { StatesDataModel } from '../../../variables/states';
import { CustomerService } from '../../../services/customer.service';
import { CustomerDataModel, CustomerModelParam } from '../../../variables/customer';
import { Exposures, EmailErrors } from '../../../variables/constant';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { state } from '@angular/animations';
import { throwIfEmpty } from 'rxjs/operators';
import { promise } from 'protractor';
import { PdfService } from '../../../services/pdf.services';
import { CustomerFormModel, ReasonDeniedModel } from '../../../variables/form';
import { SafeResourceUrl, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormService } from '../../../services/form.service';
import { CommonService } from '../../../services/common.service';
import { Route, Router } from '@angular/router';


function sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
  if (direction === '' || column === '') {
    return datalist;
  } else {
    return [...datalist].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

const getCampaignCustomers = async (campaignService: CampaignService, campaignId: number): Promise<CampaignCustomerModel[]> => {
  let response = await campaignService.getCampaignCustomers(campaignId)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response["result"] instanceof Array) {
    return response["result"].map(obj => {
      return {
        campaignId: obj.campaignId,
        customerId: obj.customerId,
        customerName: obj.customerName,
        customerNumber: obj.customerNumber,
        emailAddress: obj.emailAddress,
        emailStatus: obj.emailStatus,
        mailingAddress: obj.mailingAddress,
        mailStatus: obj.mailStatus,
        accessCode: obj.accessCode,
        exposure: obj.exposure,
        state: obj.state,
        formName: obj.formName,
        certificateStatus: obj.certificateStatus,
        fileName: obj.fileName,
        deliveryMethod: obj.deliveryMethod,
        customerFormId: obj.customerFormId
      }
    });
  }
}

//for the campaign forms
const getCampaignCustomerForms = async (campaignService: CampaignService, campaignId: number, customerId: number): Promise<CampaignCustomerModel[]> => {
  let response = await campaignService.getCampaignCustomerForms(campaignId, customerId)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response["result"] instanceof Array) {
    return response["result"].map(obj => {
      return {
        campaignId: obj.campaignId,
        customerId: obj.customerId,
        customerName: obj.customerName,
        customerNumber: obj.customerNumber,
        emailAddress: obj.emailAddress,
        emailStatus: obj.emailStatus,
        mailingAddress: obj.mailingAddress,
        mailStatus: obj.mailStatus,
        accessCode: obj.accessCode,
        exposure: obj.exposure,
        state: obj.state,
        formName: obj.formName,
        certificateStatus: obj.certificateStatus,
        fileName: obj.fileName,
        deliveryMethod: obj.deliveryMethod,
        customerFormId: obj.customerFormId
      }
    });
  }
}


/// Get Campaign List Initial
const getCampaignListInitial = async (campaignService: CampaignService): Promise<CampaignModel[]> => {

  let response = await campaignService.getCampaignsByCompanyId()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          campaignId: obj.campaignId,
          campaignName: obj.campaignName,
          companyId: obj.companyId,
          deliveryMethod: obj.deliveryMethod,
          campaignStatus: obj.campaignStatus,
          dateCreated: obj.dateCreated,
          customerName: obj.customerName,
          stateName: obj.stateName,
          exposureName: obj.exposureName
        }
      });
    }
    return null;
  }

}

const getCampaignList = async (campaignService: CampaignService,
  stateList: StatesDataModel[],
  customerLists: CustomerDataModel[],
  deliveryMethodList: DeliveryMethodModel[],
  exposureLists: ExposureModel[],
  emailErrorList: EmailErrorModel[]): Promise<CampaignModel[]> => {
  let response = await campaignService.getCampaigns(stateList, customerLists, deliveryMethodList, exposureLists, emailErrorList)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          campaignId: obj.campaignId,
          campaignName: obj.campaignName,
          companyId: obj.companyId,
          deliveryMethod: obj.deliveryMethod,
          campaignStatus: obj.campaignStatus,
          dateCreated: obj.dateCreated,
          customerName: obj.customerName,
          stateName: obj.stateName,
          exposureName: obj.exposureName
        }
      });
    }
    return null;
  }

}

const getStateList = async (stateService: StatesService): Promise<StatesDataModel[]> => {
  let response = await stateService.getAllStates()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          state: obj.state,
          stateName: obj.stateName,
          stateNameFull: obj.stateNameFull,
          isSelected: false
        }
      });
    }
    return null;
  }
}

const getCustomerList = async (customerService: CustomerService): Promise<CustomerDataModel[]> => {

  let response = await customerService.getCustomerByCompanyId()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          customerId: obj.customerId,
          customerName: obj.customerName,
          customerNumber: obj.customerNumber,
          address1: obj.address1,
          city: obj.city,
          state: obj.state,
          postalCode: obj.postalCode,
          emailAddress: obj.emailAddress
        }
      });
    }
    return null;
  }

}

const getPdf = async (pdfService: PdfService, fileName: string): Promise<string> => {

  let data = await pdfService.getPdf(fileName)
    .toPromise()
    .catch(error => {
      console.log(error);
      if (error.message != undefined && error.message != "") {
        alert(error.message);
      }
    });

  if (data instanceof Object) {
    let f = data["result"];
    let base64 = f.fileContents;
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    var i = 0;
    for (i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }


    const blob = new Blob([array], { type: 'application/pdf' });
    console.log(window.URL.createObjectURL(blob));

    return window.URL.createObjectURL(blob);
  }

  return null;
}

const approvePdf = async (formService: FormService, customerFormId: number, fileName: string): Promise<any> => {

  let response = await formService.approvePdf(customerFormId, fileName)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response["result"];
  }

}

const denyPdf = async (formService: FormService, customerFormId: number, reasonDenied: string): Promise<any> => {

  let response = await formService.denyPdf(customerFormId, reasonDenied)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response["result"];
  }

}

const getPdfPreview = async (pdfService: PdfService, formName: string, customerId: number): Promise<string> => {

  let data = await pdfService.getPdfPreview(formName, customerId)
    .toPromise()
    .catch(error => {
      console.log(error);
      if (error.message != undefined && error.message != "") {
        alert(error.message);
      }
    });

  if (data instanceof Object) {
    let f = data["result"];
    let base64 = f.fileContents;
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    var i = 0;
    for (i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }


    const blob = new Blob([array], { type: 'application/pdf' });
    console.log(window.URL.createObjectURL(blob));

    return window.URL.createObjectURL(blob);
  }

  return null;
}

const getViewPDFMail = async (campaignService: CampaignService, campaignId: number, customerID: number): Promise<string> => {

  let data = await campaignService.getViewPDFMail(campaignId, customerID)
    .toPromise()
    .catch(error => {
      console.log(error);
      if (error.message != undefined && error.message != "") {
        alert(error.message);
      }
    });

  if (data instanceof Object) {
    let f:any = data["result"];

    if (data["result"] !== null) {
      return f;
    }
  }
  return null;
}



const getReplacedReason = async (formService: FormService, customerFormId: number): Promise<string> => {
  let data = await formService.getReplaceReason(customerFormId)
    .toPromise()
    .catch(error => {
      console.log(error);
      if (error.message != undefined && error.message != "") {
        alert(error.message);
      }
    });
  if (data instanceof Object) {
    if (data["status"] == "success") {
      if (data["result"] != null) {
        return data["result"];
      }
    }
  }

  return "";
}

@Component({
  selector: 'app-campaign-search',
  templateUrl: './campaign-search.component.html',
  styleUrls: ['./campaign-search.component.scss']
})
export class CampaignSearchComponent implements OnInit {
  eCert: string = DeliveryMethod.ECERT;
  paperCert: string = DeliveryMethod.PAPERCERT;

  searchResultListAll: CampaignModel[] = [];
  searchResultList: CampaignModel[];

  customerListAll: CampaignCustomerModel[] = [];
  customerList: CampaignCustomerModel[] = [];
  customerListForm: CampaignCustomerModel[] = [];

  selectedCampaign: CampaignModel;
  selectedCustomer: CampaignCustomerModel;
  loadingSearch: boolean = false;
  //paging
  collectionSize: number = 0;
  page: number = 1;
  pageSize: number = 10;

  collectionSizeCustomer: number = 0;
  pageCustomer: number = 1;
  pageSizeCustomer: number = 10;

  collectionSizeForms: number = 0;
  pageForms: number = 1;
  pageSizeForms: number = 10;


  //search
  searchTerm: string = "";
  divFilter: boolean;

  //sorting
  currentColumn: SortColumn = "";
  currentSortBy: SortDirection = "desc";

  //Filter
  stateSettings = {};
  customerSettings = {};
  exposureSettings = {};
  deliverySettings = {};
  emailErrorSettings = {};
  stateList: StatesDataModel[] = [];
  customerLists: CustomerDataModel[] = [];
  exposureList: any[] = [];
  deliveryMethodList: any[] = [];
  emailErrorList: any[] = [];

  //PDF
  pdfFile: SafeResourceUrl;
  selectedCertificate: CustomerFormModel;

  //EmailContent
  EmailContent: string = "";

  //Reason Denied
  customerFormReason: ReasonDeniedModel;
  reasonDenied: string = "";

  //loading
  ShowCustomers: boolean = false;
  ShowForms: boolean = false;

  //replacedreason
  replaceReason: SafeHtml;
  replaceFilename: string = "";

  public get certificateStatus(): typeof CertificateStatus {
    return CertificateStatus;
  }

  frmSearchFilter: UntypedFormGroup;

  public get exposures(): typeof Exposures {
    return Exposures;
  }

  public get deliveryMethods(): typeof DeliveryMethod {
    return DeliveryMethod;
  }

  public get emailErrors(): typeof EmailErrors {
    return EmailErrors;
  }

  constructor(private campaignService: CampaignService,
    public formBuilder: UntypedFormBuilder,
    private pdfService: PdfService,
    public sanitizer: DomSanitizer,
    private activeModal: NgbModal,
    private stateService: StatesService,
    private customerService: CustomerService,
    private commonService: CommonService,
    private formService: FormService,
    private router: Router,
    private _ngZone: NgZone  ) {

    window['counterComponent'] = { component: this, zone: this._ngZone };
    getCampaignListInitial(campaignService).then(data => {
      this.searchResultListAll = data;
      this.searchResultList = data;
      this.collectionSize = data.length;
    });

  }

  ngOnInit(): void {
    this.commonService.setCurrentNav("Campaigns");
    this.stateSettings = {
      singleSelection: false,
      idField: 'state',
      textField: 'stateName',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 200,
      itemsShowLimit: 5,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No records found',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
    this.customerSettings = {
      singleSelection: false,
      idField: 'customerId',
      textField: 'customerName',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 200,
      itemsShowLimit: 5,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No records found',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
    this.exposureSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'key',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 200,
      itemsShowLimit: 5,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No records found',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
    this.deliverySettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'key',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 200,
      itemsShowLimit: 5,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No records found',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
    this.emailErrorSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'key',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 200,
      itemsShowLimit: 5,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No records found',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
    this.loadData();
  }

  @ViewChild('modalnewPDF') modalnewPDF: TemplateRef<any>;

  loadData() {
    getStateList(this.stateService).then(data => {
      this.stateList = data;
    });

    getCustomerList(this.customerService).then(data => {
      this.customerLists = data;
    });

    Object.entries(this.exposures).map(([key, value]) => {
      this.exposureList.push({ value: value, key: key });
    });

    Object.entries(this.deliveryMethods).map(([key, value]) => {
      this.deliveryMethodList.push({ value: value, key: key });
    });

    Object.entries(this.emailErrors).map(([key, value]) => {
      this.emailErrorList.push({ value: value, key: key });
    });

    this.frmSearchFilter = this.formBuilder.group({
      states: [""],
      customers: [""],
      deliveryMethods: [""],
      emailErrors: [""],
      exposures: [""]
    });
  }

  get fs() { return this.frmSearchFilter.controls; }

  onSort(event) {
    this.page = 1; //bring back to page 1
    var column = event.target.getAttribute("sortable");
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort      
    } else {
      if (this.currentColumn == "") { //first sort
        this.currentColumn = column;
        if (this.currentColumn == "campaignName") { //by campaignName, state is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.searchResultList = sortData(this.searchResultList, column, this.currentSortBy);
  }
  createCampaign() {
    this.router.navigate(['/admin/campaign/create']);
  }
  onSortCustomers(event) {
    this.pageCustomer = 1; //bring back to page 1
    var column = event.target.getAttribute("sortable");
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort      
    } else {
      if (this.currentColumn == "") { //first sort
        this.currentColumn = column;
        if (this.currentColumn == "customerName") { //by default, customerName is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.customerList = sortData(this.customerList, column, this.currentSortBy);
  }

  onSortForms(event) {
    this.pageForms = 1; //bring back to page 1
    var column = event.target.getAttribute("sortable");
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort      
    } else {
      if (this.currentColumn == "") { //first sort
        this.currentColumn = column;
        if (this.currentColumn == "state") { //by default, customerName is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.customerListForm = sortData(this.customerListForm, column, this.currentSortBy);
  }


  searchData(_term: string) {
    let term = _term.toLowerCase();
    this.searchTerm = term;
    if (_term == "") {
      this.searchResultList = this.searchResultListAll;
    }
    else {
      this.searchResultList = this.searchResultListAll.filter(data =>
        (data.campaignName == null ? "" : data.campaignName).toLowerCase().indexOf(term) > -1
        || (data.deliveryMethod == null ? "" : data.deliveryMethod).toLowerCase().indexOf(term) > -1
        || (data.campaignStatus == null ? "" : data.campaignStatus).toLowerCase().indexOf(term) > -1
        //|| (data.dateCreated == null ? "" : data.dateCreated.toDateString()).toLowerCase().indexOf(term) > -1
        || (data.customerName == null ? "" : data.customerName.toString()).toLowerCase().indexOf(term) > -1
        || (data.stateName == null ? "" : data.stateName.toString()).toLowerCase().indexOf(term) > -1
        || (data.exposureName == null ? "" : data.exposureName.toString()).toLowerCase().indexOf(term) > -1
      );
    }
    this.collectionSize = this.searchResultList.length;
    this.page = 1;
  }

  searchCampaign() {
    this.loadingSearch = true;
    let _stateList: StatesDataModel[] = [];
    if (this.fs.states.value instanceof Array) {
      _stateList = this.fs.states.value;
    }

    let _customerList: CustomerModelParam[] = [];
    if (this.fs.customers.value instanceof Array) {
      _customerList = this.fs.customers.value;
    }

    let _exposureList: ExposureModel[] = [];
    if (this.fs.exposures.value instanceof Array) {
      _exposureList = this.fs.exposures.value;
    }

    let _deliveryMethodList: DeliveryMethodModel[] = [];
    if (this.fs.deliveryMethods.value instanceof Array) {
      _deliveryMethodList = this.fs.deliveryMethods.value;
    }

    let _emailErrorList: EmailErrorModel[] = [];
    if (this.fs.emailErrors.value instanceof Array) {
      _emailErrorList = this.fs.emailErrors.value;
    }

    getCampaignList(this.campaignService, _stateList, _customerList, _deliveryMethodList, _exposureList, _emailErrorList).then(data => {
      this.searchResultListAll = data;
      this.searchResultList = data;
      this.collectionSize = data.length;

      this.loadingSearch = false;
      if (data != null) {
        this.closeModal();
      }
    });
  }

  toggleCustomer(campaign) {
    this.customerListAll = null;
    this.customerList = null;
    this.customerListForm = null;
    if (this.selectedCampaign == null) {
      this.ShowCustomers = true;
      this.selectedCampaign = campaign;
      getCampaignCustomers(this.campaignService, campaign.campaignId).then(data => {
        this.customerListAll = data;
        this.customerList = data;
        this.collectionSizeCustomer = data.length
        this.ShowCustomers = false;
        this.ShowForms = false;
        this.selectedCustomer = null;
      });
    }
    else {
      this.ShowCustomers = false;
      this.selectedCustomer = null;
      this.closeCustomer();
    }
  }

  closeCustomer() {
    this.selectedCampaign = null;
  }

  /// Get PDF Modal
  getPdf(modal, certificate) {
    getPdf(this.pdfService, certificate.fileName).then(data => {
      if (data != "" && data != undefined) {
        this.selectedCertificate = certificate;
        this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true });
      }
    });
    setTimeout(this.checkModalBody, 1000);
  }

  getReplacedPdf(modal, certificate) {
    getReplacedReason(this.formService, certificate.customerFormId).then(data => {
      if (data != "" && data != undefined) {
        let temp = data;
        const Filename1 = temp.split("filename: ");
        if (typeof Filename1[1] !== 'undefined') {
          const Filename2 = Filename1[1].split(",", 1);
          if (typeof Filename2[0] !== 'undefined' && Filename2[0] !== "") {
            this.replaceFilename = Filename2[0];
            data = data.replace(this.replaceFilename, '<span id="showNewPDF" class="btn-link" onclick="window.counterComponent.component.getnewPdfJS(' + "'" + this.replaceFilename + "'" + ')">' + this.replaceFilename + '</span>'); //replace filename to link
          }
        }
        this.replaceReason = this.sanitizer.bypassSecurityTrustHtml(data);
      }
    });
    getPdf(this.pdfService, certificate.fileName).then(data => {
      if (data != "" && data != undefined) {
        this.selectedCertificate = certificate;
        this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true });
      }
    });
    setTimeout(this.checkModalBody, 1000);
  }

  getnewPdfJS(replaceFilename) {
    this._ngZone.run(() => this.getnewPdf(this.modalnewPDF, replaceFilename));
  }

  getnewPdf(modal, replacefileName) {
    getPdf(this.pdfService, replacefileName).then(data => {
      if (data != "" && data != undefined) {
        this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true });
      }
    });
    setTimeout(this.checkModalBody, 1000);
  }

  approvePdf() {
    approvePdf(this.formService, this.selectedCertificate.customerFormId, this.selectedCertificate.fileName).then(data => {
      if (data != null) {
        this.loadData();
        this.closeModal();
      }
    });
  }

  getReasonDenied(modal) {
    this.reasonDenied = "";
    this.activeModal.open(modal, { centered: true, size: 'sm', scrollable: true });
  }

  denyPdf() {
    denyPdf(this.formService, this.selectedCertificate.customerFormId, this.reasonDenied).then(data => {
      if (data != null) {
        this.loadData();
        this.closeModal();
      }
    });
  }

  getPdfPreview(modal, certificate) {
    getPdfPreview(this.pdfService, certificate.state + "_"+ certificate.formName + ".pdf", certificate.customerId).then(data => {
      if (data != "" && data != undefined) {
        this.selectedCertificate = certificate;
        this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true });
      }
    });
    setTimeout(this.checkModalBody, 1000);
  }

  getViewPDFMail(modal, certificate) {
    getViewPDFMail(this.campaignService, certificate.campaignId, certificate.customerId).then(data => {
      if (data != "" && data != undefined && data != null) {
        this.selectedCertificate = certificate;
        let URLLink = data.match(/\bhttps?:\/\/\S+/gi);
        if (URLLink != null) {
          data = data.replace(URLLink[0], "<a href='" + URLLink[0] + "' target='_blank'>" + URLLink + "</a>")
        }
        data = data.split('<p>').join('');
        data = data.split('</p>').join('<br />');
       
        this.EmailContent = data;
        this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true });
      }
    });
    setTimeout(this.checkModalBody, 1000);
  }

  checkModalBody() {
    //add class modal-open to fix 
    if (document.getElementsByClassName("modal").length !== 0 && document.getElementsByClassName("modal-backdrop").length !== 0) {
      //alert(document.getElementsByClassName("modal-open").length);
      if (document.getElementsByClassName("modal-open").length == 0) {
        document.body.classList.add("modal-open");
      }
    }
  }

  closeModal() {
    this.activeModal.dismissAll();
  }

  openFilter(modal){
    this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true });
  }

  printPDF(elem) {
    var mywindow = window.open('', 'PRINT', 'height=720,width=1280');
    let content: HTMLInputElement = <HTMLInputElement>document.getElementById(elem);
    mywindow.document.write('<html><head><title>' + document.title + '</title>');
    mywindow.document.write('<style>body{margin: 20mm 20mm 20mm 20mm;}</style>');
    mywindow.document.write('</head><body>');
    mywindow.document.write(content.innerHTML);
    mywindow.document.write('</body></html>');

    mywindow.document.close();
    mywindow.focus();

    mywindow.print();
    mywindow.close();

    return true;
  }

  resetPage() {
    this.page = 1;
    this.pageCustomer = 1;
    this.pageForms = 1;
  }

  showCustomerCampaignForms(certificate) {
    if (this.ShowForms == true) {
      this.ShowForms = false;
      this.selectedCustomer = null;
    } else {
      this.ShowForms = true;
      getCampaignCustomerForms(this.campaignService, certificate.campaignId, certificate.customerId).then(data => {
        this.customerListForm = data;
        this.collectionSizeForms = data.length;
        this.selectedCustomer = certificate;
      });
    }
    
  }
}
