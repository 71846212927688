import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { APIResponse } from '../variables/api-response';
import { FormsBasicDataModel, CustomerFormModel, ReasonDeniedModel, PdfPreviewModel, FormCertificateModel } from '../variables/form';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FormService {
  baseUrl: string;

  constructor(private http: HttpClient,
    private commonService: CommonService) {
    this.baseUrl = environment.API_URL;
  }

  getFormsSubmittedByCompany() {
    return this.http.post<APIResponse>(this.baseUrl + 'api/customermaster/AllSubmittedForms/' + localStorage.getItem('company_code'),{});
    // return this.http.get<APIResponse>(this.baseUrl + 'api/form/company/' + this.commonService.currentCompany$);
  }

  getAllForms() {
    return this.http.get<APIResponse>(this.baseUrl + 'api/form');
  }

  GetFormById(formId: number) {
    return this.http.get<APIResponse>(this.baseUrl + 'api/form/' + formId);
  }


  updateIsCampaignForm(formId: number, data: FormsBasicDataModel) {
    return this.http.post<APIResponse>(this.baseUrl + 'api/form/' + formId, data);
  }

  updateCampaignForm(formId: number, data: FormsBasicDataModel) {
    return this.http.post<APIResponse>(this.baseUrl + 'api/form/update/' + formId, data);
  }


  approvePdf(customerFormId: number, fileName: string) {
    return this.http.post<APIResponse>(this.baseUrl + 'api/form/approve/' + customerFormId + '/' + fileName, null);
  }

  denyPdf(customerFormId: number, reasonDenied: string) {
    let customerFormReason: ReasonDeniedModel = {
      customerFormId: customerFormId,
      reasonDenied: reasonDenied
    }
    console.log(customerFormId + " " + reasonDenied);
    console.log(customerFormReason);
    return this.http.post<APIResponse>(this.baseUrl + 'api/form/deny/' + customerFormId, customerFormReason);
  }

  getPdfPreview(state: string, form: string) {
    let obj: PdfPreviewModel = {
      state: state,
      form: form
    }
    return this.http.post(this.baseUrl + 'api/form/pdfpreview', obj);
  }

  getHtml(formName: string, stateName: string) {
    return this.http.get(this.baseUrl + 'api/pdf/html/' + formName + "/" + stateName);
  }

  getReplaceReason(customerFormId: number) {
    return this.http.get(this.baseUrl + 'api/form/getreplacereason/' + customerFormId);
  }

  prefillPdf(certificate: FormCertificateModel) {
    return this.http.post(this.baseUrl + 'api/form', certificate);
  }
}

