import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { IUserDataModel, IRoleDataModel } from "../../../variables/user";
import { NgbTypeahead, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, Observable, merge } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService } from "../../../services/user.service";
import { first, debounceTime, distinctUntilChanged, filter, map } from "rxjs/operators";
import { AuthService } from "../../../services/auth.service";
import { CompanyService } from "../../../services/company.service";
import { FormsBasicDataModel } from "src/app/variables/form";
import { FormService } from "src/app/services/form.service";
import { CampaignService } from "src/app/services/campaign.service";

const createCampaign = async (campaignService: CampaignService, campaign: any) => {
  let response = await campaignService
    .createCampaignNew(campaign)
    .toPromise()
    .catch((error) => {
      alert(error);
      // if (error instanceof Object) {
      //   if (error["message"] != "") {
      //     alert(error["message"]);
      //   }
      // }
      // console.log(error);
    });
    if(response) {
      alert(response);
    }
    return response;
};

@Component({
  selector: "app-campaign-create-warning",
  templateUrl: "./campaign-create-warning.component.html",
  styleUrls: ["./campaign-create-warning.component.scss"]
})
export class CampaignCreateWarningComponent implements OnInit {
  @Input() row: any;
  model: any;
  public infoIcon: string = "./assets/img/ExclamationCircle.png";
  submitted: boolean = false;
  title: string;
  description: string;

  constructor(
    private router: Router,
    route: ActivatedRoute,
    private formService: FormService,
    private campaignService: CampaignService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    if (this.row["isMissingLength"] && this.row["isExistingLength"]) {
      this.title = "Create Campaign - Payer IDs Already Have Campaigns";
      this.description =
        "There are payer IDs that are not yet added to the system, and some payer IDs already have an ongoing or upcoming campaign. Would you like to proceed with the existing payers and create another campaign, or cancel the campaign creation?";
    } else if (this.row["isMissingLength"]) {
      this.title = "Create Campaign";
      this.description =
        "There are Payers that are currently not added in the system, would you still like to proceed with the existing payers or cancel the campaign creation?";
    } else if (this.row["isExistingLength"]) {
      this.title = "Payer IDs Already Have Campaigns";
      this.description =
        "Some of the payer Ids already have an upcoming or an ongoing campaign. Do you want to still create another campaign or cancel the campaign creation?";
    }
  }
  modalClose() {
    this.activeModal.close();
  }
  onSubmit() {
    this.submitted = true;
    console.log("this.row.formData: ", this.row.formData);
    createCampaign(this.campaignService, this.row.formData).then((data) => {
      console.log(data,'------');
      this.activeModal.close(data);
    });
  }
}
