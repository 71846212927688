import { Component, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-custom-modal',
  templateUrl: './custom-modal.component.html',
  styleUrls: ['./custom-modal.component.scss']
})
export class CustomModalComponent implements OnInit {

  model: any;
  public infoIcon: string = "";
  submitted: boolean = false;
  title: string;
  description: string;
  btnText: string;

  constructor(
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }
  modalClose() {
    this.activeModal.close();
  }
  onSubmit() {
    this.activeModal.close("ok");
  }

}
