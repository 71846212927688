import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { APIResponse } from '../variables/api-response';
import { CampaignModel, CampaignParamModel, UploadCsvCampaign } from '../variables/campaign';
import { StatesService } from './states.service';
import { CustomerService } from './customer.service';
import { StatesDataModel } from '../variables/states';
import { CustomerDataModel } from '../variables/customer';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  baseUrl: string;

  constructor(private http: HttpClient,
    private commonService: CommonService) {
    this.baseUrl = environment.API_URL;
  }

  getCampaignsByCompanyId() {
    return this.http.get<APIResponse>(this.baseUrl + 'api/campaign/company/' + this.commonService.currentCompany$);
  }

  getCampaignsByCompanyCode(data) {
    return this.http.post<APIResponse>(this.baseUrl + 'api/campaign/campaigns/' + this.commonService.company_code$,data);
  }

  getCampaignsCustomersByCompanyCode(data) {
    return this.http.post<APIResponse>(this.baseUrl + 'api/campaign/customer-report/' + this.commonService.company_code$,data);
  }

  exportCampaignsCustomer(data) {
    return this.http.post<APIResponse>(this.baseUrl + 'api/campaign/export-customer-report/' + this.commonService.company_code$,data);
  }

  exportCampaignCustomers(campaignId) {
    return this.http.post<APIResponse>(this.baseUrl + 'api/campaign/export-campaign-customer/' + this.commonService.company_code$ + '/' + campaignId,{});
  }

  getCampaignsDetailsById(campaignId,payload) {
    return this.http.post<APIResponse>(this.baseUrl + 'api/campaign/campaign-details/' + campaignId,payload);
  }

  deleteCampaignById(campaignId) {
    return this.http.post<APIResponse>(this.baseUrl + 'api/campaign/delete-campaign/' + campaignId,{});
  }

  stopCustomerProcessById(campaigncustomerId) {
    return this.http.post<APIResponse>(this.baseUrl + 'api/campaign/stop-customer-process/' + campaigncustomerId,{});
  }

  markAsNonExempt(campaigncustomerId,state) {
    return this.http.get<APIResponse>(this.baseUrl + 'api/shippingaccount/mark-as-non-exempt/' + campaigncustomerId +'/'+state);
  }


  customersListByCampaign(campaignId) {
    return this.http.post<APIResponse>(this.baseUrl + 'api/campaign/campaign-details-customers-ddl/' + campaignId,{});
  }

  stateListByCampaign(campaignId) {
    return this.http.post<APIResponse>(this.baseUrl + 'api/campaign/campaign-details-state-ddl/' + campaignId,{});
  }

  getCampaigns(stateList, customerList, deliveryMethodList, exposureList, emailErrorList) {

    let searchFilter: CampaignParamModel = {
      stateList: stateList,
      customerLists: customerList,
      deliveryMethodList: deliveryMethodList,
      exposureList: exposureList,
      emailErrorList: emailErrorList,
      currentCompany: this.commonService.currentCompany$
    };
    return this.http.post<APIResponse>(this.baseUrl + 'api/campaign/search', searchFilter);
  }

  createCampaign(campaign: CampaignModel) {
    campaign.companyId = this.commonService.currentCompany$;
    console.log(campaign);
    return this.http.post<APIResponse>(this.baseUrl + 'api/campaign', campaign);
  }

  getCampaignCustomers(campaignId: number) {
    return this.http.get<APIResponse>(this.baseUrl + 'api/campaign/' + campaignId);
  }

  getViewPDFMail(campaignId: number, customerID: number) {
    return this.http.get<APIResponse>(this.baseUrl + 'api/campaign/viewpdfemail/' + campaignId + "/" + customerID);
  }

  getCampaignCustomerForms(campaignId: number, customerID: number) {
    return this.http.get<APIResponse>(this.baseUrl + 'api/campaign/campaigncustomerforms/' + campaignId + "/" + customerID);
  }  
  uploadCsvCampaign(body: UploadCsvCampaign) {
    return this.http.post<APIResponse>(this.baseUrl + 'api/campaign/uploadcsvfile', body);
  }
  createCampaignNew(body: UploadCsvCampaign) {
    return this.http.post(this.baseUrl + 'api/campaign/createcampaign', body,{ responseType: 'text' });
  }

}

