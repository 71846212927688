import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { ROUTES } from "../components/sidebar/sidebar.component";
import { environment } from "src/environments/environment";


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  _isOktaLink$ = new Subject<any>();
  isOktaLink = this._isOktaLink$.asObservable();

  constructor() { }

  //Base Url
  private baseUrl = new BehaviorSubject<string>(environment.API_URL + 'api/');

  setBaseUrl(_baseUrl = environment.API_URL + 'api/') {
    this.baseUrl.next(_baseUrl);
  }

  get baseUrl$() { return this.bSubjectToString(this.baseUrl); }

  //Top Navigation  
  private currentNav = new BehaviorSubject<string>("");
  private navTitle = new BehaviorSubject<string>("");
  private navReturnPath = new BehaviorSubject<string>("");
  private _listTitles: any[] = ROUTES.filter(listTitle => listTitle);

  setCurrentNav(_currentNav: string) {
    this.currentNav.next(_currentNav);
    this.setNavTitle();
  }

  setNavTitle() {
    let _title: string = this.currentNav$;

    if (_title.charAt(0) === '#') {
      _title = _title.slice(1);
    }


    for (var item = 0; item < this._listTitles.length; item++) {
      if (this._listTitles[item].path === _title) {
        this.navTitle.next(this._listTitles[item].title);
        return;
      }
      var submenu = this._listTitles[item].submenu;
      if (submenu != null) {
        for (var subitem = 0; subitem < submenu.length; subitem++) {
          if ((_title.indexOf(submenu[subitem].path + "/") > -1 ||
            _title == submenu[subitem].path)
            && submenu[subitem].path != "") {
            if (submenu[subitem].returnTitle != "") {
              this.navReturnPath.next(submenu[subitem].returnPath);
              this.navTitle.next(submenu[subitem].returnTitle);
              return;
            }

            this.navReturnPath.next(submenu[subitem].path);
            this.navTitle.next(submenu[subitem].title);
            return;
          }
        }
      }
    }
    this.navTitle.next(_title);
    return;
  }

  setCurrentCompany(_currentCompany: number) {
    localStorage.setItem('currentCompany', _currentCompany.toString());
  }

  get currentNav$() { return this.bSubjectToString(this.currentNav); }
  get currentCompany$() { return parseInt(localStorage.getItem("currentCompany")); }
  get navTitle$() { return this.bSubjectToString(this.navTitle); }
  get navReturnPath$() { return this.bSubjectToString(this.navReturnPath); }


  //Side Navigation
  public menuState = new BehaviorSubject<string>("");

  setMenuState(_menuState: string) {
    this.menuState.next(_menuState);
  }
  get menuState$() { return this.bSubjectToString(this.menuState); }

  //CompanyLogo
  public companyLogo = new BehaviorSubject<string>("");

  setCompanyLogo(_companyLogo: string) {
    this.companyLogo.next(_companyLogo);
  }

  get companyLogo$() { return this.bSubjectToString(this.companyLogo); }


  //CompanyLogo
  private cursorWait = new BehaviorSubject<boolean>(false);

  setCursorWait(_cursorWait: boolean) {
    this.cursorWait.next(_cursorWait);
  }

  get cursorWait$() { return this.bSubjectToBoolean(this.cursorWait); }



  bSubjectToString(obj: BehaviorSubject<string>): string {
    let _value: string = "";
    obj.asObservable()
      .subscribe(item => {
        _value = item;
      });
    return _value;
  }
  bSubjectToNumber(obj: BehaviorSubject<number>): number {
    let _value: number = 0;
    obj.asObservable()
      .subscribe(item => {
        _value = item;
      });
    return _value;
  }
  bSubjectToBoolean(obj: BehaviorSubject<boolean>): boolean {
    let _value: boolean = false;
    obj.asObservable()
      .subscribe(item => {
        _value = item;
      });
    return _value;
  }

  setOktaLink(val: string) {
    this._isOktaLink$.next(val);
  }
}
