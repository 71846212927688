import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { UserService } from '../../services/user.service';
import { IUserDataModel } from '../../variables/user';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';


const addPassword = async (authService: AuthService, newPassword: string, userId: string, confirmNewPassword: string): Promise<string> => {

  let response = await authService.addPassword(newPassword, userId, confirmNewPassword)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"] + confirmNewPassword);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = response["result"].toString();
    return result;
  }

}

const checkCurrentPassword = async (authService: AuthService, userId: string, currentPassword: string): Promise<any> => {

  let response = await authService.login(userId, currentPassword,'',localStorage.getItem('company_code'))
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert("Incorrect current password");
          let result = "false"
          return result;
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
      let result = "false"
      return result;
    }

    let result = "";
    return result;
  } else {
    let result = "";
    return result;
  }
  

}

const resetPassword = async (userService: UserService, id: string, temporaryPassword: string): Promise<IUserDataModel> => {
  let response = await userService.resetUserPasswordById(id, temporaryPassword)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      //alert(response["message"]);
    }
    let result = <IUserDataModel>response["result"];

    return result;
  }
}

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
  newPasswordForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  error = '';
  userName = '';
  userId = '';
  isFirstTimeLogin: boolean;
  response = '';
  isPasswordReset: boolean;
  public companylogo = "../../assets/img/bcvt_logo.png";
  hide1 = false;
  hide2 = false;
  hide3 = false;

  constructor(private userService: UserService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    public location : Location
  ) {

    this.userName = this.authService.getUserName();
    this.userId = route.snapshot.params["userId"]
    //this.checkIfPasswordShouldUpdate();
  }

  ngOnInit(): void {
    this.newPasswordForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      currentPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmNewPassword: ['', Validators.required]
    }, { validator: this.passwordMatchValidator });
  }

  get newPassword() { return this.newPasswordForm.get('newPassword'); }
  get confirmNewPassword() { return this.newPasswordForm.get('confirmNewPassword'); }
  get viewForm() { return this.newPasswordForm; }



  onSubmit() {
    this.submitted = true;
    //console.log(this.newPasswordForm.errors);
    if (this.newPasswordForm.invalid) { return; }
    checkCurrentPassword(this.authService, this.userName, this.newPasswordForm.get('currentPassword').value).then(data => {
      if (data == "") {
        //reset password to be able to change password
        resetPassword(this.userService, this.userId, "01001010").then(data => {
          addPassword(this.authService, this.newPasswordForm.get('newPassword').value, this.userId, this.newPasswordForm.get('confirmNewPassword').value)
            .then(data => {
              if (data == "") {
                //alert("Redirecting..");
                this.router.navigate(['/login']);
              } else {
                alert(data);
              }
            });
        });
              
        
      }
    });   

  }

  passwordMatchValidator(frm: UntypedFormGroup) {
    return frm.controls['newPassword'].value === frm.controls['confirmNewPassword'].value ? null : { 'mismatch': true };
  }

}
