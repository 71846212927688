import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommonService } from './common.service';
import { Observable } from 'rxjs';
import { APIResponse } from '../variables/api-response';
import { createCustomerModel, createShippingModel, CustomerContactDataModel, CustomerDataModel, CustomerEmailModel, updateShippingModel } from '../variables/customer';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  baseUrl: string;

  constructor(private http: HttpClient,
    private commonService: CommonService) {
    this.baseUrl = environment.API_URL;
  }

  getCustomerByCompanyId() {
    return this.http.get<APIResponse>(this.baseUrl + 'api/customer/company/' + this.commonService.currentCompany$);
  }


  getCustomerById(customerId) {
    return this.http.get<APIResponse>(this.baseUrl + 'api/customer/' + customerId);
  }

  getCurrentCustomer() {
    return this.http.get<APIResponse>(this.baseUrl + 'api/customer/current');
  }

  getCurrentMasterCustomer() {
    return this.http.post<APIResponse>(this.baseUrl + 'api/customermaster/GetCurrentCustomerProfile',{});
  }

  getCustomerContactsByCustomerId(customerId) {
    return this.http.get<APIResponse>(this.baseUrl + 'api/customer/contacts/' + customerId);
  }

  getCustomerContactByEmail(email) {
    return this.http.get<APIResponse>(this.baseUrl + 'api/customer/contact/' + email);
  }

  getCustomerContactByEmailv2(customerContact: CustomerEmailModel) {
    return this.http.put<APIResponse>(this.baseUrl + 'api/customer/contactemail', customerContact);
  }

  getCustomerRequiredForms() {
    // return this.http.get<APIResponse>(this.baseUrl + 'api/customer/forms');
    return this.http.get<APIResponse>(this.baseUrl + 'api/customer/customer-required-to-submit-forms');
  }

  getCustomerSubmittedForms() {
    return this.http.get<APIResponse>(this.baseUrl + 'api/customer/submittedforms');
  }

  getMasterCustomerSubmittedForms() {
    return this.http.post<APIResponse>(this.baseUrl + 'api/customermaster/GetSubmittedFormsForCurrentCustomer',{});
  }

  updateCustomer(customer: CustomerDataModel, src: string) {
    return this.http.put<APIResponse>(this.baseUrl + 'api/customer/' + src, customer);
  }

  updateMasterCustomer(customer: any) {
    return this.http.post<APIResponse>(this.baseUrl + 'api/customermaster/EditCustomerProfile', customer);
  }

  addCustomer(customer: CustomerDataModel) {
    return this.http.put<APIResponse>(this.baseUrl + 'api/customer/add', customer);
  }

  updateCustomerContact(contact: CustomerContactDataModel, src: string) {
    return this.http.put<APIResponse>(this.baseUrl + 'api/customer/contact/' + src, contact);
  }

  // new apis
  getCustomers(companyCode: string) {
    return this.http.post<APIResponse>(this.baseUrl + `api/customermaster/customers/${companyCode}`, {});
  }

  getCustomerSubmittedFormsByCustomerId(customer: any) {
    return this.http.post<APIResponse>(this.baseUrl + `api/customermaster/SubmittedForms/${customer.customerId}/${customer.companyCode}`, {});
  }

  createCustomer(customer: createCustomerModel) {
    return this.http.post<APIResponse>(this.baseUrl + `api/customermaster/AddCustomer/${customer.companyCode}`, customer);
  }

  updateCustomerMaster(customer: createCustomerModel) {
    return this.http.post<APIResponse>(this.baseUrl + `api/customermaster/EditCustomer`, customer);
  }

  deleteCustomerMaster(data: any) {
    return this.http.post<APIResponse>(this.baseUrl + `api/customermaster/Delete/${data}`, {});
  }

  approveCustomerMaster(id: any) {
    return this.http.put<APIResponse>(this.baseUrl + `api/customermaster/approve/${id}`, {});
  }

  changeCustomerMasterStatus(data: any) {
    return this.http.put<APIResponse>(this.baseUrl + `api/customermaster/set-active-inactive-status/${data.payorAccountNumber}/${data.status}`, {});
  }

  createShipping(shipping: createShippingModel) {
    return this.http.post<APIResponse>(this.baseUrl + `api/shippingaccount/AddShippingAccount/${shipping.companyCode}`, shipping);
  }

  updateShipping(shipping: updateShippingModel) {
    return this.http.put<APIResponse>(this.baseUrl + `api/shippingaccount/overwrite-shipping-account-detail/${shipping.companyCode}`, shipping);
  }

  deleteShipping(shippingAccountNumber: number) {
    return this.http.post<APIResponse>(this.baseUrl + `api/shippingaccount/Delete/${shippingAccountNumber}`, {});
  }

  getCustomerShippingsByCustomerId(customerId: number) {
    return this.http.get<APIResponse>(this.baseUrl + 'api/shippingaccount/shipto-accounts/' + customerId);
  }

  importCustomer(file: any, companyCode: string) {
    return this.http.post<APIResponse>(this.baseUrl + `api/customermaster/ImportCustomers/${companyCode}`, file);
  }

  overwriteShippingAccount(data: any, companyCode: string) {
    return this.http.put<APIResponse>(this.baseUrl + `api/shippingaccount/overwrite-shipping-account-detail/${companyCode}`, data);
  }

  exportFile(data: any, companyCode: string) {
    return this.http.post<APIResponse>(this.baseUrl + `api/customermaster/ExportCustomers/${companyCode}`, data);
  }
}