import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Location } from '@angular/common';
import { CommonService } from '../../services/common.service';
import { AuthService } from '../../services/auth.service';
import { CompanyService } from '../../services/company.service';
import { Router } from '@angular/router';
import { CompanyDataModel } from '../../variables/common';
import { SystemRole } from '../../variables/constant';
import { waitForAsync } from '@angular/core/testing';


const getCompanylist = async (companyService: CompanyService): Promise<CompanyDataModel[]> => {

  let response = await companyService.getAllCompany()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          companyId: obj.companyId,
          companyCode: obj.companyCode,
          companyName: obj.companyName
        }
      });
    }
    return null;
  }

}

const lastCompanyIdSelected = async (authService: AuthService, currentCompanyId: number): Promise<any> => {
  let response = await authService.lastCompanyIdSelected(currentCompanyId)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
}

const logout = async (authService: AuthService): Promise<String> => {
  let response = await authService.logoutAdmin()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      return response["message"]
    }

    return "";
  }

}

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  public displayName: string = "Jesse Doe";
  public businessUnits: CompanyDataModel[] = [];
  private companyId: number;
  public companyCode: string;
  public is404: boolean = false;
  public isAllowChangeBU: boolean = false;
  public companylogo: string = "./assets/img/bertelsmann.png";

  constructor(private location: Location,
    public commonService: CommonService,
    public authService: AuthService,
    private companyService: CompanyService,
    private router: Router) {

    this.commonService.companyLogo.subscribe((res) => {
      this.companylogo = res;
    })

    getCompanylist(companyService).then(data => {
      console.log("data", data);

      this.businessUnits = data;
      if (data.length > 0) {
        let bu: CompanyDataModel = null;
        let currentUser = localStorage.getItem('currentUser') ? JSON.parse(localStorage.getItem('currentUser') || '{}') : null;
        let companyCode = currentUser ? currentUser['companyCode'] : null
        // && commonService.currentCompany$ != NaN //removed

        if (commonService.currentCompany$ != null && commonService.currentCompany$ != undefined) {
          bu = data.find(x => x.companyCode == companyCode);
        }

        if (bu == null || bu == undefined) {
          bu = data[0];
        }

        if (authService.userRole$ == SystemRole.ADMIN || authService.userRole$ == SystemRole.COMPANYADMIN) {
          this.changeBusinessUnit(bu);
          this.isAllowChangeBU = true;
        } else if (authService.userRole$ == SystemRole.CLIENT) {
          this.companyCode = bu.companyCode;
        }
      }
    });
  }

  ngOnInit() { }

  ngAfterViewInit() {
    let _title = this.location.prepareExternalUrl(this.location.path());
    //this.commonService.setCurrentNav(_title);    
  }

  profileredirect() {
    if (this.authService.userRole$ == SystemRole.CLIENT) {
      this.router.navigate(['/client/user-profile'])
    } else {
      this.router.navigate(['/admin/user-profile'])
    }
  }

  logout() {
    logout(this.authService).then(data => {
      this.authService.logout();
      sessionStorage.clear();
      this.router.navigate(['/login']);
    });
  }
  changeBusinessUnit(bu) {
    this.is404 = false;
    this.companyCode = bu.companyCode;
    this.companyId = bu.companyId;
    this.commonService.setCurrentCompany(this.companyId);
    this.router.navigate(['admin']);
    this.commonService.setCurrentNav("Dashboard");
    lastCompanyIdSelected(this.authService, bu.companyId);
  }
}

