import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { first } from 'rxjs/operators';
import { CommonService } from 'src/app/services/common.service';


const addPassword = async (authService: AuthService,currentPassword:string, newPassword: string, userId: string, confirmNewPassword: string): Promise<string> => {

  let response = await authService.changePassword(currentPassword,newPassword, userId, confirmNewPassword)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }'currentUser'
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = response["result"].toString();
    return result;
  }

}

const checkAccountStatus = async (authService: AuthService, userId: string): Promise<any> => {

  let response = await authService.checkAccountStatus(userId)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = response["result"];

    return result;
  }

}

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PasswordChangeComponent implements OnInit {
  newPasswordForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  error = '';
  userId = '';
  isFirstTimeLogin: boolean;
  isPasswordReset: boolean;
  public companylogo = "../../assets/img/bcvt_logo.png";
  hide1 = false;
  hide2 = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private commonService : CommonService
  ) {
    this.commonService.companyLogo.subscribe((res) => {
      this.companylogo = res;
    })

    this.userId = route.snapshot.params["userId"];
    this.checkIfPasswordShouldUpdate();
  }

  ngOnInit(): void {
    this.newPasswordForm = this.formBuilder.group({
      currentPassword:[sessionStorage.getItem('old_password')],
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      confirmNewPassword: ['', Validators.required]
    }, { validator: this.passwordMatchValidator });
  }



  get newPassword() { return this.newPasswordForm.get('newPassword'); }
  get confirmNewPassword() { return this.newPasswordForm.get('confirmNewPassword'); }
  get viewForm() { return this.newPasswordForm; }

  checkIfPasswordShouldUpdate() {
    checkAccountStatus(this.authService, this.userId).then(data => {
      this.isFirstTimeLogin = data.isFirstTimeLogin;
      this.isPasswordReset = data.isPasswordReset;

      if (this.isFirstTimeLogin == false && this.isPasswordReset == false) {
        this.router.navigate(['/']);
      }
    });

  }

  onSubmit() {
    this.submitted = true;
    console.log(this.newPasswordForm.errors);
    if (this.newPasswordForm.invalid) { return; }

    this.loading = true;

    addPassword(this.authService, this.newPasswordForm.get('currentPassword').value,this.newPasswordForm.get('newPassword').value, this.userId, this.newPasswordForm.get('confirmNewPassword').value)
      .then(data => {
        if (data == "") {
          sessionStorage.removeItem('old_password');
          this.router.navigate(['/login']);
        } else {
          alert(data);
        }
      });

  }

  passwordMatchValidator(frm: UntypedFormGroup) {
    return frm.controls['newPassword'].value === frm.controls['confirmNewPassword'].value ? null : { 'mismatch': true };
  }

}
