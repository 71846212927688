import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  trigger,
  state,
  style,
  transition,
  animate,
  AUTO_STYLE,
} from "@angular/animations";
import { AuthService } from "../../services/auth.service";
import { CommonService } from "../../services/common.service";
import { SystemRole } from "../../variables/constant";
import { SVG } from "src/app/common/svg";
import { DomSanitizer } from "@angular/platform-browser";

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  isIcon?: boolean;
  class: string;
  returnPath: string;
  returnTitle: string;
  collapse: string;
  submenu: {
    path: string;
    title: string;
    icon: string;
    class: string;
    returnPath: string;
    returnTitle: string;
    collapse: string;
  }[];
}

const routesUser: RouteInfo[] = [
  {
    path: "/admin",
    title: "Dashboard",
    icon: SVG.Dashboard,
    class: "",
    returnPath: "",
    returnTitle: "",
    collapse: "",
    submenu: [],
  },
  {
    path: "/admin/certificates",
    title: "Submitted Certificates",
    icon: SVG.Chat,
    class: "",
    returnPath: "",
    returnTitle: "",
    collapse: "",
    submenu: [],
  },
  {
    path: "/admin/campaigns",
    title: "Campaigns",
    icon: SVG.Champaigns,
    class: "",
    returnPath: "",
    returnTitle: "",
    collapse: "",
    submenu: [],
  },
  // {
  //   path: "/admin/customer",
  //   title: "Customers",
  //   icon: SVG.Customer,
  //   class: "",
  //   returnPath: "",
  //   returnTitle: "",
  //   collapse: "",
  //   submenu: [],
  // },
  {
    path: "/admin/customer-management",
    title: "Customers Management",
    icon: SVG.Customer,
    class: "",
    returnPath: "",
    returnTitle: "",
    collapse: "",
    submenu: [],
  },
  {
    path: "/admin/user",
    title: "Company Users",
    icon: SVG.Clock,
    class: "",
    returnPath: "",
    returnTitle: "",
    collapse: "",
    submenu: [],
  },
  // { path: '/admin/campaign', title: 'Create Campaign', icon: 'fas fa-file-invoice', class: '', returnPath: '', returnTitle: '', collapse: "", submenu: [] },
  // {
  //   path: '/admin', title: 'Company', icon: 'fas fa-file-invoice', class: '', returnPath: '', returnTitle: '', collapse: "",
  //   submenu: [
  //     { path: '/admin/company', title: 'Company Profile', icon: '', class: '', returnPath: '', returnTitle: '', collapse: "" },
  //     { path: '/admin/customer', title: 'Customers', icon: '', class: '', returnPath: '', returnTitle: '', collapse: "" },
  //   ]
  // },
  // {
  //   path: '/admin', title: 'Settings', icon: 'fas fa-file-invoice', class: '', returnPath: '', returnTitle: '', collapse: "",
  //   submenu: [
  //     { path: '/admin/form', title: 'Forms', icon: '', class: '', returnPath: '', returnTitle: '', collapse: "" },
  //     { path: '/admin/user', title: 'Users', icon: '', class: '', returnPath: '', returnTitle: '', collapse: "" },
  //     { path: '/admin/editor', title: 'Template Editor', icon: '', class: '', returnPath: '', returnTitle: '', collapse: "" }
  //   ]
  // },
];

const routesClient: RouteInfo[] = [
  {
    path: "/client",
    title: "Dashboard",
    icon: "fas fa-file-invoice custom-text-primary",
    isIcon: true,
    class: "",
    returnPath: "",
    returnTitle: "",
    collapse: "",
    submenu: [],
  },
  {
    path: "/client/customer",
    title: "Customers",
    icon: "fas fa-file-invoice custom-text-primary",
    isIcon: true,
    class: "",
    returnPath: "",
    returnTitle: "",
    collapse: "",
    submenu: [],
  },
  {
    path: "/client/customer-management",
    title: "Customers Management",
    icon: "fas fa-file-invoice custom-text-primary",
    isIcon: true,
    class: "",
    returnPath: "",
    returnTitle: "",
    collapse: "",
    submenu: [],
  },
  {
    path: "/client/certificates",
    title: "Certificates",
    icon: "fas fa-file-invoice custom-text-primary",
    isIcon: true,
    class: "",
    returnPath: "",
    returnTitle: "",
    collapse: "",
    submenu: [],
  },
];

const validateSession = async (
  authService: AuthService,
  src: string
): Promise<String> => {
  let response = await authService
    .validateSession(src)
    .toPromise()
    .catch((error) => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      return response["message"];
    }

    return "";
  }
};

export var ROUTES: RouteInfo[] = [];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  animations: [
    trigger("collapse", [
      state("true", style({ height: AUTO_STYLE, visibility: "visible" })),
      state("false", style({ height: "0px", visibility: "hidden" })),
      transition("false => true", animate("400ms ease-in")),
      transition("true => false", animate("400ms ease-out")),
    ]),
  ],
})
export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;
  public companylogo = "./assets/img/brand/companylogo.png";
  public currentSubNav = "";
  collapsed = false;
  homepage: string;

  @Input() isExpanded: boolean = false;
  @Output() toggleSidebar: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() toggleEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  handleSidebarToggle = () => this.toggleSidebar.emit(!this.isExpanded);

  constructor(
    private router: Router,
    route: ActivatedRoute,
    public commonService: CommonService,
    private sanitizer: DomSanitizer,
    public authService: AuthService
  ) {
    if (authService.userRole$ == SystemRole.CLIENT) {
      ROUTES = routesClient;
      this.homepage = "client";
    } else {
      ROUTES = routesUser;
      this.homepage = "admin";
    }
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url != "/" && event.url != "") this.isCollapsed = true;
      }
    });
  }

  sanitizerSVG(icon: string) {
    return this.sanitizer.bypassSecurityTrustHtml(icon);
  }

  clickMenu(menu) {
    this.collapsed = !this.collapsed;
    let _changeNav =
      this.commonService.currentNav$ != menu.title &&
      this.commonService.currentNav$ != "";
    this.commonService.setCurrentNav(menu.title);
    this.currentSubNav = "";

    if (_changeNav && !this.collapsed) {
      this.collapsed = !this.collapsed;
    }

    validateSession(this.authService, "admin").then((data) => {
      if (data == "Session expired. Please login again.") {
        sessionStorage.clear();
        this.authService.logoutAdmin();
        this.router.navigate(["/login"]);
      }
    });

    //If a modal is open
    if (
      document.getElementsByClassName("modal").length !== 0 &&
      document.getElementsByClassName("modal-backdrop").length !== 0
    ) {
      let elements = document.getElementsByClassName("modal");
      for (let e = 0; e < elements.length; e++) {
        document.body.removeChild(elements[e]);
      }

      const modalBody = document.getElementsByClassName("modal-open");
      for (let s = 0; s < modalBody.length; s++) {
        modalBody[s].classList.remove("modal-open");
      }

      // get modal backdrops
      const modalsBackdrops = document.getElementsByClassName("modal-backdrop");

      // remove every modal backdrop
      for (let i = 0; i < modalsBackdrops.length; i++) {
        document.body.removeChild(modalsBackdrops[i]);
      }
    }
  }

  clickSubMenu(submenu) {
    if (submenu.collapse == "") submenu.collapse = "show";
    else submenu.collapse = "";

    this.currentSubNav = submenu.title;

    validateSession(this.authService, "admin").then((data) => {
      if (data == "Session expired. Please login again.") {
        sessionStorage.clear();
        this.authService.logoutAdmin();
        this.router.navigate(["/login"]);
      }
    });

    //If a modal is open
    if (
      document.getElementsByClassName("modal").length !== 0 &&
      document.getElementsByClassName("modal-backdrop").length !== 0
    ) {
      let elements = document.getElementsByClassName("modal");
      for (let e = 0; e < elements.length; e++) {
        document.body.removeChild(elements[e]);
      }

      const modalBody = document.getElementsByClassName("modal-open");
      for (let s = 0; s < modalBody.length; s++) {
        modalBody[s].classList.remove("modal-open");
      }

      // get modal backdrops
      const modalsBackdrops = document.getElementsByClassName("modal-backdrop");

      // remove every modal backdrop
      for (let i = 0; i < modalsBackdrops.length; i++) {
        document.body.removeChild(modalsBackdrops[i]);
      }
    }
  }

  private _opened: boolean = false;

  private _toggleSidebar() {
    this._opened = !this._opened;
  }
}
