import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { SystemRole } from '../../variables/constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoModalComponent } from 'src/app/pages/eCert/info-modal/info-modal.component';
import { CommonService } from 'src/app/services/common.service';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';
import { CustomerService } from 'src/app/services/customer.service';
import { CustomerDataModel } from 'src/app/variables/customer';
import { alphaNumericValidator } from 'src/app/variables/common';

const requestNewAccess = async (authService: AuthService, customerNumber: string): Promise<string> => {

  let response = await authService.requestNewAccessMaster(customerNumber)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["ResponseException"] != "") {
          alert(error["ResponseException"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response;
  }

}

const activateCustomer = async (authService: AuthService, token: string): Promise<string> => {

  let response = await authService.activateCustomer(token)
    .toPromise()
    .catch(error => {
      if (error["ResponseException"] != "") {
        alert(error["ResponseException"]);
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response;
  }

}

const loginCustomer = async (authService: AuthService, payload: any): Promise<string> => {

  let response = await authService.customerMasterLogin(payload)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["ResponseException"] != "") {
          alert(error["ResponseException"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response;
  }

}
const getCurrentCustomer = async (customerService: CustomerService): Promise<CustomerDataModel> => {

  let response: any = await customerService.getCurrentMasterCustomer()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          // alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      // alert(response["message"]);
    }

    return response;
  }

}

@Component({
  selector: 'app-e-cert',
  templateUrl: './e-cert.component.html',
  styleUrls: ['./e-cert.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ECertComponent implements OnInit {
  customerLogin: UntypedFormGroup;
  otpForm: FormGroup;
  sendOTPForm!: FormGroup;

  submitted: boolean = false;
  token: string = "";
  frmNewToken: UntypedFormGroup;
  isRequested: boolean = false;
  isRequestNewAccess: boolean = false;
  clicked: boolean = false;
  public companyLogo = "../../assets/img/bcvt_logo.png";

  loading = false;
  otpSubmitted = false;
  error = '';
  showLoginScreen: boolean = true;
  showSendOtpScreen: boolean = false;
  showOtpScreen: boolean = false;
  canResend: boolean = true;

  constructor(private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    route: ActivatedRoute,
    private modalService: NgbModal,
    private commonService: CommonService,
    private toastr: ToastrService,
    private customerService: CustomerService
  ) {
    this.commonService.companyLogo.subscribe((res) => {
      this.companyLogo = res;
    })

    let _payorAccountNumber: string = "";
    let _accessCode: string = "";
    this.token = route.snapshot.params["token"];

    if (this.token != undefined) {
      if (this.token.indexOf("-") > 1) {
        activateCustomer(authService, this.token).then(response => {
          if (response != undefined) {
            const returnUrl = '/eCert/customer';
            this.router.navigate([returnUrl]);
          }
        });
      }
    }
    this.customerLogin = this.formBuilder.group({
      payorAccountNumber: [_payorAccountNumber, [Validators.required, alphaNumericValidator()]],
      accessCode: [_accessCode, Validators.required]
    });

    this.frmNewToken = this.formBuilder.group({
      customerNumber: [_payorAccountNumber, Validators.required],
      // emailAddress: ["", Validators.required]
    });

    this.sendOTPForm = this.formBuilder.group({
      type: ["", [Validators.required]]
    });

    this.otpForm = this.formBuilder.group({
      OtpCode: ["", Validators.required]
    });

    if (this.authService.currentUserValue && this.authService.userRole$ == SystemRole.CUSTOMER) {
      this.router.navigate(['/eCert/certificates']);
    }
  }

  ngOnInit(): void {
  }

  openVerticallyCentered() {
    const modalRef = this.modalService.open(InfoModalComponent, { centered: true });
    modalRef.result.then(
      () => {
      },
      (reason: any) => {
        console.log(reason);
      }
    );
  }

  get f() { return this.customerLogin.controls; }
  get t() { return this.frmNewToken.controls; }
  get otp() { return this.otpForm.controls; }

  sendOTP(isSendOtp?: boolean) {
    this.submitted = true;
    if (this.customerLogin.invalid) { return; }
    this.loading = true;

    let payload: any = {
      payorAccountNumber: this.customerLogin.value['payorAccountNumber'],
      accessCode: this.customerLogin.value['accessCode'],
      isNeedToSendOTP: false,
      companyCode: localStorage.getItem('company_code')
    };

    if (isSendOtp) {
      payload.isNeedToSendOTP = true;
      payload.isSendOTPOnMobile = this.sendOTPForm.value['type'] == 'SMS' ? true : false
    }

    this.authService.customerMasterSendOtp(payload)
      .pipe(first())
      .subscribe({
        next: () => {
          if (isSendOtp) {
            this.showOtpScreen = true;
            this.showLoginScreen = false;
            this.showSendOtpScreen = false;
          } else {
            this.showSendOtpScreen = true;
            this.showOtpScreen = false;
            this.showLoginScreen = false;
          }
          this.loading = false;
        },
        error: (error) => {
          this.error = error;
          this.loading = false;
          if (error instanceof Object) {
            if (error["ResponseException"] != "") {
              this.toastr.error(error["ResponseException"], 'Error');
            } else {
              this.toastr.error("Something went wrong!", 'Error');
            }
          } else {
            this.toastr.error("Something went wrong!", 'Error');
          }
          console.log(error);
        }
      })
  }

  onSubmit() {
    this.otpSubmitted = true;
    if (this.otpForm.invalid) { return; }
    this.loading = true;

    let payload: any = {
      payorAccountNumber: this.customerLogin.value['payorAccountNumber'],
      accessCode: this.customerLogin.value['accessCode'],
      otp: this.otp.OtpCode.value?.toString(),
      companyCode: localStorage.getItem('company_code')
    };

    loginCustomer(this.authService, payload).then(response => {
      if (response != undefined) {
        console.log(response);
        getCurrentCustomer(this.customerService).then((data) => {
          localStorage.setItem('currentUserInfo', JSON.stringify(data));

          const returnUrl = '/eCert/certificates';
          window.location.href = returnUrl;
        })
        // this.router.navigate([returnUrl]);
      }
    });

    // this.authService.customerMasterLogin(payload)
    //   .pipe(first())
    //   .subscribe({
    //     next: (res) => {
    //       if (res?.token) {
    //         this.redirect();
    //       }
    //     },
    //     error: (error) => {
    //       console.log(error);
    //       this.error = error;
    //       this.loading = false;
    //       if (error instanceof Object) {
    //         if (error["ResponseException"] != "") {
    //           this.toastr.error(error["ResponseException"], 'Error');
    //         } else {
    //           this.toastr.error("Something went wrong!", 'Error');
    //         }
    //       } else {
    //         this.toastr.error("Something went wrong!", 'Error');
    //       }
    //     }
    //   })
  }

  resendOTP() {
    this.sendOTP(true);
    this.canResend = false;

    timer(60000).subscribe(() => {
      this.canResend = true;
    });
  }

  // loginCustomer() {
  //   this.submitted = true;
  //   if (!this.customerLogin.invalid) {
  //     loginCustomer(this.authService, this.f.customerNumber.value, this.f.accessCode.value).then(response => {
  //       if (response != undefined) {
  //         console.log(response);
  //         const returnUrl = '/eCert/certificates';
  //         window.location.href = returnUrl;
  //         // this.router.navigate([returnUrl]);
  //       }
  //     });
  //   }
  // }

  requestNewAccess() {
    this.isRequested = true;
    this.clicked = true;
    requestNewAccess(this.authService, this.t.customerNumber.value).then(response => {
      if (response != undefined) {
        this.isRequested = false;
        this.isRequestNewAccess = false;
      } else {
        this.clicked = false;
      }
    });
  }

  redirect() {
    const returnUrl = '/eCert/certificates';
    window.location.href = returnUrl;
  }
}
