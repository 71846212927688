import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, Validator, ValidatorFn, ValidationErrors } from '@angular/forms';
import { IUserDataModel, IRoleDataModel } from '../../../variables/user';
import { NgbTypeahead, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Observable, merge } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { first, debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { AuthService } from '../../../services/auth.service';
import { CompanyService } from '../../../services/company.service';
import { CompanyDataModel } from '../../../variables/common';
import { SystemRole } from '../../../variables/constant';


//
const updateUser = async (userService: UserService, user: IUserDataModel): Promise<IUserDataModel> => {
  console.log(user);
  let response = await userService.updateUser(user)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["ResponseException"] != "") {
          alert(error["ResponseException"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return <IUserDataModel>response["result"];
  }

}
const getUserById = async (userService: UserService, id: string): Promise<IUserDataModel> => {

  let response = await userService.getUserById(id)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return <IUserDataModel>response["result"];
  }

}


const getAllCompany = async (companyService: CompanyService): Promise<CompanyDataModel[]> => {

  let response = await companyService.getAllCompany()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          companyId: obj.companyId,
          companyCode: obj.companyCode,
          companyName: obj.companyName,
          emailAddress: obj.emailAddress
        }
      });
    }
    return null;
  }

}

const getUserRoles = async (userService: UserService): Promise<IRoleDataModel[]> => {

  let response = await userService.getAllRoles()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          id: obj.id,
          name: obj.name
        }
      });
    }
    return null;
  }

}

@Component({
  selector: 'app-user-modal',
  templateUrl: './user-modal.component.html',
  styleUrls: ['./user-modal.component.scss']
})
export class UserModalComponent implements OnInit {
  editForm: UntypedFormGroup;
  @Input() user: IUserDataModel;
  public roleList: IRoleDataModel[];
  public companyList: CompanyDataModel[];
  model: any;
  defaultRole: IRoleDataModel;
  filterRoleList: IRoleDataModel[];
  submitted: boolean = false;
  currentCompanyId = localStorage.getItem('currentCompany');

  // List of countries with flags and codes
  countries = [
    { code: '+91', name: 'India', flag: './assets/svg/in.svg' },
    { code: '+1', name: 'United States', flag: './assets/svg/us.svg' },
  ];

  selectedCountry: any = this.countries[1];

  public get systemRoles(): typeof SystemRole {
    return SystemRole;
  }

  @ViewChild('instance', { static: true }) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  NoWhiteSpaceValidator = Validators.pattern(/^\S*$/);

  searchRole = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => {
        if (term === '') {
          return this.roleList;
        }
        else {
          this.filterRoleList = this.roleList.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1);
          if (this.filterRoleList.length == 1) {
            this.f.tmpRole.setValue(this.filterRoleList[0]);
          }
          return this.filterRoleList;
        }
      })
    );
  }

  constructor(private router: Router, route: ActivatedRoute,
    private userService: UserService,
    private authService: AuthService,
    private companyService: CompanyService,
    public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this.editForm = new UntypedFormGroup({
      id: new UntypedFormControl(this.user.id),
      userName: new UntypedFormControl(this.user.userName, [Validators.required]),
      email: new UntypedFormControl(this.user.email, [Validators.required]),
      firstName: new UntypedFormControl(this.user.firstName, [Validators.required]),
      middleName: new UntypedFormControl(this.user.middleName),
      lastName: new UntypedFormControl(this.user.lastName),
      phoneNumber: new UntypedFormControl(this.splitPhoneNumber(this.user.phoneNumber).localNumber, [
        Validators.required,
        this.NoWhiteSpaceValidator,
        this.onlyNumbersValidator,
        Validators.minLength(7),
        Validators.maxLength(15)
      ]),
      isActive: new UntypedFormControl(this.user.isActive),
      role: new UntypedFormControl(this.user.role, [Validators.required]),
      roleId: new UntypedFormControl(this.user.roleId),
      temporaryPassword: new UntypedFormControl(this.user.temporaryPassword),
      isPasswordReset: new UntypedFormControl(this.user.isPasswordReset),
      tmpRole: new UntypedFormControl(this.user.role, [roleValidator]),
      companyId: new UntypedFormControl(this.currentCompanyId)
    });

    getUserRoles(this.userService).then(data => {
      const roleList = data.filter(x => x.name != SystemRole.CUSTOMER);
      if (this.user != undefined) {
        this.defaultRole = roleList.filter(x => x.name == this.user.role)[0];
        if (this.defaultRole != null)
          this.f.tmpRole.setValue(this.defaultRole);
      }

      this.roleList = roleList.map((el) => {
        return {
          id: el.id,
          name: el.name,
          label: el.name == 'Admin' ? 'Company User' : el.name,
          roleId: el.roleId
        }
      });
      this.editForm.patchValue({
        role: this.user.role ? this.user.role : this.roleList[0]['name'],
        roleId: this.user.role ? this.user.role : this.roleList[0]['roleId'],
      });
    });

    this.editForm.controls['role'].disable();
    this.editForm.controls['companyId'].disable();

    getAllCompany(this.companyService).then(data => {
      this.companyList = data;
    });

    let identityUserId = this.editForm.controls['id'].value;
    //console.log(this.id);


    if (identityUserId != "") {
      getUserById(this.userService, identityUserId).then(data => {
        if (data.roleId != null && this.roleList != null) {
          this.defaultRole = this.roleList.filter(x => x.id == data.roleId)[0];
          data.roleId = this.defaultRole.name;
          this.f.tmpRole.setValue(this.defaultRole);
        }
        const selectedCountry = this.splitPhoneNumber(data.phoneNumber).countryCode;
        this.selectedCountry = this.countries.find((el) => el.code == selectedCountry);

        this.editForm.patchValue({
          id: data.id,
          userName: data.userName,
          email: data.email,
          firstName: data.firstName,
          middleName: data.middleName,
          lastName: data.lastName,
          phoneNumber: this.splitPhoneNumber(data.phoneNumber).localNumber,
          isActive: data.isActive,
          role: data.role,
          temporaryPassword: data.temporaryPassword,
          isPasswordReset: data.isPasswordReset,
          roleId: data.roleId,
          companyId: this.currentCompanyId
        });
        this.f.isPasswordReset.setValue(false);
      });
    } else {
      this.f.isPasswordReset.setValue(true);
      this.f.temporaryPassword.setValue("");
    }

  }

  get f() { return this.editForm.controls; }

  onGenerateTemporaryPassword() {
    this.f.temporaryPassword.setValue(Math.random().toString(36).slice(-8));
    return false;
  }

  onSubmit() {
    this.submitted = true;
    if (this.f.role.value == SystemRole.CLIENT && this.f.companyId.value == null) {
      return false;
    }
    if (this.f.companyId.value != null) {
      this.f.companyId.setValue(parseInt(this.currentCompanyId));
    }
    if (!this.editForm.invalid) {

      const formData = this.editForm.getRawValue();

      let payload : IUserDataModel = {
        id: formData.id,
        userName: formData.userName,
        email: formData.email,
        firstName: formData.firstName,
        middleName: formData.middleName,
        lastName: formData.lastName,
        phoneNumber: this.selectedCountry['code'] + formData.phoneNumber,
        isActive: formData.isActive,
        role: formData.role,
        temporaryPassword: formData.temporaryPassword,
        isPasswordReset: formData.isPasswordReset,
        tmpRole: formData.tmpRole,
        companyId: formData.companyId
      }

      updateUser(this.userService, payload).then(data => {
        if (data != null) {
          this.editForm.controls.phoneNumber.setValue(this.splitPhoneNumber(this.editForm.controls.phoneNumber.value).localNumber);
          this.activeModal.close(data);
        }
      });
    }
  }

  // Handle country selection
  selectCountry(country: any) {
    this.selectedCountry = country;
  }

  splitPhoneNumber(phoneNumber: string) {
    if (phoneNumber) {
      let countryCode = "";
      let localNumber = "";

      // Check for +1 (USA) or +91 (India) as the country code
      if (phoneNumber.startsWith("+1")) {
        countryCode = "+1";
        localNumber = phoneNumber.substring(2); // Remove the country code part
      } else if (phoneNumber.startsWith("+91")) {
        countryCode = "+91";
        localNumber = phoneNumber.substring(3); // Remove the country code part
      } else {
        countryCode = this.countries[0]['code'];
        localNumber = phoneNumber; // Remove the country code part
      }
      return { countryCode: countryCode || this.countries[0]['code'], localNumber };
    } else {
      this.selectedCountry = this.countries[1];
      return { countryCode: this.selectedCountry.code || this.countries[0]['code'], localNumber: null };
    }
  }

  // Custom validator to check if phone number contains only numeric characters
  onlyNumbersValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!/^[0-9]*$/.test(value)) {
      return { invalidCharacters: true };
    }
    return null;
  }


  role_change(role) {
    if (role != SystemRole.CLIENT) {
      this.f.companyId.setValue(this.currentCompanyId);
    }
  }

  formatter = (x: { name: string }) => x.name;
}

function roleValidator(control: AbstractControl): { [key: string]: boolean } | null {
  if (Object.keys(control.value).length > 0) {
    if (Object.keys(control.value)[0] == "roleId" && (control.value["roleId"] == null)) {
      return { "roleInvalid": true };
    } else if (control.value == "") {
      return { "roleInvalid": true };
    }
  }

  return null;
}
