import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MultiSelectComponent } from 'ng-multiselect-dropdown';
import { CustomModalComponent } from 'src/app/components/custom-modal/custom-modal.component';
import { CampaignService } from 'src/app/services/campaign.service';
import { SortColumn, SortDirection } from 'src/app/services/sortable.directive';
import { CampaignDetailsModel } from 'src/app/variables/campaign';
import { CertificateStatus, compare } from 'src/app/variables/constant';
import { state } from '@angular/animations';
import { PdfService } from 'src/app/services/pdf.services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormService } from 'src/app/services/form.service';
import { ReasonDeniedModel } from 'src/app/variables/form';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SVG } from 'src/app/common/svg';
import { Location } from '@angular/common';

const getCampaignDetails = async (
  campaignService: CampaignService,
  campaignId: any,
  payload: any
): Promise<any[]> => {
  let response = await campaignService
    .getCampaignsDetailsById(campaignId, payload)
    .toPromise()
    .catch((error) => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response as any;
  }
};

const deleteCampaign = async (
  campaignService: CampaignService,
  campaignId: any
): Promise<any[]> => {
  let response = await campaignService
    .deleteCampaignById(campaignId)
    .toPromise()
    .catch((error) => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response as any;
  }
};

const stopCustomerProcessById = async (
  campaignService: CampaignService,
  campaigncustomerId: any
): Promise<any[]> => {
  let response = await campaignService
    .stopCustomerProcessById(campaigncustomerId)
    .toPromise()
    .catch((error) => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response as any;
  }
};

const getCustomersListByCampaign = async (
  campaignService: CampaignService,
  campaigncustomerId: any
): Promise<any[]> => {
  let response = await campaignService
    .customersListByCampaign(campaigncustomerId)
    .toPromise()
    .catch((error) => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response as any;
  }
};

const getStateListByCampaign = async (
  campaignService: CampaignService,
  campaigncustomerId: any
): Promise<any[]> => {
  let response = await campaignService
    .stateListByCampaign(campaigncustomerId)
    .toPromise()
    .catch((error) => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response as any;
  }
};

const getPdf = async (pdfService: PdfService, fileName: string): Promise<string> => {
  let data = await pdfService.getPdfMaster(fileName)
    .toPromise()
    .catch(error => {
      console.log(error);
      if (error.ResponseException != undefined && error.ResponseException != "") {
        alert(error.ResponseException);
      }
    });

  if (data instanceof Object) {
    return data["result"]
  }

  return null;
}

const approvePdf = async (formService: FormService, customerId: number, fileName: string): Promise<any> => {

  let response = await formService.approvePdf(customerId, fileName)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["ResponseException"] != "") {
          alert(error["ResponseException"]);
        }
      }
      alert("Something went wrong. Please take note of the time this happened and contact your administrator. Error:" + error);
      console.log(error);
      location.reload();
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response["result"];
  }

}

const denyPdf = async (formService: FormService, customerId: number, reasonDenied: string): Promise<any> => {

  let response = await formService.denyPdf(customerId, reasonDenied)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["ResponseException"] != "") {
          alert(error["ResponseException"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response["result"];
  }
}

const exportCampaignCustomers = async (
  campaignService: CampaignService,
  data: any
): Promise<any[]> => {
  let response = await campaignService
    .exportCampaignCustomers(data)
    .toPromise()
    .catch((error) => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          // alert(error["message"]);
        }
      }
      console.log(error);
      return null;
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      // alert(response["message"]);
    }
    return response as any;
  }
};


@Component({
  selector: 'app-campaign-view',
  templateUrl: './campaign-view.component.html',
  styleUrls: ['./campaign-view.component.scss']
})
export class CampaignViewComponent implements OnInit, OnDestroy {

  @ViewChild('multiSelect', { static: false }) multiSelect!: MultiSelectComponent;

  campaignId: any;
  campaignData: CampaignDetailsModel;
  searchResultListAll: CampaignDetailsModel[] = [];
  searchResultList: CampaignDetailsModel[];

  searchTerm: string = "";
  searchSubject = new Subject<string>();

  selectedStatus = [];
  selectedCustomer = "";
  selectedState = "";

  collectionSize: number = 0;
  page: number = 1;
  pageSize: number = 10;
  currentColumn: SortColumn = "";
  currentSortBy: SortDirection = "desc";
//   Pending
// Received
// Stopped
// Not Exempt
 
  statusList: any[] = [
    {
      value: "Received", key: "Received"
    },
    {
      value: "Pending", key: "Pending"
    },
    {
      value: "Stopped", key: "Stopped"
    },
    {
      value: "Not Exempt", key: "Not Exempt"
    }
  ];
  statusDropdownSettings = {};
  customerList = [];
  stateList = [];
  pdfFile: SafeResourceUrl;
  selectedCertificate: any;
  approveLoading: boolean = false;
  customerFormReason: ReasonDeniedModel;
  reasonDenied: string = "";
  docIcon = SVG.documentIconSVG;
  secureDocIcon = SVG.secureDocIcon;
  public get certificateStatus(): typeof CertificateStatus {
    return CertificateStatus;
  }

  constructor(
    private campaignService: CampaignService,
    private route: ActivatedRoute,
    private activeModal: NgbModal,
    private router: Router,
    private pdfService: PdfService,
    public sanitizer: DomSanitizer,
    private formService: FormService,
    public location : Location
  ) {
    this.campaignId = route.snapshot.params["id"];
  }

  ngOnInit(): void {
    this.searchSubject.pipe(
      debounceTime(300), // Adjust the delay as needed
      distinctUntilChanged()
    ).subscribe(searchTerm => {
      this.getCampaignsDetails();
    });

    if (this.multiSelect) {
      this.multiSelect.closeDropdown();
    }
    this.statusDropdownSettings = {
      singleSelection: false,
      idField: 'value',
      textField: 'key',
      enableCheckAll: true,
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      allowSearchFilter: true,
      limitSelection: -1,
      clearSearchFilter: true,
      maxHeight: 200,
      itemsShowLimit: 1,
      searchPlaceholderText: 'Search',
      noDataAvailablePlaceholderText: 'No records found',
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false
    };
    this.getCampaignsDetails();
    this.getCustomersListByCampaign();
    this.getStateListByCampaign();
  }

  onSearchChange(searchValue: string) {
    this.searchSubject.next(searchValue); // Emit value for debounce processing
  }

  getCampaignsDetails() {
    let statusValue = [];
    if (this.selectedStatus && this.selectedStatus.length) {
      statusValue = this.selectedStatus.map(item => item.value);
    }
    let payload = {
      "status": statusValue || [],
      "customerId": this.selectedCustomer ? Number(this.selectedCustomer) : 0,
      "state": this.selectedState || null,
      "search": this.searchTerm || null
    }
    getCampaignDetails(this.campaignService, this.campaignId, payload).then((data: any) => {
      this.campaignData = data;
      if (this.campaignData.customers.length) {
        this.collectionSize = this.campaignData.customers.length;
        this.searchResultListAll = this.campaignData.customers;
        this.searchResultList = this.campaignData.customers;
        this.collectionSize = this.campaignData.customers.length;
      } else {
        this.collectionSize = 0;
        this.searchResultListAll = [];
        this.searchResultList = [];
        this.collectionSize = 0;
      }
    });
  }

  getCustomersListByCampaign() {
    getCustomersListByCampaign(this.campaignService, this.campaignId).then((data: any) => {
      this.customerList = data;
    });
  }

  getStateListByCampaign() {
    getStateListByCampaign(this.campaignService, this.campaignId).then((data: any) => {
      this.stateList = data;
    });
  }

  onDeleteCampaign() {
    const modalRef = this.activeModal.open(CustomModalComponent, { centered: true });
    modalRef.componentInstance.title = "Delete Campaign";
    modalRef.componentInstance.description = "If you delete the campaign the pending email attempts will be stopped & no further actions could be taken on the campaign. Click delete to continue.";
    modalRef.componentInstance.infoIcon = "./assets/img/delete-icon.svg";
    modalRef.componentInstance.btnText = "Delete";
    modalRef.result.then(
      (data: any) => {
        if (data == "ok") {
          deleteCampaign(this.campaignService, this.campaignId).then((data: any) => {
            this.router.navigate(["/admin/campaigns"]);
          });
        }
      },
      (reason: any) => {
        console.log(reason);
      }
    );
  }

  getPdf(modal, file, certificate) {
    //this.activeModal.dismissAll();
    getPdf(this.pdfService, file).then(data => {
      if (data != "" && data != undefined) {
        this.selectedCertificate = certificate;
        this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true });
      }
    });
    setTimeout(this.checkModalBody, 1000);
  }

  checkModalBody() {
    //add class modal-open to fix 
    if (document.getElementsByClassName("modal").length !== 0 && document.getElementsByClassName("modal-backdrop").length !== 0) {
      //alert(document.getElementsByClassName("modal-open").length);
      if (document.getElementsByClassName("modal-open").length == 0) {
        document.body.classList.add("modal-open");
      }
    }
  }

  closeModal() {
    this.activeModal.dismissAll();
  }

  approvePdfCurrent() {
    console.log("selectedCertificate",this.selectedCertificate);
    
    this.approveLoading = true;
    approvePdf(this.formService, this.selectedCertificate.currentCertificateCustomerFormId, this.selectedCertificate.currentCertificateWebFileName).then(data => {
      if (data != null) {
        this.getCampaignsDetails();
        this.closeModal();
        this.approveLoading = false;
      }
    });
  }

  approvePdfPrior() {
    console.log("selectedCertificate",this.selectedCertificate);
    
    this.approveLoading = true;
    approvePdf(this.formService, this.selectedCertificate.priorCertificateCustomerFormId, this.selectedCertificate.priorCertificateWebFileName).then(data => {
      if (data != null) {
        this.getCampaignsDetails();
        this.closeModal();
        this.approveLoading = false;
      }
    });
  }

  getReasonDenied(modal) {
    this.reasonDenied = "";
    this.activeModal.open(modal, { centered: true, size: 'sm', scrollable: true });
  }

  denyPdfCurrent() {
    denyPdf(this.formService, this.selectedCertificate.currentCertificateCustomerFormId, this.reasonDenied).then(data => {
      if (data != null) {
        this.getCampaignsDetails();
        this.closeModal();
      }
    });
  }

  denyPdfPrior() {
    denyPdf(this.formService, this.selectedCertificate.priorCertificateCustomerFormId, this.reasonDenied).then(data => {
      if (data != null) {
        this.getCampaignsDetails();
        this.closeModal();
      }
    });
  }

  onBlockCustomer(campaigncustomerId) {
    const modalRef = this.activeModal.open(CustomModalComponent, { centered: true });
    modalRef.componentInstance.title = "Stop Email";
    modalRef.componentInstance.description = "Are you sure you want to stop the customer's campaign email?";
    modalRef.componentInstance.infoIcon = "./assets/img/delete-icon.svg";
    modalRef.componentInstance.btnText = "Stop";
    modalRef.result.then(
      (data: any) => {
        if (data == "ok") {
          stopCustomerProcessById(this.campaignService, campaigncustomerId).then((data: any) => {
            this.getCampaignsDetails();
            this.getCustomersListByCampaign();
            this.getStateListByCampaign();
          });
        }
      },
      (reason: any) => {
        console.log(reason);
      }
    );
  }

  searchData(_term: string) {
    let term = _term.toLowerCase();
    this.searchTerm = term;
    if (_term == "") {
      this.searchResultList = this.searchResultListAll;
    } else {
      this.searchResultList = this.searchResultListAll.filter(
        (data) =>
          (data.customerName == null ? "" : data.customerName).toLowerCase().indexOf(term) > -1
      );
    }
    this.collectionSize = this.searchResultList.length;
    this.page = 1;
  }

  onSort(event) {
    this.page = 1; //bring back to page 1
    var column = event.target.getAttribute("sortable");
    console.log("column: ", column);
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort
    } else {
      if (this.currentColumn == "") {
        //first sort
        this.currentColumn = column;
        if (this.currentColumn == "state") {
          //by default, state is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.searchResultList = sortData(this.searchResultList, column, this.currentSortBy);
  }

  exportCustomers() {
    exportCampaignCustomers(this.campaignService, this.campaignId).then((res: any) => {
      if (res) {
        const base64Excel = res['file']['fileContents'];
        this.downloadExcel(base64Excel, res['fileName']);
      }
    });
  }

  downloadExcel(base64String: string, fileName: string) {
    // Convert base64 string to binary data
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a download link for the Blob and trigger download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName || 'download.xlsx';

    // Append link to body, click, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the object URL to free memory
    URL.revokeObjectURL(url);
  }
  
  getBackgroundColor(days: any): string {
    switch (true) {
      case days >= 0 && days <= 1:
        return "#6482A0";
      case days >= 1 && days <= 12:
        return "#FAAD14";
      case days > 12 && days <= 47:
        return "#FAAD14";
      case days > 47 && days <= 66:
        return "#73D13D";
      case days > 66 && days <= 85:
        return "#73D13D";
      case days > 85:
        return "#73D13D";
      default:
        return "#FF4D4F";
    }
  }

  getBGColor(days: any): string {
    switch (true) {
      case days >= 0 && days <= 1:
        return "rgba(100, 130, 160, 0.44)";
      case days >= 1 && days <= 12:
        return "rgba(250, 173, 20, 0.41)";
      case days > 12 && days <= 47:
        return "rgba(250, 173, 20, 0.41)";
      case days > 47 && days <= 66:
        return "rgba(115, 209, 61, 0.31)";
      case days > 66 && days <= 85:
        return "rgba(115, 209, 61, 0.31)";
      case days > 85:
        return "rgba(115, 209, 61, 0.31)";
      default:
        return "#fff1f0";
    }
  }

  ngOnDestroy() {
    this.searchSubject.unsubscribe(); // Clean up subscription
  }
}

function sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
  if (!direction || !column) {
    return datalist;
  }

  return [...datalist].sort((a, b) => {
    const valA = a[column];
    const valB = b[column];

    if (typeof valA === "number" && typeof valB === "number") {
      return direction === "asc" ? valA - valB : valB - valA;
    }

    const strA = valA?.toString().toLowerCase() ?? "";
    const strB = valB?.toString().toLowerCase() ?? "";
    const res = strA.localeCompare(strB);

    return direction === "asc" ? res : -res;
  });
}
