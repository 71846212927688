import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

export interface CompanyDataModel {
  companyId: number;
  companyCode: string;
  companyName: string;
  emailAddress?: string;
}

export function alphaNumericValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const valid = /^[a-zA-Z0-9]*$/.test(control.value);
    return valid ? null : { alphaNumeric: true };
  };
}