import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from "@angular/core";
import { CampaignCustomerListModel, CampaignListModel } from "../../../variables/campaign";
import { CampaignService } from "../../../services/campaign.service";
import { CertificateStatus, compare } from "../../../variables/constant";
import { SortColumn, SortDirection } from "../../../services/sortable.directive";
import { UntypedFormBuilder } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { SVG } from "src/app/common/svg";
import { CampaignSubmittedCertificateComponent } from "../campaign-submitted-certificate/campaign-submitted-certificate.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FormService } from "src/app/services/form.service";
import { ReasonDeniedModel } from "src/app/variables/form";
import { PdfService } from "src/app/services/pdf.services";

function sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
  if (direction === "" || column === "") {
    return datalist;
  } else {
    return [...datalist].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === "asc" ? res : -res;
    });
  }
}

// Get Campaign List Initial
const getCustomerListInitial = async (
  campaignService: CampaignService,
  data: any
): Promise<CampaignListModel[]> => {
  let response = await campaignService
    .getCampaignsCustomersByCompanyCode(data)
    .toPromise()
    .catch((error) => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          // alert(error["message"]);
        }
      }
      console.log(error);
      return null;
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      // alert(response["message"]);
    }
    return response as any;
  }
};

const exportSelectedCustomers = async (
  campaignService: CampaignService,
  data: any
): Promise<CampaignListModel[]> => {
  let response = await campaignService
    .exportCampaignsCustomer(data)
    .toPromise()
    .catch((error) => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          // alert(error["message"]);
        }
      }
      console.log(error);
      return null;
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      // alert(response["message"]);
    }
    return response as any;
  }
};


const getPdf = async (pdfService: PdfService, fileName: string): Promise<string> => {
  let data = await pdfService.getPdfMaster(fileName)
    .toPromise()
    .catch(error => {
      console.log(error);
      if (error.ResponseException != undefined && error.ResponseException != "") {
        alert(error.ResponseException);
      }
    });

  if (data instanceof Object) {
    return data["result"]
  }

  return null;
}

const approvePdf = async (formService: FormService, customerId: number, fileName: string): Promise<any> => {

  let response = await formService.approvePdf(customerId, fileName)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["ResponseException"] != "") {
          alert(error["ResponseException"]);
        }
      }
      alert("Something went wrong. Please take note of the time this happened and contact your administrator. Error:" + error);
      console.log(error);
      location.reload();
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response["result"];
  }

}

const denyPdf = async (formService: FormService, customerId: number, reasonDenied: string): Promise<any> => {

  let response = await formService.denyPdf(customerId, reasonDenied)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["ResponseException"] != "") {
          alert(error["ResponseException"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response["result"];
  }
}

@Component({
  selector: 'app-customer-reports',
  templateUrl: './customer-reports.component.html',
  styleUrls: ['./customer-reports.component.scss']
})
export class CustomerReportsComponent implements OnInit {
  @ViewChild("modalnewPDF") modalnewPDF: TemplateRef<any>;
  @ViewChild('selectAll', { static: false }) selectAll!: ElementRef;

  searchResultListAll: CampaignCustomerListModel[] = [];
  searchResultList: CampaignCustomerListModel[];
  loading: boolean = true;
  collectionSize: number = 0;
  page: number = 1;
  pageSize: number = 10;
  pageForms: number = 1;
  searchTerm: string = "";
  currentColumn: SortColumn = "";
  currentSortBy: SortDirection = "desc";
  selectedIds: any[] = [];
  exportIcon = SVG.exportIcon;

  pdfFile: SafeResourceUrl;
  selectedCertificate: any;
  approveLoading: boolean = false;
  customerFormReason: ReasonDeniedModel;
  reasonDenied: string = "";
  certificateStatus = CertificateStatus;
  
  constructor(
    private campaignService: CampaignService,
    public formBuilder: UntypedFormBuilder,
    public sanitizer: DomSanitizer,
    private router: Router,
    private activeModal: NgbModal,
    private pdfService : PdfService,
    private formService : FormService
  ) { }

  ngOnInit(): void {
    this.getCustomersList();
  }

  getCustomersList(search?: any) {
    let payload = {
      search: search || ''
    };
    getCustomerListInitial(this.campaignService, payload).then((data: any) => {
      this.loading = false;
      if (data) {
        this.searchResultListAll = data;
        this.searchResultList = data;
        this.collectionSize = data.length;
      }
    });
  }

  exportCustomers() {
    console.log(this.selectedIds);
    let payload = {
      customerIds: this.selectedIds || []
    };
    exportSelectedCustomers(this.campaignService, payload).then((res: any) => {
      if (res) {
        this.loading = false;
        this.selectedIds = [];
        const base64Excel = res['file']['fileContents'];
        this.downloadExcel(base64Excel, res['fileName']);
      }
    });
  }

  downloadExcel(base64String: string, fileName: string) {
    // Convert base64 string to binary data
    const byteCharacters = atob(base64String);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a download link for the Blob and trigger download
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName || 'download.xlsx';

    // Append link to body, click, and remove it
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the object URL to free memory
    URL.revokeObjectURL(url);
  }

  showSubmittedCertificates(data) {
    const modalRef = this.activeModal.open(CampaignSubmittedCertificateComponent, { centered: true, size: 'xl', scrollable: true });
    modalRef.componentInstance.campaign = data;
    modalRef.result.then(
      (data: any) => {
        // this.loadData();
      },
      (reason: any) => { }
    );
  }

  // Handle "Select All" functionality
  selectAllCheckbox(event: any) {
    if (event.target.checked) {
      this.selectedIds = this.searchResultList.map(item => item.customerId);
    } else {
      this.selectedIds = [];
    }
  }

  // Check if the checkbox is selected
  isChecked(id: string) {
    return this.selectedIds.includes(id);
  }

  // Handle single checkbox change
  onCheckboxChange(event: any, id: string) {
    if (event.target.checked) {
      this.selectedIds.push(id);
    } else {
      this.selectedIds = this.selectedIds.filter(i => i !== id);
    }

    if (this.selectedIds.length == 0) {
      this.selectAll.nativeElement.checked = false;
    } else {
      this.selectAll.nativeElement.checked = true;
    }
  }

  onSort(event) {
    this.page = 1; //bring back to page 1
    var column = event.target.getAttribute("sortable");
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort
    } else {
      if (this.currentColumn == "") {
        //first sort
        this.currentColumn = column;
        if (this.currentColumn == "campaignName") {
          //by campaignName, state is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.searchResultList = sortData(this.searchResultList, column, this.currentSortBy);
  }

  createCampaign() {
    this.router.navigate(["/admin/campaign/create"]);
  }

  getBackgroundColor(days: any): string {
    switch (true) {
      case days >= 1 && days <= 12:
        return "#FF7A45";
      case days > 12 && days <= 47:
        return "#73D13D";
      case days > 47 && days <= 66:
        return "#73D13D";
      case days > 66 && days <= 85:
        return "#73D13D";
      case days > 85:
        return "#73D13D";
      default:
        return "#6482A0"; // Default color if none of the cases match
    }
  }

  searchData(_term: string) {
    let term = _term.toLowerCase();
    this.searchTerm = term;
    if (_term == "") {
      this.searchResultList = this.searchResultListAll;
    } else {
      // this.getCustomersList(term);
      this.searchResultList = this.searchResultListAll.filter(
        (data) =>
          (data.campaignName == null ? "" : data.campaignName).toLowerCase().indexOf(term) > -1
      );
    }
    this.collectionSize = this.searchResultList.length;
    this.page = 1;
  }

  resetPage() {
    this.page = 1;
    this.pageForms = 1;
  }

  getPdf(modal, file, certificate) {
    //this.activeModal.dismissAll();
    getPdf(this.pdfService, file).then(data => {
      if (data != "" && data != undefined) {
        this.selectedCertificate = certificate;
        this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true });
      }
    });
    setTimeout(this.checkModalBody, 1000);
  }

  checkModalBody() {
    //add class modal-open to fix 
    if (document.getElementsByClassName("modal").length !== 0 && document.getElementsByClassName("modal-backdrop").length !== 0) {
      //alert(document.getElementsByClassName("modal-open").length);
      if (document.getElementsByClassName("modal-open").length == 0) {
        document.body.classList.add("modal-open");
      }
    }
  }

  closeModal() {
    this.activeModal.dismissAll();
  }

  approvePdf() {
    this.approveLoading = true;
    approvePdf(this.formService, this.selectedCertificate.currentCertCustomerFormId, this.selectedCertificate.currentCertWebFileName).then(data => {
      if (data != null) {
        this.getCustomersList();
        this.closeModal();
        this.approveLoading = false;
      }
    });
  }

  getReasonDenied(modal) {
    this.reasonDenied = "";
    this.activeModal.open(modal, { centered: true, size: 'sm', scrollable: true });
  }

  denyPdf() {
    denyPdf(this.formService, this.selectedCertificate.currentCertCustomerFormId, this.reasonDenied).then(data => {
      if (data != null) {
        this.getCustomersList();
        this.closeModal();
      }
    });
  }
}