import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { IUserDataModel, IRoleDataModel } from "../../../variables/user";
import { NgbTypeahead, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Subject, Observable, merge } from "rxjs";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBlankModel, FormsBasicDataModel } from "src/app/variables/form";
import { FormService } from "src/app/services/form.service";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

const getDocument = async (formService: FormService, form: FormBlankModel): Promise<string> => {
  let data = await formService
    .getBlankForm(form)
    .toPromise()
    .catch((error) => {
      console.log(error);
      if (error.message != undefined && error.message != "") {
        alert(error.message);
      }
    });

  console.log("data: ", data);
  if (data instanceof Object) {
    // let f = data["result"];
    // let base64 = f.fileContents;
    // var raw = window.atob(base64);
    // var rawLength = raw.length;
    // var array = new Uint8Array(new ArrayBuffer(rawLength));
    // var i = 0;
    // for (i = 0; i < rawLength; i++) {
    //     array[i] = raw.charCodeAt(i);
    // }
    // const blob = new Blob([array], { type: 'application/pdf' });
    // console.log(window.URL.createObjectURL(blob));
    return data["result"];
  }
  return null;
};

@Component({
  selector: "app-form-document-modal",
  templateUrl: "./form-document-modal.component.html",
  styleUrls: ["./form-document-modal.component.scss"]
})
export class FormDocumentModalComponent implements OnInit {
  @Input() row: any;
  model: any;
  pdfFile: SafeResourceUrl;

  constructor(
    private router: Router,
    route: ActivatedRoute,
    private formService: FormService,
    public sanitizer: DomSanitizer,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.getDocument()
  }
  modalClose() {
    this.activeModal.close();
  }
  getDocument() {
    let payload = {
      state: this.row.statecode,
      form: this.row.form
    };
    getDocument(this.formService, payload).then((data) => {
      this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
      console.log('this.pdfFile: ', this.pdfFile);
    });
  }
}
