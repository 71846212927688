import { Component, Inject, ViewEncapsulation, ElementRef, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl, SafeHtml } from '@angular/platform-browser';
import { Session } from 'protractor';
import { PdfService } from '../../../services/pdf.services';
import { CommonService } from '../../../services/common.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StatesService } from '../../../services/states.service';
import { FormsByStatesDataModel } from '../../../variables/states';
import { CustomerService } from '../../../services/customer.service';
import { ClaimType } from '../../../variables/claim-type';
import { AuthService } from '../../../services/auth.service';
import { CertificateDataModel, CertificateModel, FilledInResultDataModel } from '../../../variables/customer';
import { HTMLWithRulesDataModel, HTMLValidationDataModel } from '../../../variables/form';
import { Location } from '@angular/common';




const prefillPdfTemp = async (pdfService: PdfService, certificate: CertificateModel): Promise<FilledInResultDataModel> => {

  let data = await pdfService.prefillPdfTemp(certificate)
    .toPromise()
    .catch(error => {
      console.log(error);
      if (error.message != undefined && error.message != "") {
        alert(error.message);
      }
    });

  if (data instanceof Object) {
    let result = data["result"];
    let f = result["file"];
    let base64 = f.fileContents;
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    var i = 0;
    for (i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }


    const blob = new Blob([array], { type: 'application/pdf' });
    console.log(window.URL.createObjectURL(blob));
    let finaldata: FilledInResultDataModel = <FilledInResultDataModel>{
      url: window.URL.createObjectURL(blob),
      fileName: result["fileName"],
      referenceNumber: result["referenceNumber"]
    };

    return finaldata;
  }

  return null;
}


const prefillPdf = async (pdfService: PdfService, certificate: CertificateModel): Promise<FilledInResultDataModel> => {

  let data = await pdfService.prefillPdf(certificate)
    .toPromise()
    .catch(error => {
      if (error.message != undefined && error.message != "") {
        alert(error.message);
      }
    });

  if (data instanceof Object) {
    let result = data["result"];
    let f = result["file"];
    let base64 = f.fileContents;
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    var i = 0;
    for (i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }


    const blob = new Blob([array], { type: 'application/pdf' });
    console.log(window.URL.createObjectURL(blob));
    let finaldata: FilledInResultDataModel = <FilledInResultDataModel>{
      url: window.URL.createObjectURL(blob),
      fileName: result["fileName"],
      referenceNumber: result["referenceNumber"]
    };

    return finaldata;
  }

  return null;
}


const getHtml = async (pdfService: PdfService, formName: string, stateName: string, isEsign?: boolean): Promise<string> => {

  let response = await pdfService.getHtml(formName, stateName, isEsign).toPromise()
    .catch(error => {
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["data"] != undefined) {
      return response["data"];
    }
    else {
      if (response["message"] != "")
        alert(response["message"]);
    }
  }
}

const getFormById = async (statesService: StatesService, id: number): Promise<FormsByStatesDataModel> => {

  let response = await statesService.getFormById(id)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response instanceof Object) {
      if (response["message"] != "" && response["message"] != undefined) {
        alert(response["message"]);
      }
      let result = <FormsByStatesDataModel>response["result"];

      return result;
    }
    return null;
  }

}

//HTML RULES
const getHTMLWithRules = async (pdfService: PdfService, formName: string, stateName: string): Promise<HTMLWithRulesDataModel> => {

  let response = await pdfService.getHTMLRules(formName, stateName).toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    console.log("OBJ");
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }

    if (response instanceof Object) {
      if (response["message"] != "" && response["message"] != undefined) {
        alert(response["message"]);
      }
      let result = <HTMLWithRulesDataModel>response["result"];

      return result;
    }
    return null;
  }

}

const approveFillIn = async (pdfService: PdfService, referenceNumber: any): Promise<any> => {

  let response = await pdfService.approveFillIn(referenceNumber)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert("FIle Uploaded and " + response["message"]);
    }
    if (response instanceof Object) {      
      let result = response["result"];

      return result;
    }
    return null;
  }

}

@Component({
  selector: 'app-fill-in',
  templateUrl: './fill-in.component.html',
  styleUrls: ['./fill-in.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FillInComponent {
  sourceFolder = "/assets/blankforms/";
  dateNow: string = (new Date()).toLocaleDateString();
  dateTimeNow: Date = new Date();

  formId: number = null;

  //destinationFile: string = "";
  destinationFile: SafeResourceUrl;

  fileName: string = "";
  formName: string = "";
  state: string = "";
  templateName: string = "";
  formHtml: SafeHtml;
  esignHtml: SafeHtml;


  data: CertificateDataModel[] = [];

  signatureForm: UntypedFormGroup;
  signatureSubmitted: boolean = false;
  emptySignaturePad: string = "";

  consentForm: UntypedFormGroup;
  consentSubmitted: boolean = false;

  isSummary: boolean = false;
  referenceNumber: string = "";
  emailRecipient: string = "";
  newFileName: string = "";

  isWait: boolean = false;

  htmlRules: HTMLValidationDataModel[];
  //public viewElement: ElementRef;
  //public element: any;
  hasErrors: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private http: HttpClient,
    private pdfService: PdfService,
    public commonService: CommonService,
    public sanitizer: DomSanitizer,
    private activeModal: NgbModal,
    private customerService: CustomerService,
    private authService: AuthService,
    statesService: StatesService,
    route: ActivatedRoute,
    router: Router,
    private _ngZone: NgZone,
    public location : Location
  ) {
    this.formId = parseInt(route.snapshot.params["id"]);
    if (this.formId != undefined && this.formId != null) {
      getFormById(statesService, this.formId).then(data => {
        if (data != undefined && data != null) {
          this.state = data.state;
          this.formName = data.form;
          this.templateName = this.state + "_" + this.formName;
          this.fileName = this.templateName + ".pdf";
          //let _file: string = this.sourceFolder + this.fileName;
          //this.destinationFile = this.sanitizer.bypassSecurityTrustResourceUrl(_file);
          //getHtml(pdfService, this.formName).then(result => {
          getHTMLWithRules(pdfService, this.formName, this.state).then(result => {
            if (result != undefined && result != null) {

              this.formHtml = this.sanitizer.bypassSecurityTrustHtml(result["html"]);

              window['counterComponent'] = { component: this, zone: this._ngZone };
              this.htmlRules = result["htmlrules"];
            }
            else {
              alert("Form is not available. Please contact support.");
              router.navigate(["/eCert/certificates"]);
            }
          });

          getHtml(pdfService, this.formName, this.state, true).then(result => {
            if (result != undefined && result != null && result != "") {
              this.esignHtml = this.sanitizer.bypassSecurityTrustHtml(result);
            }
          });
          this.signatureForm = this.formBuilder.group({
            personSigning: ['', [Validators.required,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
            personTitle: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
            signatureData: ['', Validators.required]
          });

          this.consentForm = this.formBuilder.group({
            hasConsent: [false, Validators.requiredTrue]
          });

          let _certificate: CertificateModel = {
            templateName: this.templateName,
            dateSubmitted: this.dateTimeNow,
            formId: this.formId,
            data: null
          };
          prefillPdfTemp(pdfService, _certificate).then(result => {
            //this.destinationFile = result.url;
            this.destinationFile = this.sanitizer.bypassSecurityTrustResourceUrl(result.url);
          });
        }
        else {
          router.navigate(["/eCert/certificates"]);
        }
      });
    }



  }

  get sf() { return this.signatureForm.controls; }

  get cf() { return this.consentForm.controls; }

  //getDestinationFile() {
  //  return this.sanitizer.bypassSecurityTrustResourceUrl(this.destinationFile);
  //}


  //HTML RULES
  checkHTMLRules() {
    let message = "";
    let blnValid = true;
    if (this.htmlRules.length > 0) {
      for (var rule of this.htmlRules) {
        if (rule.isRequired) {
          let input: HTMLInputElement = <HTMLInputElement>document.getElementById(rule.pdfFieldName);
          if (input != null) {
            console.log("INPUT", input);
            if (input.type == "text" || input.type == "date") { //check input type
              if (input.value == null || input.value.trim() == "") { //check if there's a value
                if (rule.checkedField != null && rule.checkedField != "") { //check if checkbox needed for click
                  let chk: HTMLInputElement = <HTMLInputElement>document.getElementById(rule.checkedField);
                  if (chk != null && chk.checked) {
                    message = message + " SELECTION CHECKED <br/>";
                    blnValid = false;
                  }
                }
                else {
                  message = message + " TEXTBOX REQUIRED <br/>";
                  blnValid = false;
                }
              }
            }
            else {
              console.log("NOT TEXT");
              if (!input.checked && (input.value == null || input.value.trim() == "" || input.value =='on')) { //added checking of input.value to allow select, chbox value is always on
                if (rule.checkedField != null && rule.checkedField != "") { //check if checkbox needed for click
                  let chk: HTMLInputElement = <HTMLInputElement>document.getElementById(rule.checkedField);
                  if (chk != null && chk.checked) {
                    message = message + " SELECTION CHECKED <br/>";
                    blnValid = false;
                  }
                }
                else {
                  message = message + " TEXTBOX REQUIRED <br/>";
                  blnValid = false;
                }
              }
              

            }
          }
          else {
            console.log("NOT INPUT");
            //let inputCount = document.getElementsByName(rule.pdfFieldName).length;

            let blnHasSelection = false;
            <HTMLInputElement[]><any>document.getElementsByName(rule.pdfFieldName).forEach((item) => {
              let chk = <HTMLInputElement>item;
              if (chk.checked) {
                blnHasSelection = true;
                return;
              } else {
                if (chk.type == "text") {
                  if (chk.value.trim() !== "") {
                    blnHasSelection = true;
                    return;
                  }
                }
              }
            });
            console.log(blnHasSelection);
            if (!blnHasSelection) {
              if (rule.checkedField != null && rule.checkedField != "") { //check if checkbox needed to be clicked or if textbox has value
                let chk: HTMLInputElement = <HTMLInputElement>document.getElementById(rule.checkedField);
                if (chk != null && chk.checked) {
                  message = message + " SELECTION CHECKED <br/>";
                  blnValid = false;
                } else {
                  if (chk.type == "text") {
                    if (chk.value.trim() !== "") {
                      blnValid = false;
                    }
                  }
                }
              } else {
                blnValid = false;
              }
            }
          }
        }
      }
    }
    return blnValid;
  }

  openModalSignature(modal) {
    
    let blnValid = this.checkHTMLRules();
    if (blnValid) {
      this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true, windowClass: 'customClass' });
    }
    else {
      this.hasErrors = true;
      alert("Please complete required certificate fields");
    }
  }

  submitSignature(data) {
    if (this.emptySignaturePad == "") {
      this.emptySignaturePad = data;
    }
    if (data != this.emptySignaturePad) {
      this.sf.signatureData.setValue(data);
    }
    else {
      this.sf.signatureData.setValue("");
    }
  }

  onSignatureSubmit(modal) {
    this.signatureSubmitted = true;
    if (this.sf.signatureData.value == this.emptySignaturePad) {
      this.sf.signatureData.setValue("");
    }
    if (this.signatureForm.invalid) {
      return;
    }
    else {
      this.activeModal.dismissAll();
      this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true, backdrop: 'static', keyboard: false });
    }
  }

  onConsentSubmit() {
    this.consentSubmitted = true;
    if (this.consentForm.invalid) {
      return;
    }
    else {
      this.submitForm();
    }
  }

  closeModal() {
    this.activeModal.dismissAll();
  }

  submitForm() {
    this.isWait = true;
    this.commonService.setCursorWait(true);
    this.data = [];
    //Get all txtBox
    for (let i = 1; i < 100; i++) {
      let txtBox: HTMLInputElement = <HTMLInputElement>document.getElementById("txtBox" + i);
      if (txtBox == null) {
        break;
      }
      else {
        this.data.push(<CertificateDataModel>{ field: "txtBox" + i, value: txtBox.value });
      }
    }

    //Get all chkBox
    for (let i = 1; i < 100; i++) {
      let chkBox: HTMLInputElement = <HTMLInputElement>document.getElementById("chkBox" + i);
      if (chkBox == null) {
        break;
      }
      else {
        this.data.push(<CertificateDataModel>{ field: "chkBox" + i, value: chkBox.checked ? "Yes" : "No" });
      }
    }

    this.data.push(<CertificateDataModel>{ field: "PrintedName", value: this.sf.personSigning.value });
    this.data.push(<CertificateDataModel>{ field: "Title", value: this.sf.personTitle.value });

    //imgSignature
    this.data.push(<CertificateDataModel>{ field: "imgSignature", value: this.sf.signatureData.value });

    this.dateTimeNow = new Date();
    let _certificate: CertificateModel = {
      templateName: this.templateName,
      dateSubmitted: this.dateTimeNow,
      formId: this.formId,
      data: this.data
    };
    console.log(this.data);
    prefillPdf(this.pdfService, _certificate).then(result => {
      approveFillIn(this.pdfService, result.referenceNumber).then(data => {
        //this.destinationFile = result.url;
        this.destinationFile = this.sanitizer.bypassSecurityTrustResourceUrl(result.url);
        this.referenceNumber = result.referenceNumber;
        this.emailRecipient = this.authService.getEmail();
        this.newFileName = result.fileName;
        this.isSummary = true;
        this.activeModal.dismissAll();
        this.commonService.setCursorWait(false);
        this.isWait = false;
      });

    });
  }


  //HTML Functions for Checkboxes
  enableControlsFunction(chk, txt, cName, txt2) {
    this._ngZone.run(() => this.enableElements(chk, txt, cName, txt2));
  }

  toggleControlsFunction(chk, txt, cName, txt2) {
    this._ngZone.run(() => this.toggleEnableElements(chk, txt, cName, txt2));
  }

  toggleSelectControlsFunction(togglename, disablename, requireCheckbox, checkboxClass) {
    this._ngZone.run(() => this.toggleSelectEnableElements(togglename, disablename, requireCheckbox, checkboxClass));
  }

  ontothenext(parent, child) {
    this._ngZone.run(() => this.ontothenextOne(parent, child));
  }

  compareDates(dateFrom, dateTo) {
    this._ngZone.run(() => this.comparetheseDates(dateFrom, dateTo));
  }

  linkData(fromData1, toData2) {
    this._ngZone.run(() => this.linktheseData(fromData1, toData2));
  }

  revertToValue(xValue, cName) {
    this._ngZone.run(() => this.revertValue(xValue, cName));
  }


  enableElements(chk, txt, cName, txt2) {
    if (chk.checked) {
      let elements = document.getElementsByClassName(cName);
      for (let e = 0; e < elements.length; e++) {
        elements[e].setAttribute("disabled", "disabled");
        let input: HTMLInputElement = <HTMLInputElement>document.getElementById(elements[e].id);
        input.value = "";
        if (input.checked == true) {
          input.checked = false;
        }
      }
      if (txt != null) {
        document.getElementById(txt).removeAttribute("disabled");
      }
      if (txt2 != null) {
        document.getElementById(txt2).removeAttribute("disabled");
      }
    }
  }

  toggleEnableElements(chk, txt, cName, txt2) {
    let elements = document.getElementsByClassName(cName);
    for (let e = 0; e < elements.length; e++) {
      elements[e].setAttribute("disabled", "disabled");
      let input: HTMLInputElement = <HTMLInputElement>document.getElementById(elements[e].id);
      input.value = "";
      //special case for form ID-st101 on txtBox9
      if (cName.indexOf('skip') > 0 && txt == null) {
        input.value = "N/A";
      } 
      if (input.checked == true) {
        input.checked = false;
      }
    }
    if (chk.checked) {
      if (txt != null && txt !== 'mass') {
        document.getElementById(txt).removeAttribute("disabled");
      }
      if (txt2 != null) {
        document.getElementById(txt2).removeAttribute("disabled");
      }
      if (txt == "mass") {
        let elements = document.getElementsByClassName(cName);
        for(let e = 0; e < elements.length; e++) {
          elements[e].removeAttribute("disabled");
        }
        //special case for form ID-st101 on sec5
        if (cName == 'sec5') {
          let textinput: HTMLInputElement = <HTMLInputElement>document.getElementById('txtBox9');
          textinput.value = 'N/A';
        }
      }
    } else {
      if (txt != null) {
        (document.getElementById(txt) as any).disabled = true;
      }
      if (txt2 != null) {
        (document.getElementById(txt2) as any).disabled = true;
      }
      if (txt == "mass") {
        let elements = document.getElementsByClassName(cName);
        for (let e = 0; e < elements.length; e++) {
          elements[e].setAttribute("disabled", "disabled");
        }
      }
    }
  }

  toggleSelectEnableElements(togglename, disablename, requireCheckbox, checkboxClass) {

    //disable everything with disablename and only enable those that have togglename
    let disableElements = document.getElementsByClassName(disablename);
    for (let e = 0; e < disableElements.length; e++) {
      disableElements[e].setAttribute("disabled", "disabled");
      let input: HTMLInputElement = <HTMLInputElement>document.getElementById(disableElements[e].id);
      if (input.value !== "") {
        input.value = "";
      }
      if (input.checked == true) {
        input.checked = false;
      }
    }
    //uncheck everything
    let uncheckElements = document.getElementsByClassName(checkboxClass);
    for (let e = 0; e < uncheckElements.length; e++) {
      uncheckElements[e].removeAttribute("checked");
    }
    //checked selected
    if (requireCheckbox !== "") {
      document.getElementById(requireCheckbox).setAttribute("checked", "true");
    }
    //enable elements
    let elements = document.getElementsByClassName(togglename);
    for (let e = 0; e < elements.length; e++) {
      elements[e].removeAttribute("disabled");      
    }
  }

  ontothenextOne(parent, child) {
    let parentobj: HTMLInputElement = <HTMLInputElement>document.getElementById(parent);
    let childobj = document.getElementsByClassName(child);
    if (parentobj.value !== "") {
      for (let e = 0; e < childobj.length; e++) {
        childobj[e].removeAttribute("disabled");        
      }
    } else {
      for (let e = 0; e < childobj.length; e++) {
        let input: HTMLInputElement = <HTMLInputElement>document.getElementById(childobj[e].id);
        let childattr = input.getAttribute("child-data");
        if (input.value !== "" && input.type == 'text' && childattr !== "") {
          input.value = "";
          this.ontothenext(childobj[e].id, childattr);
        } else if (input.value !== "" && input.type !== 'text' && childattr !== "") {
          var dropdownList = (document.getElementById(childobj[e].id)) as HTMLSelectElement;
          dropdownList.selectedIndex = 0;
          this.ontothenext(childobj[e].id, childattr);
        }
        childobj[e].setAttribute("disabled", "disabled");
      }
    }
  }

  comparetheseDates(DateFrom, DateTo) {
    let DateFrom1: HTMLInputElement = <HTMLInputElement>document.getElementById(DateFrom);
    let DateTo1: HTMLInputElement = <HTMLInputElement>document.getElementById(DateTo);
    let error1: HTMLInputElement = <HTMLInputElement>document.getElementById("error1");
    let error2: HTMLInputElement = <HTMLInputElement>document.getElementById("error2");
    var Date1 = Date.parse(DateFrom1.value);
    var Date2 = Date.parse(DateTo1.value);
    let elements = document.getElementsByClassName("btn-primary");
    if (DateFrom1.value !== "" && DateTo1.value !== "") {
      if (!isNaN(Date1) && !isNaN(Date2)) {
        if (Date1 > Date2) {
          error1.innerHTML = "This date cannot be greater than the other date";
          for (let e = 0; e < elements.length; e++) {
            elements[e].setAttribute("disabled", "disabled");
          }
        } else {
          for (let e = 0; e < elements.length; e++) {
            error1.innerHTML = "";
            error2.innerHTML = "";
            elements[e].removeAttribute("disabled");
          }
        }
      } else {
        error1.innerHTML = "Invalid Dates";
        error2.innerHTML = "Invalid Dates";
        for (let e = 0; e < elements.length; e++) {
          elements[e].setAttribute("disabled", "disabled");
        }
      }
    }    
  }

  linktheseData(fromData1, toData2) {
    let FromData: HTMLInputElement = <HTMLInputElement>document.getElementById(fromData1);
    let toData: HTMLInputElement = <HTMLInputElement>document.getElementById(toData2);

    toData.value = FromData.value;
  }

  revertValue(xValue, cName) {
    let disableElements = document.getElementsByClassName(cName);
    for (let e = 0; e < disableElements.length; e++) {
      let input: HTMLInputElement = <HTMLInputElement>document.getElementById(disableElements[e].id);
      if (input.type == "text") {
        input.value = xValue;
      }
    }
  }

}
