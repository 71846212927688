import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from 'ngx-clipboard';
import { AdminLayoutRoutes } from './admin-layout.routing';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePadModule } from '@ng-plus/signature-pad';
import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { CreateCampaignComponent } from '../../pages/campaign/create-campaign/create-campaign.component';
import { CampaignSearchComponent } from '../../pages/campaign/campaign-search/campaign-search.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { UserSearchComponent } from '../../pages/user/user-search/user-search.component';
import { UserModalComponent } from '../../pages/user/user-modal/user-modal.component';
import { FormSearchComponent } from '../../pages/form/form-search/form-search.component';
import { CompanyProfileComponent } from '../../pages/company/company-profile/company-profile.component';
import { TemplateEditorComponent } from '../../pages/editor/template-editor/template-editor.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { UserProfileComponent } from '../../pages/user-profile/user-profile.component';
import { FormExpiryModalComponent } from '../../pages/form/form-expiry-modal/form-expiry-modal.component';
import { CertificatesComponent } from '../../pages/certificates/certificates/certificates.component';
import { LoadingComponent } from '../../helper/loading';
import { InfoModalComponent } from 'src/app/pages/eCert/info-modal/info-modal.component';
import { CampaignFormModalComponent } from 'src/app/pages/form/campaign-form-modal/campaign-form.component';
import { FormDocumentModalComponent } from 'src/app/pages/form/form-document-modal/form-document-modal.component';
import { CreateCampaignNewComponent } from 'src/app/pages/campaign/create-campaign-new/create-campaign-new.component';
import { CampaignCreateWarningComponent } from 'src/app/pages/campaign/campaign-create-warning/campaign-create-warning.component';
import { CampaignListComponent } from 'src/app/pages/campaign/campaign-list/campaign-list.component';
import { CampaignViewComponent } from 'src/app/pages/campaign/campaign-view/campaign-view.component';
import { ComponentsModule } from 'src/app/components/components.module';
import { CustomModalComponent } from 'src/app/components/custom-modal/custom-modal.component';
import { CustomerReportsComponent } from 'src/app/pages/campaign/customer-reports/customer-reports.component';
import { CampaignSubmittedCertificateComponent } from 'src/app/pages/campaign/campaign-submitted-certificate/campaign-submitted-certificate.component';
import { CustomDatePipe } from 'src/app/common/custom-date.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    FormsModule,
    NgbModule,
    ClipboardModule,
    ReactiveFormsModule,
    SignaturePadModule,
    NgMultiSelectDropDownModule,
    AngularEditorModule,
    ComponentsModule
  ],
  declarations: [    
    DashboardComponent,
    CreateCampaignComponent,    
    CampaignSearchComponent,
    CampaignListComponent,
    CustomerReportsComponent,
    CampaignSubmittedCertificateComponent,
    CampaignViewComponent,
    UserSearchComponent,
    UserModalComponent,
    CampaignFormModalComponent,
    FormDocumentModalComponent,
    CreateCampaignNewComponent,
    CampaignCreateWarningComponent,
    CustomModalComponent,
    FormSearchComponent,
    CompanyProfileComponent,
    TemplateEditorComponent,
    FormExpiryModalComponent,
    LoadingComponent,
    CertificatesComponent,
    InfoModalComponent,
    CustomDatePipe
  ],
  exports: [
    DashboardComponent,
    CreateCampaignComponent,    
    CampaignSearchComponent,
    CampaignListComponent,
    CustomerReportsComponent,
    CampaignSubmittedCertificateComponent,
    CampaignViewComponent,
    UserSearchComponent,
    UserModalComponent,
    CampaignFormModalComponent,
    FormDocumentModalComponent,
    CreateCampaignNewComponent,
    CampaignCreateWarningComponent,
    CustomModalComponent,
    FormSearchComponent,
    CompanyProfileComponent,
    TemplateEditorComponent,
    FormExpiryModalComponent,
    LoadingComponent,
    CertificatesComponent,
    InfoModalComponent
  ],
  bootstrap: [
    DashboardComponent,
    CreateCampaignComponent,
    CampaignSearchComponent,
    CampaignListComponent,
    CustomerReportsComponent,
    CampaignSubmittedCertificateComponent,
    CampaignViewComponent,
    UserSearchComponent,
    UserModalComponent,
    CampaignFormModalComponent,
    FormDocumentModalComponent,
    CreateCampaignNewComponent,
    CampaignCreateWarningComponent,
    CustomModalComponent,
    FormSearchComponent,
    CompanyProfileComponent,
    TemplateEditorComponent,
    UserProfileComponent,
    FormExpiryModalComponent,
    CertificatesComponent
  ],
  providers: [
    DecimalPipe,
    CustomDatePipe
  ],
  schemas: [NO_ERRORS_SCHEMA] 
})

export class AdminLayoutModule { }
