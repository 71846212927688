import { Component, OnInit, NgZone } from '@angular/core';
import { SortEvent, SortDirection, SortColumn } from '../../../services/sortable.directive';
import { FormsByStatesDataModel } from '../../../variables/states';
import { FormService } from '../../../services/form.service';
import { FormsBasicDataModel, FormCertificateModel, FormFilledInResultDataModel } from '../../../variables/form';
import { PdfService } from '../../../services/pdf.services';
import { SafeResourceUrl, DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CertificateDataModel } from '../../../variables/customer';
import { CommonService } from '../../../services/common.service';
import { HTMLWithRulesDataModel, HTMLValidationDataModel } from '../../../variables/form';
import { FormExpiryModalComponent } from '../form-expiry-modal/form-expiry-modal.component'
import { CampaignFormModalComponent } from '../campaign-form-modal/campaign-form.component';
import { FormDocumentModalComponent } from '../form-document-modal/form-document-modal.component';
import { formatDate } from '@angular/common';


const updateIsCampaignForm = async (formService: FormService, formId: number, data: FormsBasicDataModel): Promise<FormsBasicDataModel> => {

  let response = await formService.updateIsCampaignFormQueryParams(formId, data.isCampaignForm)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = <FormsBasicDataModel>response["result"];

    return result;
  }

}

const getFormList = async (formService: FormService, search): Promise<FormsBasicDataModel[]> => {

  let response = await formService.getAllForms(search)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          formId: obj.formId,
          state: obj.state,
          statecode: obj.statecode,
          status: obj.status,
          updatedBy: obj.updatedBy,
          updatedByEmail: obj.updatedByEmail,
          hasBlankForm: obj.hasBlankForm,
          updatedAt: obj.updatedAt,
          type: obj.type,
          form: obj.form,
          expiringTerm: obj.expiringTerm,
          expiredTerm: obj.expiredTerm,
          isCampaignForm: obj.isCampaignForm
        }
      });
    }
    return null;
  }

}

// Helper function to parse date strings
function parseDateString(dateString: string): Date | null {
  const parsedDate = Date.parse(dateString);
  return isNaN(parsedDate) ? null : new Date(parsedDate);
}

const getPdfPreview = async (formService: FormService, state: string, form: string): Promise<string> => {

  let data = await formService.getPdfPreview(state, form)
    .toPromise()
    .catch(error => {
      console.log(error);
      if (error.message != undefined && error.message != "") {
        alert(error.message);
      }
    });

  if (data instanceof Object) {
    let f = data["result"];
    let base64 = f.fileContents;
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    var i = 0;
    for (i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }


    const blob = new Blob([array], { type: 'application/pdf' });
    console.log(window.URL.createObjectURL(blob));

    return window.URL.createObjectURL(blob);
  }

  return null;
}

const getHtml = async (formService: FormService, formName: string, stateName: string): Promise<string> => {

  let response = await formService.getHtml(formName, stateName).toPromise()
    .catch(error => {
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["data"] != undefined) {
      return response["data"];
    }
    else {
      if (response["message"] != "")
        alert(response["message"]);
    }
  }
}

const prefillPdf = async (formService: FormService, certificate: FormCertificateModel): Promise<string> => {

  let data = await formService.prefillPdf(certificate)
    .toPromise()
    .catch(error => {
      if (error.message != undefined && error.message != "") {
        alert(error.message);
      }
    });

  if (data instanceof Object) {
    let f = data["result"];
    let base64 = f.fileContents;
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));
    var i = 0;
    for (i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }


    const blob = new Blob([array], { type: 'application/pdf' });
    console.log(window.URL.createObjectURL(blob));

    return window.URL.createObjectURL(blob);
  }

  return null;
}

function sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
  if (direction === '' || column === '') {
    return datalist;
  } else {
    return [...datalist].sort((a, b) => {
      const res = compare(a[column], b[column], column);
      return direction === 'asc' ? res : -res;
    });
  }
}

function compare(a: any, b: any, column: SortColumn): number {
  // Check if the column contains date values
  if (isDate(a) && isDate(b)) {
    return new Date(a).getTime() - new Date(b).getTime();
  }

  // Default comparison for strings and numbers
  return a < b ? -1 : a > b ? 1 : 0;
}

// Helper function to check if a value is a valid date
function isDate(value: any): boolean {
  return !isNaN(Date.parse(value));
}


@Component({
  selector: 'app-form-search',
  templateUrl: './form-search.component.html',
  styleUrls: ['./form-search.component.scss']
})
export class FormSearchComponent implements OnInit {
  searchResultListAll: FormsBasicDataModel[] = [];
  searchResultList: FormsBasicDataModel[];

  //paging
  collectionSize: number = 0;
  page: number = 1;
  pageSize: number = 10;


  //search
  searchTerm: string = "";
  searchCampaignForm: boolean = false;

  //sorting
  currentColumn: SortColumn = "";
  currentSortBy: SortDirection = "desc";


  //PDF
  pdfFile: SafeResourceUrl;
  selectedCertificate: FormsBasicDataModel;
  formHtml: SafeHtml;
  data: CertificateDataModel[] = [];
  isWait: boolean = false;
  formName: string = "";
  htmlRules: HTMLValidationDataModel[];

  constructor(private formService: FormService,
    public sanitizer: DomSanitizer,
    public commonService: CommonService,
    private activeModal: NgbModal,
    private _ngZone: NgZone) {
    this.loadData();
  }

  ngOnInit(): void {
  }

  //onSort({ column, direction }: SortEvent) {
  onSort(event) {
    this.page = 1; //bring back to page 1
    var column = event.target.getAttribute("sortable");
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort      
    } else {
      if (this.currentColumn == "") { //first sort
        this.currentColumn = column;
        if (this.currentColumn == "state") { //by default, state is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.searchResultList = sortData(this.searchResultList, column, this.currentSortBy);
  }

  searchDataNew(_term: string) {
    let term = _term.toLowerCase();
    this.searchTerm = term;
    console.log('this.searchTerm: ', this.searchTerm);
    this.loadData()
  }

  searchData(_term: string) {
    let term = _term.toLowerCase();
    this.searchTerm = term;
    if (_term == "") {
      this.searchResultList = this.searchResultListAll;
    }
    else {
      this.searchResultList = this.searchResultListAll.filter(data =>
        (data.state == null ? "" : data.state).toLowerCase().indexOf(term) > -1
        || (data.form == null ? "" : data.form).toLowerCase().indexOf(term) > -1
        || (data.type == null ? "" : data.type).toLowerCase().indexOf(term) > -1
      );
      this.page = 1;
    }
    if (this.searchCampaignForm) {
      this.searchResultList = this.searchResultList.filter(data =>
        data.isCampaignForm);
    }
    this.collectionSize = this.searchResultList.length;
  }

  loadData() {
    getFormList(this.formService, this.searchTerm).then(data => {
      this.searchResultListAll = data;
      this.searchResultList = data;
      this.collectionSize = data.length;
      if (this.searchTerm != "") {
        this.searchData(this.searchTerm);
      }
    });
  }
  showSubmittedCertificates(row) {
    const modalRef = this.activeModal.open(FormDocumentModalComponent, { size: 'xl', centered: true });
    modalRef.componentInstance.row = row;
    modalRef.result.then(
      (data: any) => {
        console.log('data: ', data);
        // this.loadData();
      },
      (reason: any) => {
        console.log(reason);
        // this.loadData();
      }
    );

  }

  updateIsCampaign(row) {
    console.log('row: ', row);
    // row.isCampaignForm = !row.isCampaignForm;
    // updateIsCampaignForm(this.formService, row.formId, row).then(data => {
    //   console.log('data: ', data);
    //   if (data != null) {
    //     this.loadData();
    //   }
    // });
    const modalRef = this.activeModal.open(CampaignFormModalComponent, { centered: true });
    modalRef.componentInstance.row = row;
    modalRef.result.then(
      (data: any) => {
        console.log('data: ', data);
        this.loadData();
      },
      (reason: any) => {
        console.log(reason);
        this.loadData();
      }
    );

  }

  getPdfPreview(modal, certificate) {
    this.activeModal["_config"]["windowClass"] = "modalInvoice out";
    this.formName = certificate.state + "_" + certificate.form;
    getPdfPreview(this.formService, certificate.state, certificate.form).then(data => {
      if (data != "" && data != undefined) {
        this.selectedCertificate = certificate;
        this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        this.activeModal.open(modal, { centered: true, size: 'xl', scrollable: true });
      }
    });
    getHtml(this.formService, certificate.form, certificate.state).then(result => {
      if (result != undefined && result != null && result != "") {

        window['counterComponent'] = { component: this, zone: this._ngZone };
        this.htmlRules = result["htmlrules"];
        this.formHtml = this.sanitizer.bypassSecurityTrustHtml(result);
      }
      else {
        alert("Form is not available. Please contact support.");
      }
    });
  }

  submitForm() {
    this.isWait = true;
    this.commonService.setCursorWait(true);
    this.data = [];
    //Get all txtBox
    for (let i = 1; i < 100; i++) {
      let txtBox: HTMLInputElement = <HTMLInputElement>document.getElementById("txtBox" + i);
      if (txtBox == null) {
        break;
      }
      else {
        this.data.push(<CertificateDataModel>{ field: "txtBox" + i, value: txtBox.value });
      }
    }

    //Get all chkBox
    for (let i = 1; i < 100; i++) {
      let chkBox: HTMLInputElement = <HTMLInputElement>document.getElementById("chkBox" + i);
      if (chkBox == null) {
        break;
      }
      else {
        this.data.push(<CertificateDataModel>{ field: "chkBox" + i, value: chkBox.checked ? "Yes" : "No" });
      }
    }

    let _certificate: FormCertificateModel = {
      templateName: this.formName,
      data: this.data
    };

    prefillPdf(this.formService, _certificate).then(data => {
      this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
      this.commonService.setCursorWait(false);
      this.isWait = false;
    });
    console.log(this.pdfFile);
  }

  closeModal() {
    this.activeModal.dismissAll();
  }

  //HTML Functions for Checkboxes
  enableControlsFunction(chk, txt, cName, txt2) {
    this._ngZone.run(() => this.enableElements(chk, txt, cName, txt2));
  }


  toggleControlsFunction(chk, txt, cName, txt2) {
    this._ngZone.run(() => this.toggleEnableElements(chk, txt, cName, txt2));
  }


  enableElements(chk, txt, cName, txt2) {
    if (chk.checked) {
      let elements = document.getElementsByClassName(cName);
      for (let e = 0; e < elements.length; e++) {
        elements[e].setAttribute("disabled", "disabled");
        let input: HTMLInputElement = <HTMLInputElement>document.getElementById(elements[e].id);
        input.value = "";
      }
      if (txt != null) {
        document.getElementById(txt).removeAttribute("disabled");
      }
      if (txt2 != null) {
        document.getElementById(txt2).removeAttribute("disabled");
      }
    }
  }

  toggleEnableElements(chk, txt, cName, txt2) {
    let elements = document.getElementsByClassName(cName);
    for (let e = 0; e < elements.length; e++) {
      elements[e].setAttribute("disabled", "disabled");
      let input: HTMLInputElement = <HTMLInputElement>document.getElementById(elements[e].id);
      input.value = "";
    }
    if (chk.checked) {
      if (txt != null) {
        document.getElementById(txt).removeAttribute("disabled");
      }
      if (txt2 != null) {
        document.getElementById(txt2).removeAttribute("disabled");
      }
    } else {
      if (txt != null) {
        (document.getElementById(txt) as any).disabled = true;
      }
      if (txt2 != null) {
        (document.getElementById(txt2) as any).disabled = true;
      }
    }
  }

  openModal(form) {
    const modalRef = this.activeModal.open(FormExpiryModalComponent, { centered: true, size: 'lg', scrollable: true });
    modalRef.componentInstance.form = form;
    modalRef.result.then(
      (data: any) => {
        this.loadData();
        if (this.searchTerm != "") {
          setTimeout(this.searchData, 2000, this.searchTerm);
        }
      },
      (reason: any) => { }
    );
  }

  resetPage() {
    this.page = 1;
  }
}
