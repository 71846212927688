import { Component, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { StatesService } from "../../../services/states.service";
import { first } from "rxjs/operators";
import { Observable } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { StatesDataModel } from "../../../variables/states";
import { NgbPanelChangeEvent, NgbAccordion, NgbPanel, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomerService } from "../../../services/customer.service";
import { CampaignService } from "../../../services/campaign.service";
import { CustomerDataModel } from "../../../variables/customer";
import { compare, DeliveryMethod, Exposures } from "../../../variables/constant";
import { UntypedFormGroup, Validators, UntypedFormBuilder } from "@angular/forms";
import {
  CampaignModel,
  CampaignCustomerModel,
  ExposureModel,
  UploadCsvCampaign
} from "../../../variables/campaign";
import { CommonService } from "../../../services/common.service";
import { LoadingComponent } from "../../../helper/loading";
import { DatePipe } from "@angular/common";
import { SortColumn, SortDirection } from "src/app/services/sortable.directive";
import { CampaignCreateWarningComponent } from "../campaign-create-warning/campaign-create-warning.component";

const uploadCsvCampaign = async (campaignService: CampaignService, campaign: any) => {
  let response = await campaignService
    .uploadCsvCampaign(campaign)  
    .toPromise()
    .catch((error) => {
      if (error instanceof Object) {
        if (error["ResponseExceptionTitle"] != "") {
          alert(error["ResponseExceptionTitle"] || "Something went wrong!");
        }else{
          alert(error["message"] || "Something went wrong!");
        } 
      }else{
        alert(error);
      }
      console.log(error);
    });
  return response;
};
function sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
  if (direction === "" || column === "") {
    return datalist;
  } else {
    return [...datalist].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === "asc" ? res : -res;
    });
  }
}
const createCampaign = async (campaignService: CampaignService, campaign: any) => {
  let response = await campaignService
    .createCampaignNew(campaign)
    .toPromise()
    .catch((error) => {
      console.log('error:----- ', error);
      alert(error);
      // if (error instanceof Object) {
      //   if (error["message"] != "") {
      //     alert(error["message"]);
      //   }
      // }
      // console.log(error);
    });
    if(response) {
      alert(response);
    }
  return response;
};

@Component({
  selector: "app-create-campaign-new",
  templateUrl: "./create-campaign-new.component.html",
  styleUrls: ["./create-campaign-new.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class CreateCampaignNewComponent implements OnInit {
  currStep: string = "1";
  stateList: StatesDataModel[] = [];
  customerList: CustomerDataModel[] = [];
  campaignCustomerList: CampaignCustomerModel[] = [];
  exposureList: any[] = [];
  formCreateCampaign: UntypedFormGroup;

  submittedSearch: boolean = false;
  submittedDM: boolean = false;
  submittedCM: boolean = false;

  @ViewChild("acc") acc: NgbAccordion;

  frmSearch: UntypedFormGroup;
  frmDeliveryMethod: UntypedFormGroup;
  frmCampaignName: UntypedFormGroup;

  //EmailContent
  EmailContent: string = "";

  collectionSizeMissing: number = 0;
  collectionSizeCompanyExisting: number = 0;
  collectionSizeNormalEntries: number = 0;
  page: number = 1;
  pageSize: number = 10;

  stateSettings = {};
  customerSettings = {};
  exposureSettings = {};

  recipients: string = "";

  question: string = "";

  uploadedFileName: string | null = null;
  uploadError: string | null = null;
  activeTab: string = "missingIds";
  
  
  formattedDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  file: any = null;
  // Sample data for Missing IDs
  missingIds = [];
  missingIdsAll = [];
  campaignExists = [];
  campaignExistsAll = [];
  normalEntries = [];
  normalEntriesAll = [];
  searchTermMissing: string = "";
  searchTermCompanyExist: string = "";
  searchTermNormalEntries: string = "";
  finalResponse: any;
  //sorting
  currentColumn: SortColumn = "";
  currentSortBy: SortDirection = "desc";
  missingMessage: string = "";
  existsMessage: string = "";
  normalMessage: string = "";
  linkIcon = "./assets/img/icon-attachment.png";
  companyCode = localStorage.getItem('company_code');

  minDate: string = '';

  public get deliveryMethods(): typeof DeliveryMethod {
    return DeliveryMethod;
  }

  public get exposures(): typeof Exposures {
    return Exposures;
  }

  constructor(
    private router: Router,
    public formBuilder: UntypedFormBuilder,
    private stateService: StatesService,
    private customerService: CustomerService,
    private campaignService: CampaignService,
    private commonService: CommonService,
    private datePipe: DatePipe,
    private activeModal: NgbModal
  ) {
    this.init();
  }
  init() {
    this.formCreateCampaign = this.formBuilder.group({
      CampaignName: ["", Validators.required],
      StartDate: [this.formattedDate, Validators.required],
    });

    // Set minDate to today's date in YYYY-MM-DD format
    this.minDate = new Date().toISOString().split('T')[0];
  }
  ngOnInit(): void {
    this.commonService.setCurrentNav("Create Campaign");
  }

  get fs() {
    return this.frmSearch.controls;
  }
  get fd() {
    return this.frmDeliveryMethod.controls;
  }
  get fc() {
    return this.frmCampaignName.controls;
  }

  submitSearch() {
    this.submittedSearch = true;
    if (!this.frmSearch.invalid) {
      this.recipients =
        this.fs.states.value.length + " States, " + this.fs.customers.value.length + " Customers";
    }
  }
  get f() { return this.formCreateCampaign.controls; }

  submitForm() {
    this.submittedDM = true;
    console.log('this.file: ', this.file);
    console.log('this.formCreateCampaign.valid: ', this.formCreateCampaign.valid);
    if(!this.formCreateCampaign.valid) {
      return
    }
    if(!this.file) {
      return
    }
    const formCampaignData: FormData = new FormData();
    formCampaignData.append(
      "CampaignName",
      this.formCreateCampaign.get("CampaignName")?.value
    );
    formCampaignData.append("StartDate", this.formCreateCampaign.get("StartDate")?.value);
    formCampaignData.append("file", this.file);
    formCampaignData.append("CompanyCode", this.companyCode);
    console.log("-0-0-0-0-0", this.formCreateCampaign, "-0-0-0", formCampaignData);
    const isMissingLength = this.missingIds.length ? true : false;
    const isExistingLength = this.campaignExists.length ? true : false;
    if (!this.missingIds.length && !this.campaignExists.length) {
      createCampaign(this.campaignService, formCampaignData).then((data) => {
        console.log(data);
        if(data) {
          this.router.navigate(["/admin/campaigns"]);
        }
      });
    } else {
      const modalRef = this.activeModal.open(CampaignCreateWarningComponent, { centered: true });
      modalRef.componentInstance.row = {
        formData: formCampaignData,
        isMissingLength: isMissingLength,
        isExistingLength: isExistingLength
      };
      modalRef.result.then(
        (data: any) => {
          console.log("data: ", data);
          if(data) {
            this.router.navigate(["/admin/campaigns"]);
          }
          // this.loadData();
        },
        (reason: any) => {
          console.log(reason);
          // this.loadData();
        }
      );
    }
  }
  // Handle tab switching
  setActiveTab(tab: string): void {
    this.activeTab = tab;
    console.log("this.activeTab: ", this.activeTab);
  }
  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input?.files?.length) {
      const file = input.files[0];
      this.file = input.files[0];
      // Validate file extension
      if (file.name.endsWith(".csv")) {
        console.log(this.formCreateCampaign.value);
       const frmData: FormData = new FormData();
        frmData.append("StartDate", this.formCreateCampaign.get("StartDate")?.value);
        frmData.append("file", file);
        frmData.append("CompanyCode", this.companyCode);
        uploadCsvCampaign(this.campaignService, frmData).then((data) => {
          this.finalResponse = data;
          this.activeTab = "missingIds";
          // this.missingIds = data["missing"];
          this.missingIds = sortData(data["missing"], "name1", "asc");
          this.missingIdsAll = data["missing"];
          this.missingMessage = data["missingMessage"];

          // this.campaignExists = data["campaignExists"];
          this.campaignExists = sortData(data["campaignExists"], "name1", "asc");
          this.campaignExistsAll = data["campaignExists"];
          this.existsMessage = data["existsMessage"];

          // this.normalEntries = data["normalEntries"];
          this.normalEntries = sortData(data["normalEntries"], "name1", "asc");
          this.normalEntriesAll = data["normalEntries"];
          this.normalMessage = data["normalMessage"];

          this.collectionSizeMissing = data["missing"].length;
          this.collectionSizeCompanyExisting = data["campaignExists"].length;
          this.collectionSizeNormalEntries = data["normalEntries"].length;
        });
        this.uploadedFileName = file.name;
        this.uploadError = null;
      } else {
        this.uploadError = "Only .csv files are allowed.";
        this.uploadedFileName = null;
      }
    }
  }
  removeFile(): void {
    this.uploadedFileName = null;
    this.uploadError = null;
  }
  back() {
    this.router.navigate(["/admin/campaigns"]);
  }
  onSort(event) {
    this.page = 1; //bring back to page 1
    var column = event.target.getAttribute("sortable");
    console.log("column: ", column);
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort
    } else {
      if (this.currentColumn == "") {
        //first sort
        this.currentColumn = column;
        if (this.currentColumn == "state") {
          //by default, state is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    if (this.activeTab == "missingIds") {
      this.missingIds = sortData(this.missingIds, column, this.currentSortBy);
    } else if (this.activeTab == "campaignExists") {
      this.campaignExists = sortData(this.campaignExists, column, this.currentSortBy);
    } else if (this.activeTab == "normalEntries") {
      this.normalEntries = sortData(this.normalEntries, column, this.currentSortBy);
    } else {
      this.missingIds = sortData(this.missingIds, column, this.currentSortBy);
    }
  }

  searchDataMissingIds(_term: string) {
    let term = _term.toLowerCase();
    this.searchTermMissing = term;
    if (_term == "") {
      this.missingIds = this.missingIdsAll;
    } else {
      this.missingIds = this.missingIdsAll.filter(
        (data) =>
          (data.payer == null ? "" : data.payer).toLowerCase().indexOf(term) > -1 ||
          (data.name1 == null ? "" : data.name1).toLowerCase().indexOf(term) > -1 ||
          (data.name2 == null ? "" : data.name2).toLowerCase().indexOf(term) > -1 ||
          (data.street == null ? "" : data.street.toString()).toLowerCase().indexOf(term) > -1 ||
          (data.city == null ? "" : data.city.toString()).toLowerCase().indexOf(term) > -1 ||
          (data.region == null ? "" : data.region.toString()).toLowerCase().indexOf(term) > -1 ||
          (data.postalCode == null ? "" : data.postalCode.toString()).toLowerCase().indexOf(term) >
            -1
      );
    }
    this.collectionSizeMissing = this.missingIds.length;
    this.page = 1;
  }
  searchDataCompanyExist(_term: string) {
    let term = _term.toLowerCase();
    this.searchTermCompanyExist = term;
    if (_term == "") {
      this.campaignExists = this.campaignExistsAll;
    } else {
      this.campaignExists = this.campaignExistsAll.filter(
        (data) =>
          (data.payer == null ? "" : data.payer).toLowerCase().indexOf(term) > -1 ||
          (data.name1 == null ? "" : data.name1).toLowerCase().indexOf(term) > -1 ||
          (data.city == null ? "" : data.city.toString()).toLowerCase().indexOf(term) > -1 ||
          (data.region == null ? "" : data.region.toString()).toLowerCase().indexOf(term) > -1 ||
          (data.postalCode == null ? "" : data.postalCode.toString()).toLowerCase().indexOf(term) >
            -1
      );
    }
    this.collectionSizeCompanyExisting = this.campaignExists.length;
    this.page = 1;
  }
  searchDataNormalEntries(_term: string) {
    let term = _term.toLowerCase();
    this.searchTermNormalEntries = term;
    if (_term == "") {
      this.normalEntries = this.normalEntriesAll;
    } else {
      this.normalEntries = this.normalEntriesAll.filter(
        (data) =>
          (data.payer == null ? "" : data.payer).toLowerCase().indexOf(term) > -1 ||
          (data.name1 == null ? "" : data.name1).toLowerCase().indexOf(term) > -1 ||
          (data.name2 == null ? "" : data.name2).toLowerCase().indexOf(term) > -1 ||
          (data.street == null ? "" : data.street.toString()).toLowerCase().indexOf(term) > -1 ||
          (data.city == null ? "" : data.city.toString()).toLowerCase().indexOf(term) > -1 ||
          (data.region == null ? "" : data.region.toString()).toLowerCase().indexOf(term) > -1 ||
          (data.postalCode == null ? "" : data.postalCode.toString()).toLowerCase().indexOf(term) >
            -1
      );
    }
    this.collectionSizeNormalEntries = this.normalEntries.length;
    this.page = 1;
  }

  closeModal() {
    this.activeModal.dismissAll();
  }
}
