import { Component, OnInit } from '@angular/core';
import { ThemeService } from './common/theme.service';
import { AuthService } from './services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'Bertelsmann - CVT Web';

  constructor(
    private themeService: ThemeService
  ) { }

  ngOnInit(): void {
    let domain = '';
    if (environment.production) {
      domain = window.location.hostname;
    } else {
      domain = 'http://prh.domain.com';
    }
    let subdomain = this.getSubdomain(domain);
    localStorage.setItem('company_code', subdomain);
    this.themeService.loadTheme(subdomain);
  }

  /**
   * This TypeScript function extracts the subdomain from a given domain string.
   */
  getSubdomain(url: string): string {
    const regex = /^(?:https?:\/\/)?([^\/?.]+)/;
    const match = url.match(regex);
    return match ? match[1].split('.')[0] : '';
  }

}

