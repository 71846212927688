import { Component, OnInit, NgZone, ViewChild, TemplateRef } from "@angular/core";
import { CampaignListModel } from "../../../variables/campaign";
import { CampaignService } from "../../../services/campaign.service";
import { compare } from "../../../variables/constant";
import { SortColumn, SortDirection } from "../../../services/sortable.directive";
import { UntypedFormBuilder } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { CommonService } from "../../../services/common.service";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { CampaignSubmittedCertificateComponent } from "../campaign-submitted-certificate/campaign-submitted-certificate.component";

function sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
  if (direction === "" || column === "") {
    return datalist;
  } else {
    return [...datalist].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === "asc" ? res : -res;
    });
  }
}

// Get Campaign List Initial
const getCampaignListInitial = async (
  campaignService: CampaignService,
  data: any
): Promise<CampaignListModel[]> => {
  let response = await campaignService
    .getCampaignsByCompanyCode(data)
    .toPromise()
    .catch((error) => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          // alert(error["message"]);
        }
      }
      console.log(error);
      return null;
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      // alert(response["message"]);
    }
    return response as any;
  }
};

@Component({
  selector: "app-campaign-list",
  templateUrl: "./campaign-list.component.html",
  styleUrls: ["./campaign-list.component.scss"]
})
export class CampaignListComponent implements OnInit {
  @ViewChild("modalnewPDF") modalnewPDF: TemplateRef<any>;

  searchResultListAll: CampaignListModel[] = [];
  searchResultList: CampaignListModel[];
  loading: boolean = true;
  collectionSize: number = 0;
  page: number = 1;
  pageSize: number = 10;
  pageForms: number = 1;
  searchTerm: string = "";
  currentColumn: SortColumn = "";
  currentSortBy: SortDirection = "desc";

  constructor(
    private campaignService: CampaignService,
    public formBuilder: UntypedFormBuilder,
    public sanitizer: DomSanitizer,
    private commonService: CommonService,
    private router: Router,
    private _ngZone: NgZone,
    private activeModal: NgbModal
  ) {
    window["counterComponent"] = { component: this, zone: this._ngZone };
  }

  ngOnInit(): void {
    this.commonService.setCurrentNav("Campaigns");
    this.getCampaignsList();
  }

  getCampaignsList(search?: any) {
    let payload = {
      campaignName: search || ''
    };
    getCampaignListInitial(this.campaignService, payload).then((data) => {
      this.loading = false;
      if (data) {
        this.searchResultListAll = data;
        this.searchResultList = data;
        this.collectionSize = data.length;
      }
    });
  }

  onSort(event) {
    this.page = 1; //bring back to page 1
    var column = event.target.getAttribute("sortable");
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort
    } else {
      if (this.currentColumn == "") {
        //first sort
        this.currentColumn = column;
        if (this.currentColumn == "campaignName") {
          //by campaignName, state is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.searchResultList = sortData(this.searchResultList, column, this.currentSortBy);
  }

  createCampaign() {
    this.router.navigate(["/admin/campaign/create"]);
  }

  getBackgroundColor(days: any): string {
    switch (true) {
      case days >= 1 && days <= 12:
        return "#FF7A45";
      case days > 12 && days <= 47:
        return "#73D13D";
      case days > 47 && days <= 66:
        return "#73D13D";
      case days > 66 && days <= 85:
        return "#73D13D";
      case days > 85:
        return "#73D13D";
      default:
        return "#6482A0"; // Default color if none of the cases match
    }
  }

  showSubmittedCertificates(data) {
    const modalRef = this.activeModal.open(CampaignSubmittedCertificateComponent, { centered: true, size: 'xl', scrollable: true });
    modalRef.componentInstance.campaign = data;
    modalRef.result.then(
      (data: any) => {
        // this.loadData();
      },
      (reason: any) => { }
    );
  }

  searchData(_term: string) {
    let term = _term.toLowerCase();
    this.searchTerm = term;
    if (_term == "") {
      this.searchResultList = this.searchResultListAll;
    } else {
      // this.getCampaignsList(term);
      this.searchResultList = this.searchResultListAll.filter(
        (data) =>
          (data.campaignName == null ? "" : data.campaignName).toLowerCase().indexOf(term) > -1
      );
    }
    this.collectionSize = this.searchResultList.length;
    this.page = 1;
  }

  resetPage() {
    this.page = 1;
    this.pageForms = 1;
  }
}
