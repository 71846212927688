import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { StatesService } from '../../../services/states.service';
import { CustomerService } from '../../../services/customer.service';
import { StatesDataModel } from '../../../variables/states';
import { CustomerDataModel, CustomerContactDataModel, CustomerEmailModel, CustomerShippingDataModel, CustomerMasterDataModel } from '../../../variables/customer';
import { AuthService } from '../../../services/auth.service';
import { APIResponse } from '../../../variables/api-response';
import { SortColumn, SortDirection } from 'src/app/services/sortable.directive';
import { compare } from 'src/app/variables/constant';
import { DatePipe, Location } from '@angular/common';
import { alphaNumericValidator } from 'src/app/variables/common';


const updateCustomer = async (customerService: CustomerService, customer: CustomerDataModel): Promise<CustomerDataModel> => {

  let response = await customerService.updateMasterCustomer(customer)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = <CustomerDataModel>response["result"];

    return result;
  }

}

const updateCustomerContact = async (customerService: CustomerService, contact: CustomerContactDataModel): Promise<APIResponse> => {

  let response = await customerService.updateCustomerContact(contact, 'ecert')
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }


    return response;
  }

}

const getCustomerContactByEmail = async (customerService: CustomerService, email: CustomerEmailModel, authService: AuthService): Promise<CustomerContactDataModel> => {

  let response = await customerService.getCustomerContactByEmailv2(email)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          if (error["message"] == "Invalid Customer.") {
            authService.logout();
          }
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = <CustomerContactDataModel>response["result"];

    return result;
  }

}

const getCustomerById = async (customerService: CustomerService, customerId: number): Promise<CustomerDataModel> => {

  let response = await customerService.getCustomerById(customerId)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = <CustomerDataModel>response["result"];

    return result;
  }

}


const getCurrentCustomer = async (customerService: CustomerService): Promise<CustomerDataModel> => {

  let response: any = await customerService.getCurrentMasterCustomer()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }

    return response;
  }

}


const getStateList = async (stateService: StatesService): Promise<StatesDataModel[]> => {

  let response = await stateService.getStates()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          state: obj.state,
          stateName: obj.stateName,
          stateNameFull: obj.stateNameFull
        }
      });
    }
    return null;
  }

}


const validateSession = async (authService: AuthService, src: string): Promise<String> => {

  let response = await authService.validateSession(src)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      return response["message"]
    }

    return "";
  }

}

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})
export class CustomerComponent implements OnInit {

  NoWhiteSpaceValidator = Validators.pattern(/^\S*$/);

  frmCustomer: UntypedFormGroup;
  frmEmailLookup: UntypedFormGroup;
  submittedCustomer: boolean = false;
  submittedContact: boolean = false;
  submittedEmail: boolean = false;
  stateList: StatesDataModel[];

  editCustomer: boolean = false;
  editContact: boolean = false;

  userEmail: string = "";

  customerEemail: CustomerEmailModel;

  activeIds: string[] = ['pnl-Customer', 'pnl-Contact'];
  isEditProfile: boolean = false;

  //sorting
  currentColumn: SortColumn = "";
  currentSortBy: SortDirection = "desc";

  shippingListAll: CustomerShippingDataModel[] = [];
  shippingList: CustomerShippingDataModel[] = [];
  collectionSizeShipping: number = 0;
  pageShipping: number = 1;
  pageSizeShipping: number = 10;
  selectedShipping: CustomerShippingDataModel;
  companyCode = localStorage.getItem('company_code');
  formattedDateTime: string = ''; // For displaying the formatted date-time
  selectedCountry: any;
  selectedSecondaryCountry: any;
  // List of countries with flags and codes
  countries = [
    { code: '+91', name: 'India', flag: './assets/svg/in.svg' },
    { code: '+1', name: 'United States', flag: './assets/svg/us.svg' },
  ];
  currentCustomer: CustomerMasterDataModel;

  constructor(public formBuilder: UntypedFormBuilder,
    private stateService: StatesService,
    private customerService: CustomerService,
    private authService: AuthService,
    private router: Router,
    private datePipe: DatePipe,
    public location : Location
  ) {

    getStateList(stateService).then(data => {
      this.stateList = data;
    });

    this.frmCustomer = this.formBuilder.group({
      id: null,
      companyCode: [this.companyCode || null],
      payorAccountNumber: ["", [Validators.required, alphaNumericValidator()]],
      customerName: ["", Validators.required],
      email: ["", Validators.required],
      phoneNumber: ["", [
        Validators.required,
        this.NoWhiteSpaceValidator,
        this.onlyNumbersValidator,
        Validators.minLength(7),
        Validators.maxLength(15)
      ]],
      secondaryPhoneNumber: ["", [
        this.NoWhiteSpaceValidator,
        this.onlyNumbersValidator,
        Validators.minLength(7),
        Validators.maxLength(15)
      ]],
      address: ["", Validators.required],
      state: ["", Validators.required],
      pincode: ["", Validators.required],
      isTaxable: ["0"],
      // isVip: [false],
      createdInSAP: []
    });

    this.loadData();
  }

  ngOnInit(): void {
    if (this.authService.getEmail().trim() !== null) {
      if (this.authService.getEmail().trim() !== "") {
        this.getCustomerContactByEmail(this.authService.getEmail());
      }
    } else {
      if (sessionStorage.getItem('emailRecipientSession').trim() !== null) {
        if (sessionStorage.getItem('emailRecipientSession').trim() !== "") {
          this.getCustomerContactByEmail(sessionStorage.getItem('emailRecipientSession'));
        }
      }
    }
  }

  loadData() {
    let _customerId = this.authService.getId();
    let _email = this.authService.getEmail();
    
    this.userEmail = _email;
    if (_customerId == undefined || _customerId == null) {
      this.authService.logout();
      this.router.navigate(["/eCert"]);
    }
    else {
      getCurrentCustomer(this.customerService).then((data: any) => {
        this.currentCustomer = data;
        this.shippingList = data?.shippingAccounts || [];
        if (data == undefined || data == null) {
          this.authService.logout();
          this.router.navigate(["/eCert"]);
        }

        const formattedDate = this.datePipe.transform(data.createdInSAP, 'yyyy-MM-dd');
        const selectedCountry = this.splitPhoneNumber(this.currentCustomer.phoneNumber).countryCode;
        this.selectedCountry = this.countries.find((el) => el.code == selectedCountry);
        const selectedSecondaryCountry = this.splitPhoneNumber(this.currentCustomer.secondaryPhoneNumber).countryCode;
        this.selectedSecondaryCountry = this.countries.find((el) => el.code == selectedSecondaryCountry);

        this.frmCustomer.patchValue({
          id: data.id,
          companyCode: this.companyCode,
          payorAccountNumber: data.payorAccountNumber,
          customerName: data.customerName,
          email: data.email,
          phoneNumber: this.splitPhoneNumber(data.phoneNumber).localNumber,
          secondaryPhoneNumber: this.splitPhoneNumber(data.secondaryPhoneNumber).localNumber,
          address: data.address,
          state: data.state,
          pincode: data.pincode,
          isTaxable: data.isTaxable,
          // isVip: data.isVip,
          createdInSAP: formattedDate
        });

        this.frmCustomer.controls.payorAccountNumber.disable()
        // this.frmCustomer.controls.isTaxable.disable()
        // this.frmCustomer.controls.isVip.disable()
        this.frmCustomer.controls.createdInSAP.disable()

        this.frmEmailLookup = this.formBuilder.group({
          emailAddress: [_email, Validators.required]
        });

        if (_email != "" && _email != undefined) {
          this.getCustomerContactByEmail(_email);
        }
      });
    }
  }

  get fCustomer() { return this.frmCustomer.controls; }
  get fEmail() { return this.frmEmailLookup.controls; }

  submitCustomerForm() {
    this.submittedCustomer = true;
    //Notify if there's a change
    if (!this.frmCustomer.invalid) {

      const currentDate = new Date(this.fCustomer.createdInSAP.value);
      const formattedDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd') + 'T' + this.datePipe.transform(currentDate, 'HH:mm:ss');
      this.frmCustomer.controls.createdInSAP.setValue(formattedDate);

      this.frmCustomer.controls.isTaxable.setValue(Number(this.frmCustomer.controls.isTaxable.value || 0));

      this.frmCustomer.controls.phoneNumber.setValue(this.selectedCountry['code'] + this.frmCustomer.controls.phoneNumber.value);
      if (this.frmCustomer.controls.secondaryPhoneNumber.value) {
        this.frmCustomer.controls.secondaryPhoneNumber.setValue(this.selectedSecondaryCountry['code'] + this.frmCustomer.controls.secondaryPhoneNumber.value);
      }

      updateCustomer(this.customerService, this.frmCustomer.getRawValue()).then(data => {
        // if (data != null && data != undefined) {
        this.loadData();
        this.isEditProfile = false;
        // }
      });
      //this.router.navigate(["eCert/certificates"]);      
    }
    this.isEditProfile = false;
  }

  lookupEmail() {
    this.submittedEmail = true;
    if (!this.frmEmailLookup.invalid) {
      let _email: string = this.fEmail.emailAddress.value;
      if (_email != "" && _email != undefined) {
        this.getCustomerContactByEmail(_email);
      }
    }
  }

  getCustomerContactByEmail(_email) {
    let x: CustomerEmailModel = { emailAddress: _email }
    getCustomerContactByEmail(this.customerService, x, this.authService).then(data => {
      // if (data == undefined) {
      //   this.fContact.emailAddress.setValue(_email);
      //   this.editContact = true;
      // }
      // else {
      //   this.authService.setUserEmail(data.emailAddress);
      //   sessionStorage.setItem('emailRecipientSession', data.emailAddress);
      // }
    });
  }

  // Handle country selection
  selectCountry(country: any) {
    this.selectedCountry = country;
  }

  selectSecondaryCountry(country: any) {
    this.selectedSecondaryCountry = country;
  }

  onSort(event) {
    var column = event.target.getAttribute("sortable");
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort      
    } else {
      if (this.currentColumn == "") { //first sort
        this.currentColumn = column;
        if (this.currentColumn == "firstName") { //by default, firstName is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.shippingList = this.sortData(this.shippingList, column, this.currentSortBy);
  }

  sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
    if (direction === '' || column === '') {
      return datalist;
    } else {
      return [...datalist].sort((a, b) => {
        const res = compare(a[column], b[column]);
        return direction === 'asc' ? res : -res;
      });
    }
  }

  toDateTimeLocal(date: Date): string {
    const ten = (i: number) => (i < 10 ? '0' : '') + i;
    const YYYY = date.getFullYear();
    const MM = ten(date.getMonth() + 1);
    const DD = ten(date.getDate());
    const HH = ten(date.getHours());
    const II = ten(date.getMinutes());
    return `${YYYY}-${MM}-${DD}T${HH}:${II}`;
  }

  splitPhoneNumber(phoneNumber: string) {
    let countryCode = "";
    let localNumber = "";

    // Check for +1 (USA) or +91 (India) as the country code
    if (phoneNumber.startsWith("+1")) {
      countryCode = "+1";
      localNumber = phoneNumber.substring(2); // Remove the country code part
    } else if (phoneNumber.startsWith("+91")) {
      countryCode = "+91";
      localNumber = phoneNumber.substring(3); // Remove the country code part
    } else {
      countryCode = this.countries[0]['code'];
      localNumber = phoneNumber; // Remove the country code part
    }
    return { countryCode: countryCode || this.countries[0]['code'], localNumber };
  }

  // Custom validator to check if phone number contains only numeric characters
  onlyNumbersValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!/^[0-9]*$/.test(value)) {
      return { invalidCharacters: true };
    }
    return null;
  }

  isTaxableLabel(val) {
    switch (val) {
      case 0:
        return "Pending";
      case 1:
        return "Taxable";

      case 2:
        return "Non Taxable";

      default:
        return "Pending";
    }
  }
}
