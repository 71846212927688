import { Component, OnInit } from '@angular/core';
import { compare, DeliveryMethod, CertificateStatus } from '../../../variables/constant';
import { SortColumn, SortEvent, SortDirection } from '../../../services/sortable.directive';
import { StatesService } from '../../../services/states.service';
import { StatesDataModel, FormsByStatesDataModel, SubmittedFormDataModel, SubmittedCertificatesFormDataModel } from '../../../variables/states';
import { NgbPanelChangeEvent, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from '../../../services/customer.service';
import { PdfService } from '../../../services/pdf.services';
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CustomerFormModel } from '../../../variables/form';
import { SVG } from 'src/app/common/svg';
import { Subject } from 'rxjs';
import { CustomModalComponent } from 'src/app/components/custom-modal/custom-modal.component';
import { CampaignService } from 'src/app/services/campaign.service';


function sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
  if (direction === '' || column === '') {
    return datalist;
  } else {
    return [...datalist].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

const getPdf = async (pdfService: PdfService, fileName: string): Promise<string> => {

  let data = await pdfService.getPdfMaster(fileName)
    .toPromise()
    .catch(error => {
      console.log(error);
      if (error.message != undefined && error.message != "") {
        alert(error.message);
      }
    });

  if (data instanceof Object) {
    // let f = data["result"];
    // let base64 = f.fileContents;
    // var raw = window.atob(base64);
    // var rawLength = raw.length;
    // var array = new Uint8Array(new ArrayBuffer(rawLength));
    // var i = 0;
    // for (i = 0; i < rawLength; i++) {
    //   array[i] = raw.charCodeAt(i);
    // }


    // const blob = new Blob([array], { type: 'application/pdf' });
    // console.log(window.URL.createObjectURL(blob));

    return data["result"]
  }

  return null;
}


const getHtml = async (pdfService: PdfService, formName: string, stateName: string, mode: string = ""): Promise<string> => {
  console.log("formNameformName", formName);
  console.log("stateNamestateName", stateName);

  let response = await pdfService.getHtml(formName, stateName).toPromise()
    .catch(error => {
      console.log(error);
    });;
  if (response instanceof Object) {
    if (response["data"] != undefined && mode != "") {
      if (response["message"] != "") {
        if (response["data"] == undefined || response["data"] == null || response["data"] == "") {
          alert("Form is not available. Please contact support.");
        } else {
          sessionStorage.setItem("message", response["message"]);
          sessionStorage.setItem("data", response["data"]);
          if (response["filename"] != "") {
            return response["filename"];
          }
          return response["data"];
        }
      } else {
        return response["data"];
      }
    }
    else if (response["data"] != undefined) {
      return response["data"];
    }
    else {
      if (response["message"] != "")
        alert(response["message"]);
    }
  }
}

const getRequiredFormList = async (customerService: CustomerService): Promise<FormsByStatesDataModel[]> => {

  let response = await customerService.getCustomerRequiredForms()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          // alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response['result'] instanceof Array) {
      return response['result'];
    }
    return null;
  }

}

const getSubmittedForms = async (customerService: CustomerService): Promise<any[]> => {

  let response = await customerService.getMasterCustomerSubmittedForms()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          // alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response instanceof Array) {
      return response;
    }
    return null;
  }

}
const getFormList = async (stateService: StatesService, state: string): Promise<FormsByStatesDataModel[]> => {

  let response = await stateService.getAllFormsByState(state)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          // alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          formId: obj.formId,
          state: obj.state,
          form: obj.form,
          formType: obj.formType
        }
      });
    }
    return null;
  }

}

const getStateList = async (stateService: StatesService): Promise<StatesDataModel[]> => {

  let response = await stateService.getAllStates()
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          // alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          state: obj.state,
          stateName: obj.stateName,
          stateNameFull: obj.stateNameFull
        }
      });
    }
    return null;
  }

}

const validateSession = async (authService: AuthService, src: string): Promise<String> => {
  let response = await authService.validateSession(src)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["message"] != "") {
          alert(error["message"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      return response["message"]
    }
    return "";
  }
}

const stopCustomerProcessById = async (
  campaignService: CampaignService,
  campaigncustomerId: any,
  state : any
): Promise<any[]> => {
  let response = await campaignService
    .markAsNonExempt(campaigncustomerId,state)
    .toPromise()
    .catch((error) => {
      if (error instanceof Object) {
        if (error["ResponseException"] != "") {
          alert(error["ResponseException"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    return response as any;
  }
};

@Component({
  selector: 'app-certificates',
  templateUrl: './certificates.component.html',
  styleUrls: ['./certificates.component.scss']
})
export class CertificatesComponent implements OnInit {
  stateList: StatesDataModel[];
  formList: FormsByStatesDataModel[];
  requiredFormList: FormsByStatesDataModel[];
  requiredFormListAll: FormsByStatesDataModel[];

  state: string = "";
  stateFormId: number = null;
  modalMessage: string = "";
  modalData: string = "";

  requiredFormId: number = null;

  requiredFillInAvailable: boolean = false;
  allFormsFillInAvailable: boolean = false;

  activeIds: string[] = ['pnlRequired', 'pnlNew', 'pnlHistory'];

  formId: number = null;

  emailRecipient: string = "";

  searchTerm: string = "";
  searchTerm2: string = "";

  submittedFormsList: SubmittedCertificatesFormDataModel[] = [];
  submittedFormsListAll: SubmittedCertificatesFormDataModel[] = [];

  collectionSize: number = 0;
  page: number = 1;
  pageSize: number = 10;

  requiredCollectionSize: number = 0;
  requiredPage: number = 1;
  requiredPageSize: number = 10;

  //sorting
  currentColumn: SortColumn = "";
  currentSortBy: SortDirection = "desc";

  pdfFile: SafeResourceUrl;
  showAddNewCertificate: boolean = false;
  loading = false;
  fileIcon = SVG.documentIconSVG;
  certificateIcon = SVG.exportIcon;
  activeTab = 1;

  constructor(
    private customerService: CustomerService,
    private pdfService: PdfService,
    private router: Router,
    private authService: AuthService,
    private stateService: StatesService,
    private activeModal: NgbModal,
    public sanitizer: DomSanitizer,
    private campaignService: CampaignService
  ) {

    this.emailRecipient = this.authService.getEmail();

    getStateList(stateService).then(data => {
      this.stateList = data;
    });
    this.getRequiredFormList();
    this.getSubmittedForms();
  }

  ngOnInit(): void {
    if (this.emailRecipient.trim() !== "" && this.emailRecipient.trim() !== null) {
      sessionStorage.setItem('emailRecipientSession', this.emailRecipient);
    } else {
      if (sessionStorage.getItem('emailRecipientSession').trim() !== "" && sessionStorage.getItem('emailRecipientSession').trim() !== null) {
        this.emailRecipient = sessionStorage.getItem('emailRecipientSession');
        this.authService.setUserEmail(this.emailRecipient);
      }
    }

  }

  onSort(event) {
    var column = event.target.getAttribute("sortable");
    this.page = 1; //bring back to page 1
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort      
    } else {
      if (this.currentColumn == "") { //first sort
        this.currentColumn = column;
        if (this.currentColumn == "dateSubmitted") { //by default, dateSubmitted is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.submittedFormsList = sortData(this.submittedFormsList, column, this.currentSortBy);
  }

  onRequiredSort(event) {
    var column = event.target.getAttribute("sortable");
    this.page = 1; //bring back to page 1
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort      
    } else {
      if (this.currentColumn == "") { //first sort
        this.currentColumn = column;
        if (this.currentColumn == "dateSubmitted") { //by default, dateSubmitted is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.requiredFormList = sortData(this.requiredFormList, column, this.currentSortBy);
  }

  getSubmittedForms() {
    getSubmittedForms(this.customerService).then((data: any) => {
      if (data) {
        this.submittedFormsList = data;
        this.submittedFormsListAll = data;
        this.collectionSize = data.length;
        console.log(this.submittedFormsList);
      }
    });
  }

  getFormList() {
    if (this.state != "") {
      getFormList(this.stateService, this.state).then(data => {
        console.log("datadatadata", data);

        this.formList = data;
        if (data.length > 0) {
          this.stateFormId = data[0].formId;
          this.formId_change(this.stateFormId, 'allforms');
        }
      });
    }
    else {
      this.stateFormId = null;
      this.formList = null;
    }
  }

  getRequiredFormList() {
    this.loading = true;
    getRequiredFormList(this.customerService).then(data => {
      this.loading = false;
      if (data) {
        this.requiredFormList = data;
        this.requiredFormListAll = data;
        this.requiredCollectionSize = data.length;
        if (data.length == 1) {
          this.requiredFormId = this.requiredFormList[0].formId;
          let formName = this.requiredFormList[0].form;
          let stateName = this.requiredFormList[0].state;
          getHtml(this.pdfService, formName, stateName).then(result => {
            this.requiredFillInAvailable = (result != undefined && result != null && result != "");
          });
        }
      }
    });
  }


  fillinCertificate(formId, frmList, modal) {
    //check if session is still valid
    validateSession(this.authService, "eCert").then(data => {
      if (data == "Session expired. Please login again.") {
        sessionStorage.clear();
        this.authService.logout();
        this.router.navigate(['/eCert']);
      }
    });
    if (this.emailRecipient == "") {
      alert("Please update your contact information before uploading a certificate");
    }
    else {
      let formName = frmList.find(x => x.formId == formId).form;
      let stateName = frmList.find(x => x.formId == formId).state;
      this.modalMessage = "";
      this.modalData = "";
      this.pdfFile = "";
      getHtml(this.pdfService, formName, stateName, "fill").then(result => {
        if (result == undefined || result == null || result == "") {
          //alert("Form is not available. Please contact support."); message transferred to new condition on getHTML
        }
        else {
          //this.router.navigate(["/eCert/fill/" + formId]);
          this.modalMessage = sessionStorage.getItem("message");
          this.modalData = formId;
          sessionStorage.removeItem("message");
          sessionStorage.removeItem("data");
          if (this.modalMessage != "" && this.modalMessage != null) {
            if (result.indexOf('.pdf') > 0) {
              getPdf(this.pdfService, result).then(data => {
                if (data != "" || data != undefined || data != null) {
                  this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
                  this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true });
                }
              });
            } else {
              this.activeModal.open(modal, { centered: true, size: 'sm', scrollable: true });
            }
          } else {
            this.router.navigate(["/eCert/fill/" + formId]);
          }
        }
      });
    }
  }

  ContinueToPrefill() {
    if (this.modalData != "" || this.modalData != null) {
      this.router.navigate(["/eCert/fill/" + this.modalData]);
      this.activeModal.dismissAll();
    }
  }

  formId_change(formId, option) {
    let formName = null;
    let stateName = null;
    if (option == "required") {
      formName = this.requiredFormList.find(x => x.formId == formId).form;
      stateName = this.requiredFormList.find(x => x.formId == formId).state;
    }
    else {
      formName = this.formList.find(x => x.formId == formId).form;
      stateName = this.formList.find(x => x.formId == formId).state;
    }

    getHtml(this.pdfService, formName, stateName).then(result => {
      if (option == "required") {
        this.requiredFillInAvailable = (result != undefined && result != null && result != "");
      } else {
        this.allFormsFillInAvailable = (result != undefined && result != null && result != "");
      }
    });
  }

  uploadPdf(formId, frmList, modal) {
    //check if session is still valid
    validateSession(this.authService, "eCert").then(data => {
      if (data == "Session expired. Please login again.") {
        sessionStorage.clear();
        this.authService.logout();
        this.router.navigate(['/eCert']);
      }
    });
    if (this.emailRecipient == "") {
      alert("Please update your contact information before uploading a certificate");
    }
    else {
      //this.router.navigate(["/eCert/upload/" + formId]);
      let formName = frmList.find(x => x.formId == formId).form;
      let stateName = frmList.find(x => x.formId == formId).state;
      this.modalMessage = "";
      this.modalData = "";
      this.pdfFile = "";
      getHtml(this.pdfService, formName, stateName, "fill").then(result => {
        if (result == undefined || result == null || result == "") {
          //alert("Form is not available. Please contact support."); message transferred to new condition on getHTML
        }
        else {
          this.modalMessage = sessionStorage.getItem("message");
          this.modalData = formId;
          sessionStorage.removeItem("message");
          sessionStorage.removeItem("data");
          //check if there is an existing cert, if not, then no need for preview/validation
          if (this.modalMessage != "" && this.modalMessage != null) {
            if (result.indexOf('.pdf') > 0) {
              getPdf(this.pdfService, result).then(data => {
                if (data != "" || data != undefined || data != null) {
                  this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
                  this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true });
                }
              });
            } else {
              this.activeModal.open(modal, { centered: true, size: 'sm', scrollable: true });
            }
          } else {
            this.router.navigate(["/eCert/upload/" + formId]);
          }
        }
      });
    }
  }

  ContinueToPrefillUpload() {
    if (this.modalData != "" || this.modalData != null) {
      this.router.navigate(["/eCert/upload/" + this.modalData]);
      this.activeModal.dismissAll();
    }
  }

  getPdf(modal, certificate) {
    getPdf(this.pdfService, certificate.fileName).then(data => {
      if (data != "" && data != undefined) {
        this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
        this.activeModal.open(modal, { centered: true, size: 'lg', scrollable: true });
      }
    });
  }

  closeModal() {
    this.activeModal.dismissAll();
  }

  resetPage() {
    this.page = 1;
  }

  searchData(_term: string) {
    let term = _term.toLowerCase();
    this.searchTerm = term;
    if (_term == "") {
      this.submittedFormsList = this.submittedFormsListAll;
    } else {
      this.submittedFormsList = this.submittedFormsListAll.filter(
        (data) =>
          (data.form == null ? "" : data.form).toLowerCase().indexOf(term) > -1 ||
          (data.state == null ? "" : data.state).toLowerCase().indexOf(term) > -1 ||
          (data.formType == null ? "" : data.formType).toLowerCase().indexOf(term) > -1
      );
    }
    this.collectionSize = this.submittedFormsList.length;
    this.page = 1;
  }

  requiredFormSearchData(_term: string) {
    let term = _term.toLowerCase();
    this.searchTerm2 = term;
    if (_term == "") {
      this.requiredFormList = this.requiredFormListAll;
    } else {
      this.requiredFormList = this.requiredFormListAll.filter(
        (data) =>
          (data.form == null ? "" : data.form).toLowerCase().indexOf(term) > -1 ||
          (data.state == null ? "" : data.state).toLowerCase().indexOf(term) > -1 ||
          (data.formType == null ? "" : data.formType).toLowerCase().indexOf(term) > -1
      );
    }
    this.requiredPageSize = this.requiredFormList.length;
    this.requiredPage = 1;
  }

  onBlockCustomer(campaigncustomerId,state) {
    const modalRef = this.activeModal.open(CustomModalComponent, { centered: true });
    modalRef.componentInstance.title = "Mark this state as NOT EXEMPT";
    modalRef.componentInstance.description = "Are you sure you want to mark this state as 'NOT EXEMPT?, This would mean that you are taxable in this state & once you are marked as 'NOT EXEMPT' no further changes could be done. If you still wish to continue click on Mark Not Exempt button below.";
    modalRef.componentInstance.infoIcon = "./assets/img/delete-icon.svg";
    modalRef.componentInstance.btnText = "Mark Not Exempt";
    modalRef.result.then(
      (data: any) => {
        if (data == "ok") {
          stopCustomerProcessById(this.campaignService, campaigncustomerId,state).then((data: any) => {
            this.getRequiredFormList();
          });
        }
      },
      (reason: any) => {
        console.log(reason);
      }
    );
  }
}
