import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { CustomerService } from "src/app/services/customer.service";
import { FormService } from "src/app/services/form.service";
import { PdfService } from "src/app/services/pdf.services";
import { SortColumn, SortDirection } from "src/app/services/sortable.directive";
import { CertificateStatus, compare } from "src/app/variables/constant";
import { CustomerFormModel, ReasonDeniedModel } from "src/app/variables/form";

export interface CertificatesListModel {
    customerId: number
    customerName: string
    customerNumber: string
    state: string
    form: string
    dateSubmitted: string
    status: string
    validationStatus: string
    expirationDate: any
    formDate: any
    fileName: string
    desktopFileName: string
}

const getPdf = async (pdfService: PdfService, fileName: string): Promise<string> => {

    let data = await pdfService.getPdfMaster(fileName)
        .toPromise()
        .catch(error => {
            console.log(error);
            if (error.message != undefined && error.message != "") {
                alert(error.message);
            }
        });

    if (data instanceof Object) {
        // let f = data["result"];
        // let base64 = f.fileContents;
        // var raw = window.atob(base64);
        // var rawLength = raw.length;
        // var array = new Uint8Array(new ArrayBuffer(rawLength));
        // var i = 0;
        // for (i = 0; i < rawLength; i++) {
        //     array[i] = raw.charCodeAt(i);
        // }


        // const blob = new Blob([array], { type: 'application/pdf' });
        // console.log(window.URL.createObjectURL(blob));

        return data["result"]
    }
    return null;
}

const approvePdf = async (formService: FormService, customerFormId: number, fileName: string): Promise<any> => {

    let response = await formService.approvePdf(customerFormId, fileName)
        .toPromise()
        .catch(error => {
            if (error instanceof Object) {
                if (error["ResponseException"] != "") {
                    alert(error["ResponseException"]);
                }
            }
            alert("Something went wrong. Please take note of the time this happened and contact your administrator. Error:" + error);
            console.log(error);
            location.reload();
        });
    if (response instanceof Object) {
        if (response["message"] != "" && response["message"] != undefined) {
            alert(response["message"]);
        }
        return response["result"];
    }

}

const denyPdf = async (formService: FormService, customerFormId: number, reasonDenied: string): Promise<any> => {

    let response = await formService.denyPdf(customerFormId, reasonDenied)
        .toPromise()
        .catch(error => {
            if (error instanceof Object) {
                if (error["ResponseException"] != "") {
                    alert(error["ResponseException"]);
                }
            }
            console.log(error);
        });
    if (response instanceof Object) {
        if (response["message"] != "" && response["message"] != undefined) {
            alert(response["message"]);
        }
        return response["result"];
    }
}




@Component({
    selector: 'customer-submitted-certificates-model',
    templateUrl: './customer-submitted-certificates-model.component.html',
    styleUrls: ['./customer-submitted-certificates-model.component.scss']
})
export class CustomerSubmittedCertificatesModelComponent implements OnInit {

    @Input() customer: any;

    certificatesListAll: CertificatesListModel[] = [];
    certificatesList: CertificatesListModel[] = [];
    collectionSizeCertificate: number = 0;
    pageCertificate: number = 1;
    pageSizeCertificate: number = 10;

    //sorting
    currentColumn: SortColumn = "";
    currentSortBy: SortDirection = "desc";

    companyCode = localStorage.getItem('company_code');

    pdfFile: SafeResourceUrl;
    selectedCertificate: any;

    secondModalRef: NgbModalRef | undefined;
    thirdModalRef: NgbModalRef | undefined;

    //loading
    approveLoading: boolean = false;

    //Reason Denied
    customerFormReason: ReasonDeniedModel;
    reasonDenied: string = "";

    constructor(
        public activeModal: NgbActiveModal,
        private customerService: CustomerService,
        private pdfService: PdfService,
        public sanitizer: DomSanitizer,
        private activeModal2: NgbModal,
        private formService: FormService
    ) { }

    ngOnInit(): void {
        this.getFormsSubmittedList();
    }

    public get certificateStatus(): typeof CertificateStatus {
        return CertificateStatus;
    }

    getFormsSubmittedList() {
        const payload = {
            customerId: this.customer.id,
            companyCode: this.companyCode
        }
        this.customerService
            .getCustomerSubmittedFormsByCustomerId(payload)
            .subscribe((res: any) => {
                if (res) {
                    this.certificatesListAll = res || [];
                    this.certificatesList = res || [];
                    this.collectionSizeCertificate = res?.length || 0;
                }
            }, (error) => {
                this.certificatesListAll = [];
                this.certificatesList = [];
                this.collectionSizeCertificate = 0;
            });
    }

    closeModal() {
        this.activeModal.close();
    }

    closeModal2() {
        this.secondModalRef.close();
    }

    getReasonDenied(modal) {
        this.reasonDenied = "";
        this.thirdModalRef = this.activeModal2.open(modal, { centered: true, size: 'sm', scrollable: true });
    }

    resetPage() {
        this.pageCertificate = 1;
    }

    onSort(event) {
        var column = event.target.getAttribute("sortable");
        if (this.currentColumn !== column && this.currentColumn !== "") {
            this.currentColumn = column;
            this.currentSortBy = "asc"; //revert to default sort      
        } else {
            if (this.currentColumn == "") { //first sort
                this.currentColumn = column;
                if (this.currentColumn == "firstName") { //by default, firstName is already sorted to asc
                    this.currentSortBy = "desc";
                }
            } else {
                if (this.currentSortBy == "asc") {
                    this.currentSortBy = "desc";
                } else {
                    this.currentSortBy = "asc";
                }
            }
        }
        this.certificatesList = this.sortData(this.certificatesList, column, this.currentSortBy);
    }

    getPdf(modal, certificate) {
        //this.activeModal.dismissAll();
        getPdf(this.pdfService, certificate.fileName).then(data => {
            if (data != "" && data != undefined) {
                this.selectedCertificate = certificate;
                console.log("this.selectedCertificate", this.selectedCertificate);

                this.pdfFile = this.sanitizer.bypassSecurityTrustResourceUrl(data);
                this.secondModalRef = this.activeModal2.open(modal, { centered: true, size: 'lg', scrollable: true });
            }
        });
        setTimeout(this.checkModalBody, 1000);
    }

    approvePdf() {
        this.approveLoading = true;
        approvePdf(this.formService, this.selectedCertificate.customerFormId, this.selectedCertificate.fileName).then(data => {
            if (data != null) {
                this.getFormsSubmittedList();
                this.closeModal2();
                this.approveLoading = false;
            }
        });
    }

    closeModal3() {
        this.thirdModalRef.close();
    }

    denyPdf() {
        denyPdf(this.formService, this.selectedCertificate.customerFormId, this.reasonDenied).then(data => {
            if (data != null) {
                this.getFormsSubmittedList();
                this.closeModal2();
                this.closeModal3();
            }
        });
    }


    checkModalBody() {
        //add class modal-open to fix 
        if (document.getElementsByClassName("modal").length !== 0 && document.getElementsByClassName("modal-backdrop").length !== 0) {
            //alert(document.getElementsByClassName("modal-open").length);
            if (document.getElementsByClassName("modal-open").length == 0) {
                document.body.classList.add("modal-open");
            }
        }
    }


    sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
        if (direction === '' || column === '') {
            return datalist;
        } else {
            return [...datalist].sort((a, b) => {
                const res = compare(a[column], b[column]);
                return direction === 'asc' ? res : -res;
            });
        }
    }
}