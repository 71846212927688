import { Component, OnInit } from '@angular/core';
import { IUserDataModel } from '../../../variables/user';
import { UserService } from '../../../services/user.service';
import { SortColumn, SortDirection, SortEvent } from '../../../services/sortable.directive';
import { compare, SystemRole } from '../../../variables/constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth.service';
import { UserModalComponent } from '../user-modal/user-modal.component';

const resetPassword = async (userService: UserService, id: string, temporaryPassword: string): Promise<IUserDataModel> => {
  let response = await userService.resetUserPasswordById(id, temporaryPassword)
    .toPromise()
    .catch(error => {
      if (error instanceof Object) {
        if (error["ResponseException"] != "") {
          alert(error["ResponseException"]);
        }
      }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    let result = <IUserDataModel>response["result"];

    return result;
  }
}
const getUserList = async (userService: UserService): Promise<IUserDataModel[]> => {
  let response = await userService.getAllUsers()
    .toPromise()
    .catch(error => {
      // if (error instanceof Object) {
      //   if (error["ResponseException"] != "") {
      //     alert(error["ResponseException"]);
      //   }
      // }
      console.log(error);
    });
  if (response instanceof Object) {
    if (response["message"] != "" && response["message"] != undefined) {
      alert(response["message"]);
    }
    if (response["result"] instanceof Array) {
      return response["result"].map(obj => {
        return {
          id: obj.id,
          userId: obj.userId,
          firstName: obj.firstName,
          lastName: obj.lastName,
          userName: obj.userName,
          email: obj.email,
          role: obj.role,
          temporaryPassword: obj.temporaryPassword,
          isActive: obj.isActive,
          companyId: obj.companyId,
          company: obj.company,
          phoneNumber: obj.phoneNumber
        }
      });
    }
    return null;
  }

}

function sortData(datalist: any[], column: SortColumn, direction: SortDirection): any[] {
  if (direction === '' || column === '') {
    return datalist;
  } else {
    return [...datalist].sort((a, b) => {
      const res = compare(a[column], b[column]);
      return direction === 'asc' ? res : -res;
    });
  }
}

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss']
})
export class UserSearchComponent implements OnInit {
  searchResultListAll: IUserDataModel[] = [];
  searchResultList: IUserDataModel[];

  //paging
  collectionSize: number = 0;
  page: number = 1;
  pageSize: number = 10;


  //search
  searchTerm: string = "";
  searchActive: string = "";

  currentRole: string = "";
  SystemRoles = SystemRole;
  //sorting
  currentColumn: SortColumn = "";
  currentSortBy: SortDirection = "desc";

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private activeModal: NgbModal,
  ) {
    this.loadData();
    this.currentRole = authService.getRole();
  }

  ngOnInit(): void {
    console.log("this.currentRole", this.currentRole);

  }

  onSort(event) {
    this.page = 1; //bring back to page 1
    var column = event.target.getAttribute("sortable");
    if (this.currentColumn !== column && this.currentColumn !== "") {
      this.currentColumn = column;
      this.currentSortBy = "asc"; //revert to default sort      
    } else {
      if (this.currentColumn == "") { //first sort
        this.currentColumn = column;
        if (this.currentColumn == "firstName") { //by default, firstName is already sorted to asc
          this.currentSortBy = "desc";
        }
      } else {
        if (this.currentSortBy == "asc") {
          this.currentSortBy = "desc";
        } else {
          this.currentSortBy = "asc";
        }
      }
    }
    this.searchResultList = sortData(this.searchResultList, column, this.currentSortBy);
  }


  searchData(_term: string) {
    let term = _term.toLowerCase();
    this.searchTerm = term;
    if (_term == "") {
      this.searchResultList = this.searchResultListAll;
    }
    else {
      this.searchResultList = this.searchResultListAll.filter(data =>
        (data.firstName == null ? "" : data.firstName).toLowerCase().indexOf(term) > -1
        || (data.lastName == null ? "" : data.lastName).toLowerCase().indexOf(term) > -1
        || (data.userName == null ? "" : data.userName).toLowerCase().indexOf(term) > -1
        || (data.email == null ? "" : data.email).toLowerCase().indexOf(term) > -1
        || (data.role == null ? "" : data.role).toLowerCase().indexOf(term) > -1
        || (data.company == null ? "" : data.company).toLowerCase().indexOf(term) > -1
      );
      this.page = 1;
    }
    if (this.searchActive) {
      this.searchResultList = this.searchResultList.filter(data =>
        data.isActive);
    }
    this.collectionSize = this.searchResultList.length;
  }

  loadData() {
    getUserList(this.userService).then(data => {
      this.searchResultListAll = data;
      this.searchResultList = data;
      this.collectionSize = data.length;
    });
  }


  //MODALS
  openUserModal(user: IUserDataModel) {
    if (user == null) {
      user = <IUserDataModel>{
        id: "",
        userId: 0,
        userName: "",
        email: "",
        firstName: "",
        middleName: "",
        lastName: "",
        role: "",
        roleId: "",
        isActive: true,
        temporaryPassword: "",
        companyId: null,
        company: ""
      };
    }

    const modalRef = this.activeModal.open(UserModalComponent, { size: 'lg', centered: true });
    modalRef.componentInstance.user = user;
    modalRef.result.then(
      (data: any) => {
        console.log(data);
        this.loadData();
      },
      (reason: any) => {
        console.log(reason);
      }
    );
  }


  editUser(user) {
    const modalRef = this.activeModal.open(UserModalComponent, { centered: true, size: 'lg', scrollable: true });
    modalRef.componentInstance.user = user;
    modalRef.result.then(
      (data: any) => {
        this.loadData();
      },
      (reason: any) => { }
    );
  }

  resetPasswordById(id: string) {
    if (id != "") {
      if (confirm("Are you sure you want to reset the password?")) {
        var temporaryPassword = Math.random().toString(36).slice(-8);
        resetPassword(this.userService, id, temporaryPassword).then(data => {
          this.loadData();
        });
      }
    }
  }


  hasAccess(role) {
    var hasRoleAccess = false;

    switch (this.currentRole) {
      default: hasRoleAccess = true;
        break;
      case (SystemRole.ADMIN || SystemRole.COMPANYADMIN):
        hasRoleAccess = true;
        break;
    }

    return hasRoleAccess;
  }

  resetPage() {
    this.page = 1;
  }

}
