import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent {
  @Input() progress;

  increaseProgress() {
    if (this.progress < 100) {
      this.progress += 10;
    }
  }

  decreaseProgress() {
    if (this.progress > 0) {
      this.progress -= 10;
    }
  }
}
