import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { Auth } from "../variables/auth";
import { map } from "rxjs/operators";
import jwt_decode from "jwt-decode";
import { ILoginDataModel, INewPasswordDataModel, ICustomerAuthDataModel, ICustomerRequestAccessDataModel, IChangePasswordDataModel, IForgotPasswordModel } from "../variables/user";
import { ClaimType } from "../variables/claim-type";
import { SystemRole } from "../variables/constant";
import { CommonService } from "./common.service";
import { APIResponse } from "../variables/api-response";
import { ThemeService } from '../common/theme.service';
import { environment } from "src/environments/environment";
import { decryptData } from "../variables/common";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserSubject: BehaviorSubject<Auth>;
  public currentUser: Observable<Auth>;
  baseUrl: string;
  decodedToken: { [key: string]: string };
  currentUserAPIRes: any;

  constructor(private http: HttpClient,
    private commonService: CommonService
  ) {
    this.currentUserSubject = new BehaviorSubject<Auth>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
    this.baseUrl = environment.API_URL;
  }

  public get currentUserValue(): Auth {
    return this.currentUserSubject.value;
  }

  //Get Firstname instead of Username for Top Nav 
  get displayName$() { return this.getUserName(); }
  get userName$() { return this.getName(); }
  get userRole$() { return this.getRole(); }


  login(username: string, password: string, OtpCode: string, companyCode: string) {
    let body: ILoginDataModel = { userName: username, password: password, OtpCode: OtpCode }
    if (this.currentUserValue != null) {
      this.logout();
    }
    this.setUserEmail("");
    this.commonService.setCurrentCompany(0);
    return this.http.post<any>(this.baseUrl + `api/auth/login/${companyCode}`, body)
      .pipe(map(response => {
        this.setAuth(response.result);
        return response.result;
      }));
  }

  setAuth(result) {
    localStorage.setItem('currentUser', JSON.stringify(result));
    this.currentUserSubject.next(result);
    if (this.userRole$ == SystemRole.CLIENT) {
      if (this.getCompanyId() != "") {
        let companyId = parseInt(this.getCompanyId());
        this.commonService.setCurrentCompany(companyId);
      }
      return this.getCompanyId();
    }
    if ((this.userName$ == SystemRole.ADMIN || this.userName$ == SystemRole.COMPANYADMIN) && !Number.isNaN(parseInt(this.getLastCompanyIdSelected()))) {
      let lastCompanyIdSelected = parseInt(this.getLastCompanyIdSelected());
      this.commonService.setCurrentCompany(lastCompanyIdSelected);
    }
  }

  sendOTP(payload) {
    let body: ILoginDataModel = {
      userName: payload.username,
      password: payload.password,
      // OtpCode: "",
      isNeedToSendOTP: payload.isNeedToSendOTP || false,
      isSendOTPOnMobile: payload.isSendOTPOnMobile || false,
    }
    return this.http.post<any>(this.baseUrl + `api/auth/send-otp/${payload.companyCode}`, body)
      .pipe(map(response => {
        return response;
      }));
  }

  forgotPassword(payload:IForgotPasswordModel) {
    return this.http.post<any>(this.baseUrl + `api/auth/reset-password-request/${payload.companyCode}?userName=${payload.userName}`, {})
      .pipe(map(response => {
        return response;
      }));
  }

  lastCompanyIdSelected(currentCompanyId: number) {
    return this.http.put<APIResponse>(this.baseUrl + 'api/auth/' + currentCompanyId, null);
  }

  checkIfPasswordShouldUpdate(userName: string, password: string, companyCode: string) {
    let body: ILoginDataModel = { userName: userName, password: password }
    return this.http.post<any>(this.baseUrl + "api/auth/checkIfPasswordShouldUpdate/" + companyCode, body)
      .pipe(map(response => {
        return response.result;
      }));
  }



  checkAccountStatus(id: string) {
    return this.http.get<any>(this.baseUrl + "api/auth/checkAccountStatus/" + id)
      .pipe(map(response => {
        return response;
      }));
  }

  addPassword(newPassword: string, userId: string, confirmPassword: string, currentPassword: string) {

    let body: IChangePasswordDataModel = {
      "userId": userId,
      "currentPassword": currentPassword,
      "newPassword": newPassword,
      "confirmPassword": confirmPassword
    }
    // let body: INewPasswordDataModel = { NewPassword: newPassword, UserId: userId, ConfirmPassword: confirmPassword }
    return this.http.post<any>(this.baseUrl + "api/auth/AddUserPassword", body)
      .pipe(map(response => {
        return response;
      }));
  }

  changePassword(currentPassword: string, newPassword: string, userId: string, confirmPassword: string) {
    let body: INewPasswordDataModel = { currentPassword: currentPassword, NewPassword: newPassword, UserId: userId, ConfirmPassword: confirmPassword }
    return this.http.post<any>(this.baseUrl + "api/auth/AddUserPassword", body)
      .pipe(map(response => {
        return response;
      }));
  }


  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);

  }

  //Decode Token
  decodeToken() {
    if (this.currentUserSubject.value.token){
      this.decodedToken = jwt_decode(this.currentUserSubject.value.token);

      if (this.decodedToken[ClaimType.UserName] && decryptData(this.decodedToken[ClaimType.UserName])) {
        this.decodedToken[ClaimType.UserName] = decryptData(this.decodedToken[ClaimType.UserName])
      }
      if (this.decodedToken[ClaimType.Role] && decryptData(this.decodedToken[ClaimType.Role])) {
        this.decodedToken[ClaimType.Role] = decryptData(this.decodedToken[ClaimType.Role])
      }
      if (this.decodedToken[ClaimType.Id] && decryptData(this.decodedToken[ClaimType.Id])) {
        this.decodedToken[ClaimType.Id] = decryptData(this.decodedToken[ClaimType.Id]);
      }
      if (this.decodedToken[ClaimType.GroupSid] && decryptData(this.decodedToken[ClaimType.GroupSid])) {
        this.decodedToken[ClaimType.GroupSid] = decryptData(this.decodedToken[ClaimType.GroupSid]);
      }
  
      const currentUserAPIRes = localStorage.getItem('currentUserInfo') ? localStorage.getItem('currentUserInfo') : "";
      if (!currentUserAPIRes || currentUserAPIRes == 'undefined') {
        localStorage.removeItem('currentUserInfo');
      }
      this.currentUserAPIRes = localStorage.getItem('currentUserInfo') ? JSON.parse(decryptData(localStorage.getItem('currentUserInfo'))) : {};
    }
  }

  getExpiryTime() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.exp : null;
  }

  getId() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken[ClaimType.Id] : null;
  }

  getRole() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken[ClaimType.Role] : null;
  }

  getUserName() {
    this.decodeToken();
    if (this.getRole() == SystemRole.CUSTOMER) {
      return this.currentUserAPIRes ? this.currentUserAPIRes['customerName'] : null;
    } else {
      return this.decodedToken ? this.decodedToken[ClaimType.UserName] : null;
    }
  }

  getEmail() {
    this.decodeToken();
    if (this.getRole() == SystemRole.CUSTOMER) {
      let returnValue = this.currentUserAPIRes ? this.currentUserAPIRes['email'] : null;
      if (returnValue == "" || returnValue == null) {
        return this.bSubjectToString(this.emailAddress);
      }
      return returnValue;
    } else {
      let returnValue = this.decodedToken ? this.decodedToken[ClaimType.Email] : null;
      if (returnValue == "" || returnValue == null) {
        return this.bSubjectToString(this.emailAddress);
      }
      return returnValue;
    }
  }

  getName() {
    this.decodeToken();
    if (this.getRole() == SystemRole.CUSTOMER) {
      return this.currentUserAPIRes['customerName'];
    } else {
      let sb = this.decodedToken[ClaimType.LastName] ? this.decodedToken[ClaimType.LastName] + ", " : "";
      return sb + this.decodedToken[ClaimType.FirstName];
    }
  }

  getCustomerNumber() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken[ClaimType.GroupSid] : null;
  }

  getCompanyId() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken[ClaimType.PrimaryGroupSid] : null;
  }

  getLastCompanyIdSelected() {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken[ClaimType.Sid] : null;
  }

  loginCustomer(customerNumber: string, accessCode: string) {
    this.setUserEmail("");
    this.commonService.setCurrentCompany(0);
    let body: ICustomerAuthDataModel = { customerNumber: customerNumber, accessCode: accessCode }
    if (this.currentUserValue != null) {
      this.logout();
    }
    console.log('!!!!!!!!!!!!!!', body);
    return this.http.post<any>(environment.API_URL + "api/auth/login/customer", body)
      .pipe(map(response => {
        localStorage.setItem('currentUser', JSON.stringify(response.result));
        this.currentUserSubject.next(response.result);
        return response.result;
      }));
  }

  activateCustomer(token: string) {
    return this.http.get<any>(this.baseUrl + "api/auth/customer/activate/" + token)
      .pipe(map(response => {
        localStorage.setItem('currentUser', JSON.stringify(response.result));
        this.currentUserSubject.next(response.result);
        return response.result;
      }));
  }
  requestNewAccess(customerNumber: string, emailAddress: string) {
    let body: ICustomerRequestAccessDataModel = { customerNumber: customerNumber, emailAddress: emailAddress }

    return this.http.post<any>(this.baseUrl + "api/auth/customer/request", body);
  }

  requestNewAccessMaster(payorAccountNumber: string) {
    return this.http.post<any>(this.baseUrl + `api/customermaster/ForgotPin/${payorAccountNumber}`, {});
  }

  public emailAddress = new BehaviorSubject<string>("");

  setUserEmail(_emailAddress: string) {
    this.emailAddress.next(_emailAddress);
  }

  bSubjectToString(obj: BehaviorSubject<string>): string {
    let _value: string = "";
    obj.asObservable()
      .subscribe(item => {
        _value = item;
      });
    return _value;
  }

  logoutCustomer() {
    return this.http.post<any>(this.baseUrl + "api/auth/logout/customer", "");
  }

  logoutAdmin() {
    return this.http.post<any>(this.baseUrl + "api/auth/logout", "");
  }

  validateSession(src: string) {
    return this.http.post<any>(this.baseUrl + "api/auth/validatesession/" + src, "");
  }

  // 
  customerMasterSendOtp(payload: any) {
    return this.http.post<any>(this.baseUrl + `api/auth/customer-master/send-otp/${payload.companyCode}`, payload);
  }

  // customerMasterLogin(payload: any) {
  //   return this.http.post<any>(this.baseUrl + "api/auth/customer-master/login", payload);
  // }


  customerMasterLogin(payload) {
    this.setUserEmail("");
    this.commonService.setCurrentCompany(0);
    if (this.currentUserValue != null) {
      this.logout();
    }
    console.log('!!!!!!!!!!!!!!', payload);
    return this.http.post<any>(this.baseUrl + `api/auth/customer-master/login/${payload.companyCode}`, payload)
      .pipe(map(response => {
        localStorage.setItem('currentUser', JSON.stringify(response.result));
        this.currentUserSubject.next(response.result);
        return this.currentUserSubject.value.token ? jwt_decode(this.currentUserSubject.value.token) : response.result;
      }));
  }
}

